import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CloseIcon } from '@chakra-ui/icons';
import { Button, chakra, HStack, Input, Select, Skeleton, Wrap } from '@chakra-ui/react';

import Cardlayout from 'components/CardLayout';
import FilePicker from 'components/FilePicker';
import FilterPopovers from 'components/filters';
import DocumentsTable from 'components/tables/DocumentsTable';
import useThemedToast from 'hooks/useThemedToast';
import { useGetClientByEmailQuery } from 'services/client';
import { AllDeal, OpsInvestDeal, OpsTransferDeal } from 'services/deal';
import { RamifyDoc, useGetDocumentsQuery, useUploadDocumentMutation } from 'services/document';
import { PjTypeIdentity, PjTypeIdentityMap, useLazyGetSubscriptionsQuery } from 'services/subscription';
import { BOContext, ProductType, SubscriptionStatus } from 'types/global.type';
import { documentDataMap, DocumentName, S3Folder } from 'utils/documentNamingMap';
import { isNone, isNotNone } from 'utils/functions';

type DocumentsListProps = {
	email: string;
} & (
	| {
			context: Extract<BOContext, 'client'>;
			subscription?: never;
			selectedDocuments?: never;
			setSelectedDocuments?: never;
	  }
	| {
			context: Extract<BOContext, 'subscription'>;
			subscription: AllDeal;
			selectedDocuments: RamifyDoc[];
			setSelectedDocuments: (d: RamifyDoc[]) => void;
	  }
	| {
			context: Extract<BOContext, 'deal'>;
			subscription: AllDeal;
			selectedDocuments?: never;
			setSelectedDocuments?: never;
	  }
);

type DocumentUploadForm = {
	fileToUpload: File[] | null;
	documentName: DocumentName;
	documentFolder: S3Folder;
	pjType?: PjTypeIdentity;
};

const DocumentsList: FC<DocumentsListProps> = ({
	context,
	email,
	subscription,
	selectedDocuments,
	setSelectedDocuments,
}) => {
	const toast = useThemedToast();

	const { data: client } = useGetClientByEmailQuery(email);
	const { data: documents, isFetching: isDocumentsFetching } = useGetDocumentsQuery(
		{
			email: email,
			productType: ['deal', 'subscription'].includes(context) ? subscription?.productType : undefined,
			subscriptionId: ['deal', 'subscription'].includes(context) ? subscription?.id : undefined,
		},
		{ refetchOnFocus: true, refetchOnReconnect: true, pollingInterval: 300000 },
	);
	const [getSubscriptions] = useLazyGetSubscriptionsQuery();
	const [uploadDocument, { isLoading: isUploadLoading }] = useUploadDocumentMutation();

	const formMethods = useForm<DocumentUploadForm>({
		defaultValues: {
			fileToUpload: null,
			documentName: DocumentName.JUSTIFICATIF_DOMICILE,
			documentFolder: S3Folder.PJ,
			pjType: PjTypeIdentity.CARTE_IDENTITE,
		},
	});
	const { setValue, watch, control, handleSubmit } = formMethods;

	const [filterDocumentName, setFilterDocumentName] = useState('');
	const [filterFolder, setFilterFolder] = useState<string[]>([]);
	const [filterExtension, setFilterExtension] = useState<string[]>([]);

	const folderOptions = useMemo(() => [...new Set(documents?.map((d) => d.folder))].sort(), [documents]);
	const extensionOptions = useMemo(() => [...new Set(documents?.map((d) => d.contentType ?? ''))].sort(), [documents]);

	const resetFileInput = useCallback(() => {
		formMethods.reset({ fileToUpload: null });
	}, [formMethods]);

	const handleFileUpload = useCallback(
		async (data: DocumentUploadForm) => {
			if (isNone(data.fileToUpload)) {
				toast({
					title: 'Erreur',
					description: 'Veuillez sélectionner un fichier à uploader',
					status: 'error',
				});
				return;
			}

			const formData = new FormData();
			Array.from(data.fileToUpload).forEach((file) => formData.append('files', file));
			formData.append('userId', client!.id);
			formData.append('documentName', data.documentName);
			if (isNotNone(data.pjType) && data.documentName.includes(DocumentName.PIECE_IDENTITE))
				formData.append('pjType', data.pjType);

			if (context !== 'client') {
				formData.append('productType', subscription.productType);
				formData.append('subscriptionId', subscription.id);
				if (subscription && 'moralPersonId' in subscription && subscription.moralPersonId)
					formData.append('moralPersonId', subscription.moralPersonId);
				if (subscription && 'bankInformationId' in subscription && subscription.bankInformationId)
					formData.append('bankInformationId', subscription.bankInformationId);
			}

			await uploadDocument(formData)
				.unwrap()
				.catch((e) => toast({ title: 'Erreur', description: e.data.message, status: 'error' }));
			resetFileInput();
		},
		[client, context, subscription, toast, uploadDocument, resetFileInput],
	);

	// preselect documents
	useEffect(() => {
		if (context !== 'subscription' || isNone(documents)) return;
		if (
			subscription.productType === ProductType.INVEST &&
			subscription.dealType !== 'TRANSFER' &&
			(subscription as OpsInvestDeal).envelope.type === 'PER'
		) {
			setSelectedDocuments(
				documents.filter(async (doc) => {
					if (doc.folder !== 'per-transfer') return;
					const subs = await getSubscriptions({ searchBy: 'email', input: email }).unwrap();
					const transferId = doc.url.slice(doc.url.indexOf('per-transfer')).split('/')[1];
					const transferRequest = subs?.find((s) => s.id === transferId);
					if (isNone(transferRequest)) return;
					return (transferRequest as OpsTransferDeal).status === SubscriptionStatus.SIGNED;
				}),
			);
			return;
		}
	}, [context, documents, email, getSubscriptions, setSelectedDocuments, subscription]);

	const [watchFile, watchFolder, watchDocName] = watch(['fileToUpload', 'documentFolder', 'documentName']);

	// update default document to upload when upload folder changes
	useEffect(() => {
		setValue(
			'documentName',
			Object.keys(documentDataMap).find(
				(key) => documentDataMap[key as DocumentName].folder === watchFolder,
			) as DocumentName,
		);
	}, [setValue, watchFolder]);

	return (
		<Cardlayout title="Documents">
			<HStack w="100%" mb="16px">
				<Input
					size="md"
					placeholder="Rechercher un document"
					w="350px"
					value={filterDocumentName}
					onChange={(e) => setFilterDocumentName(e.target.value)}
				/>
				<FilterPopovers
					components={[
						{
							title: 'Extension',
							componentProps: {
								value: filterExtension,
								onChange: (v: string[]) => setFilterExtension(v),
								options: extensionOptions,
							},
						},
						{
							title: 'Dossier',
							componentProps: {
								value: filterFolder,
								onChange: (v: string[]) => setFilterFolder(v),
								options: folderOptions,
							},
						},
					]}
				/>
			</HStack>
			<Skeleton isLoaded={!isDocumentsFetching} w="100%">
				<DocumentsTable
					docList={(documents ?? [])
						.filter((doc) => doc.folder !== 'TaxNotice')
						.filter((doc) => filterFolder.length === 0 || filterFolder.includes(doc.folder))
						.filter((doc) => filterExtension.length === 0 || filterExtension.includes(doc.contentType ?? ''))
						.filter((doc) =>
							(documentDataMap[doc.documentName]?.displayName ?? doc.documentName)
								.toLocaleLowerCase()
								.includes(filterDocumentName.toLocaleLowerCase()),
						)}
					selectedDocs={selectedDocuments}
					setSelectedDocs={setSelectedDocuments}
					hideSelect={context !== 'subscription'}
				/>
			</Skeleton>

			<chakra.form onSubmit={handleSubmit(handleFileUpload)}>
				<Wrap mt="16px">
					<Controller
						control={control}
						name="fileToUpload"
						render={({ field }) => (
							<FilePicker
								multiple={false}
								shouldShowSelectButton
								shouldShowSelectedFiles={false}
								onDrop={(files) => field.onChange(files)}
							/>
						)}
					/>

					<Controller
						control={control}
						name="documentFolder"
						render={({ field }) => (
							<Select {...field} w="300px">
								<option value={S3Folder.PJ}>Pièce justificative</option>
								{['deal', 'subscription'].includes(context) && (
									<option value={S3Folder.SUBSCRIPTION}>Document lié à la souscription</option>
								)}
								{['deal', 'subscription'].includes(context) && (
									<option value={S3Folder.BANK_ACCOUNT}>Document bancaire</option>
								)}
								{['deal', 'subscription'].includes(context) &&
									isNotNone(subscription) &&
									'moralPersonId' in subscription &&
									isNotNone(subscription.moralPersonId) && (
										<option value={S3Folder.MORAL_PERSON}>Document personne morale</option>
									)}
							</Select>
						)}
					/>

					<Controller
						control={control}
						name="documentName"
						render={({ field }) => (
							<Select {...field} w="300px">
								{Object.entries(documentDataMap)
									.filter(([, value]) => value.folder === watchFolder)
									.map(([key, value]) => (
										<option key={key} value={key}>
											{value.displayName}
										</option>
									))}
							</Select>
						)}
					/>

					{watchDocName?.includes(DocumentName.PIECE_IDENTITE) && (
						<Controller
							control={control}
							name="pjType"
							render={({ field }) => (
								<Select {...field} w="300px">
									{Object.entries(PjTypeIdentityMap).map(([key, value]) => (
										<option key={key} value={key}>
											{value}
										</option>
									))}
								</Select>
							)}
						/>
					)}

					{isNotNone(watchFile) && (
						<>
							<Button type="submit" colorScheme="blue" isLoading={isUploadLoading}>
								Uploader {watchFile[0].name ?? ''}
							</Button>
							<CloseIcon cursor="pointer" boxSize="12px" onClick={() => resetFileInput()} />
						</>
					)}
				</Wrap>
			</chakra.form>
		</Cardlayout>
	);
};

export default DocumentsList;
