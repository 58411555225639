import { useCallback, useMemo, useState } from 'react';
import {
	Button,
	Checkbox,
	Divider,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	Heading,
	HStack,
	Input,
	Skeleton,
	Stack,
	Switch,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import NumberInputWithStepper from 'components/NumberInput';
import RebalancingVPsTable from 'components/tables/RebalancingVPsTable';
import useThemedToast from 'hooks/useThemedToast';
import { RebalancingType } from 'services/rebalancing-contrat';
import {
	useApplyVpRebalancingMutation,
	useGetVPsToRebalanceQuery,
	useRebalanceVpBatchMutation,
	useRecomputeVpRebalancingMutation,
} from 'services/rebalancing-versement';
import { ContractVpRebalancing } from 'types/invest-deal.type';
import { PortfolioType } from 'types/investmentPreferences.type';
import { isNotNone } from 'utils/functions';

import { BatchBehaviorType, BatchModal } from './BatchModal';
import BarGraph from './GraphBar';
import { defaultPortfolioTypeValues, GreenType } from './utils';

const RebalancingVP = () => {
	const toast = useThemedToast();
	const { isOpen: isBatchModalOpen, onOpen: openBatchModal, onClose: closeBatchModal } = useDisclosure();

	const [selectedContract, setSelectedContract] = useState<ContractVpRebalancing>();

	const [minTurnover, setMinTurnover] = useState(0);
	const [maxTurnover, setMaxTurnover] = useState(15);
	const [minRisk, setMinRisk] = useState(1);
	const [maxRisk, setMaxRisk] = useState(10);
	const [greenFilter, setGreenFilter] = useState<GreenType>(GreenType.BOTH);
	const [lastUpdated, setLastUpdated] = useState(0);
	const [modifiedOnly, setModifiedOnly] = useState(false);
	const [holdings, setHoldings] = useState<string>('');

	// portfolioType
	const [portfolioFilter, setPortfolioFilter] = useState(defaultPortfolioTypeValues);
	const portfolioAllChecked = useMemo(() => Object.values(portfolioFilter).every(Boolean), [portfolioFilter]);
	const portfolioIsIndeterminate = useMemo(
		() => Object.values(portfolioFilter).some(Boolean) && !portfolioAllChecked,
		[portfolioAllChecked, portfolioFilter],
	);
	const portfolioTypeFormatted = useMemo(
		() =>
			Object.entries(portfolioFilter)
				.filter(([, value]) => value)
				.map(([key]) => key as PortfolioType),
		[portfolioFilter],
	);

	const { data, isFetching } = useGetVPsToRebalanceQuery(
		{
			min: +minTurnover / 100,
			max: +maxTurnover / 100,
			rebalanceType: RebalancingType.GLOBAL_REBALANCE, // has no effect
			green: greenFilter === GreenType.BOTH ? undefined : greenFilter === GreenType.GREEN,
			portfolioTypes: portfolioTypeFormatted,
			maxRisk,
			minRisk,
			holdings: holdings.split(/[\s,]+/).filter(Boolean),
			lastUpdated,
			modifiedOnly,
		},
		{ skip: portfolioTypeFormatted.length === 0 },
	);
	const [reCompute, { isLoading: isRecomputeLoading, reset }] = useRecomputeVpRebalancingMutation();
	const [triggerVpBatchRebalance] = useRebalanceVpBatchMutation();
	const [applyRebalancing, { isLoading: isRebalancingLoading }] = useApplyVpRebalancingMutation();

	const handleRecompute = useCallback(
		(contract: ContractVpRebalancing, saveRecompute: boolean) => {
			reCompute({ contractId: contract.id, saveRecompute: saveRecompute })
				.unwrap()
				.then((r) => {
					setSelectedContract((c) => ({
						...c!,
						programmedVersement: {
							...c!.programmedVersement,
							rebalancingData: r,
						},
					}));
					toast({ status: 'success', title: 'Succès', description: 'Recompute done' });
				})
				.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
		},
		[reCompute, toast],
	);

	const handleApplyRebalancing = useCallback(
		(contract: ContractVpRebalancing) => {
			applyRebalancing({ contractId: contract.id })
				.unwrap()
				.then(() => {
					toast({ status: 'success', title: 'Succès', description: 'Rebalancing done' });
				})
				.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
		},
		[applyRebalancing, toast],
	);

	const handleVpBatchRebalance = useCallback(() => {
		if (!data) return;
		triggerVpBatchRebalance(data.map((contract) => contract.id))
			.unwrap()
			.then(() => {
				toast({
					status: 'success',
					title: 'Les arbitrages ont été déclenchés',
					description: 'Un reporting vous sera envoyé (~1s/contrat)',
				});
			})
			.catch(() => {
				toast({
					status: 'error',
					title: "Une erreur s'est produite",
				});
			});
	}, [data, toast, triggerVpBatchRebalance]);

	return (
		<VStack w="100%" p="32px" align="start" bg="white" spacing="32px">
			<Heading size="lg">Arbitrage Versements Programmés Invest</Heading>

			<VStack w="100%" align="start" spacing="16px">
				<FormControl isRequired>
					<HStack w="100%">
						<FormLabel w="200px">Turnover</FormLabel>
						<HStack spacing="32px">
							<NumberInputWithStepper
								precision={1}
								min={0}
								max={100}
								value={minTurnover + '%'}
								onChange={(s) => setMinTurnover(+s)}
							/>

							<Text>à</Text>

							<NumberInputWithStepper
								precision={1}
								min={0}
								max={100}
								value={maxTurnover + '%'}
								onChange={(s) => setMaxTurnover(+s)}
							/>
						</HStack>
					</HStack>
				</FormControl>

				<FormControl isRequired>
					<HStack w="100%">
						<FormLabel w="200px">Risk</FormLabel>
						<HStack spacing="32px">
							<NumberInputWithStepper
								precision={1}
								min={1}
								max={10}
								value={minRisk}
								onChange={(s, n) => setMinRisk(n)}
								step={0.1}
							/>

							<Text>à</Text>

							<NumberInputWithStepper
								precision={1}
								min={1}
								max={10}
								value={maxRisk}
								onChange={(s, n) => setMaxRisk(n)}
								step={0.1}
							/>
						</HStack>
					</HStack>
				</FormControl>

				<FormControl isRequired>
					<HStack w="100%" align="start">
						<FormLabel w="200px">ESG</FormLabel>
						<VStack spacing="8px" align="start">
							<Checkbox
								isIndeterminate={greenFilter !== GreenType.BOTH}
								isChecked={greenFilter === GreenType.BOTH}
								onChange={() => {
									setGreenFilter(GreenType.BOTH);
								}}
							/>
							<Stack pl="32px" spacing="4px">
								<Checkbox
									isChecked={greenFilter !== GreenType.NOTGREEN}
									isDisabled={greenFilter === GreenType.GREEN}
									onChange={() => {
										setGreenFilter(greenFilter !== GreenType.BOTH ? GreenType.BOTH : GreenType.NOTGREEN);
									}}
								>
									Oui
								</Checkbox>

								<Checkbox
									isChecked={greenFilter !== GreenType.GREEN}
									isDisabled={greenFilter === GreenType.NOTGREEN}
									onChange={() => {
										setGreenFilter(greenFilter !== GreenType.BOTH ? GreenType.BOTH : GreenType.GREEN);
									}}
								>
									Non
								</Checkbox>
							</Stack>
						</VStack>
					</HStack>
				</FormControl>

				<FormControl>
					<HStack w="100%" align="start">
						<FormLabel w="200px">Holdings</FormLabel>
						<VStack spacing="8px" align="start">
							<Input value={holdings} onChange={(e) => setHoldings(e.target.value)} />
						</VStack>
					</HStack>
				</FormControl>

				<FormControl isRequired isInvalid={portfolioTypeFormatted.length === 0}>
					<HStack w="100%" align="start">
						<FormLabel w="200px">Type de portefeuille</FormLabel>
						<VStack spacing="8px" align="start">
							<Checkbox
								isChecked={portfolioAllChecked}
								isIndeterminate={portfolioIsIndeterminate}
								onChange={() => {
									setPortfolioFilter(
										Object.entries(defaultPortfolioTypeValues).reduce(
											(acc, [key]) => ({ ...acc, [key]: !portfolioAllChecked }),
											portfolioFilter,
										),
									);
								}}
							/>

							<Stack pl="32px" spacing="4px">
								{Object.entries(portfolioFilter).map(([key, value]) => (
									<Checkbox
										key={key}
										isChecked={value}
										onChange={() => {
											setPortfolioFilter({ ...portfolioFilter, [key]: !value });
										}}
									>
										{key}
									</Checkbox>
								))}
							</Stack>
						</VStack>
					</HStack>
				</FormControl>

				<FormControl>
					<HStack w="100%">
						<HStack w="200px">
							<FormLabel alignSelf="center">Last updated</FormLabel>
						</HStack>
						<NumberInputWithStepper
							precision={1}
							min={0}
							max={100}
							value={' > ' + lastUpdated + ' mois'}
							onChange={(s) => setLastUpdated(+s)}
						/>
					</HStack>
				</FormControl>

				<FormControl>
					<HStack w="200px" justify="space-between">
						<FormLabel alignSelf="center">Only Modified</FormLabel>
						<Switch isChecked={modifiedOnly} onChange={() => setModifiedOnly(!modifiedOnly)} />
					</HStack>
				</FormControl>
			</VStack>

			<FormControl>
				<HStack align="start">
					<FormLabel>
						<Heading size="sm">Arbitrage en batch</Heading>
					</FormLabel>

					<Button isDisabled={!data || data?.length === 0} onClick={openBatchModal}>
						Arbitrer en batch {data?.length} contrats
					</Button>
				</HStack>
			</FormControl>

			<BatchModal
				isOpen={isBatchModalOpen}
				onClose={closeBatchModal}
				behavior={BatchBehaviorType.SKIP}
				rebalanceType={RebalancingType.GLOBAL_REBALANCE}
				contractsNb={data?.length ?? 0}
				onTrigger={() => {
					handleVpBatchRebalance();
				}}
			/>

			<Divider />

			<VStack w="100%" align="start" spacing="16px">
				<Heading as="h4" size="md">
					Contracts à arbitrer
				</Heading>

				<Skeleton isLoaded={!isFetching} w="100%">
					<RebalancingVPsTable
						contracts={data ?? []}
						pageSize={10}
						selectedContract={selectedContract}
						onClick={(contract) => {
							setSelectedContract(contract);
							reset();
							document.getElementById('visualisation')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
						}}
					/>
				</Skeleton>
			</VStack>

			{isNotNone(selectedContract?.programmedVersement?.rebalancingData) && (
				<>
					<Divider />

					<VStack w="100%" align="start" spacing="32px">
						<HStack w="100%" justify="space-between">
							<Heading as="h4" size="md" id="visualisation">
								Visualisation - {selectedContract.user.email}
							</Heading>
							<Text>
								Last refresh date:{' '}
								<b>
									{new Date(selectedContract.programmedVersement.rebalancingData.last_refresh_date).toLocaleDateString(
										'fr-FR',
									)}
								</b>
							</Text>
						</HStack>

						<Grid templateColumns="repeat(10, 1fr)" gap="30px" w="100%" mb="40px">
							<GridItem colSpan={5}>
								<BarGraph
									data={selectedContract.programmedVersement.rebalancingData.turnover_asset_category_view_dict}
									turnover={selectedContract.programmedVersement.rebalancingData.turnover_asset_category_view_euro}
									aum={selectedContract.amount ? +selectedContract.amount : 0}
									category="Asset Category"
									title="Asset Category View"
								/>
							</GridItem>
							<GridItem colSpan={5}>
								<BarGraph
									data={selectedContract.programmedVersement.rebalancingData.turnover_asset_class_view_dict}
									turnover={selectedContract.programmedVersement.rebalancingData.turnover_asset_class_view_euro}
									aum={selectedContract.amount ? +selectedContract.amount : 0}
									category="Asset Class"
									title="Asset Class View"
								/>
							</GridItem>
							<GridItem colSpan={10}>
								<BarGraph
									data={selectedContract.programmedVersement.rebalancingData.turnover_basic_view_dict}
									turnover={selectedContract.programmedVersement.rebalancingData.turnover_basic_view_euro}
									aum={selectedContract.amount ? +selectedContract.amount : 0}
									category=""
									title="Basic View"
									height="340px"
								/>
							</GridItem>
						</Grid>

						{/* Dashboard for Apicil monitoring */}
						<Grid templateColumns="repeat(12, 1fr)" gap="30px" w="100%">
							<GridItem colSpan={4}>
								<BarGraph
									data={selectedContract.programmedVersement.rebalancedWith ?? {}}
									aum={selectedContract.amount ? +selectedContract.amount : 0}
									category=""
									title="Last Updated Allocation"
									height="340px"
								/>
							</GridItem>
							<GridItem colSpan={4}>
								<BarGraph
									data={selectedContract.programmedVersement.rebalancingData.current_allocation_weights}
									aum={selectedContract.amount ? +selectedContract.amount : 0}
									category=""
									title="Apicil Data"
									height="340px"
								/>
							</GridItem>
							<GridItem colSpan={4}>
								<BarGraph
									data={selectedContract.programmedVersement.rebalancingData.recommended_allocation_weights}
									aum={selectedContract.amount ? +selectedContract.amount : 0}
									category=""
									title="Recommended Allocation"
									height="340px"
								/>
							</GridItem>
						</Grid>
					</VStack>

					<HStack w="100%" justify="space-between">
						<HStack>
							<Button isLoading={isRecomputeLoading} onClick={() => handleRecompute(selectedContract, false)}>
								Visualize rebalancing
							</Button>
							<Button isLoading={isRecomputeLoading} onClick={() => handleRecompute(selectedContract, true)}>
								Recompute rebalancing
							</Button>
						</HStack>
						<Button
							colorScheme="red"
							isLoading={isRebalancingLoading}
							onClick={() => handleApplyRebalancing(selectedContract)}
						>
							Arbitrer le versement
						</Button>
					</HStack>
				</>
			)}
		</VStack>
	);
};

export default RebalancingVP;
