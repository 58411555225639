import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
	Checkbox,
	HStack,
	Select,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Text,
	VStack,
} from '@chakra-ui/react';
import { format } from 'd3-format';

import NumberInputWithStepper from 'components/NumberInput';
import { CreditUniverse, ScpiCreditSimulationForm } from 'pages/cpm/scpi-credit/forms/formTypes';
import FamilySituationReferentiel, { FamilySituation } from 'types/referentiels/familySituation';

type PersonalInformationProps = {
	formMethods: UseFormReturn<ScpiCreditSimulationForm>;
};

const PersonalInformation: FC<PersonalInformationProps> = ({ formMethods: { control, watch } }) => {
	const nbChildren = watch('nbChildren');
	const maxScpiByPartner = watch('maxScpiByPartner');

	return (
		<VStack align="start" spacing="30px" w="100%">
			<VStack w="100%" align="start">
				<Text variant="Text-S-Regular">Univers Crédit</Text>
				<Controller
					name="creditUniverse"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<Select {...field} bg="white">
							{Object.values(CreditUniverse).map((univers) => (
								<option key={univers} value={univers}>
									{univers}
								</option>
							))}
						</Select>
					)}
				/>
			</VStack>
			<HStack w="100%" justifyContent={{ base: 'center', md: 'space-between' }}>
				<Controller
					name="isr"
					control={control}
					render={({ field: { onChange, value, ref } }) => (
						<Checkbox onChange={onChange} isChecked={value} ref={ref}>
							<Text variant="Text-S-Regular">Filtre ISR</Text>
						</Checkbox>
					)}
				/>
			</HStack>
			<VStack w="100%" align="start">
				<Text variant="Text-S-Regular">Apport Personnel</Text>
				<Controller
					name="personalContribution"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<NumberInputWithStepper
							w="100%"
							bg="white"
							{...field}
							min={0}
							step={100}
							value={format(',.3~f')(field.value) + ' €'}
							onChange={(value) => field.onChange(value)}
						/>
					)}
				/>
			</VStack>
			<HStack w="100%" justifyContent={{ base: 'center', md: 'space-between' }}>
				<Controller
					name="moralPerson"
					control={control}
					render={({ field: { onChange, value, ref } }) => (
						<Checkbox onChange={onChange} isChecked={value} ref={ref}>
							<Text variant="Text-S-Regular">Personne Morale</Text>
						</Checkbox>
					)}
				/>
			</HStack>
			<VStack w="100%" align="start">
				<Text variant="Text-S-Regular">Situation Familliale</Text>
				<Controller
					name="familySituation"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<Select {...field} bg="white">
							{Object.values(FamilySituation).map((situation) => (
								<option key={situation} value={situation}>
									{FamilySituationReferentiel[situation]}
								</option>
							))}
						</Select>
					)}
				/>
			</VStack>
			<VStack w="100%" align="start">
				<HStack w="100%" justifyContent={{ base: 'center', md: 'space-between' }}>
					<Text variant="Text-S-Regular">Nombre d'enfants</Text>
					<Text fontSize="sm" color="grey.500">
						{nbChildren}/12
					</Text>
				</HStack>
				<Controller
					name="nbChildren"
					control={control}
					render={({ field: { onChange, value } }) => (
						<Slider value={value} min={0} max={12} step={1} onChange={onChange}>
							<SliderTrack>
								<SliderFilledTrack />
							</SliderTrack>
							<SliderThumb />
						</Slider>
					)}
				/>
			</VStack>
			<HStack w="100%" justifyContent={{ base: 'center', md: 'space-between' }}>
				<Text variant="Text-S-Regular">Pourcentage étranger</Text>
				<Controller
					name="abroadPercentage"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<NumberInputWithStepper
							w="100%"
							bg="white"
							{...field}
							min={0}
							step={1}
							value={`${field.value} %`}
							onChange={(value) => field.onChange(value)}
						/>
					)}
				/>
			</HStack>
			<VStack w="100%" align="start">
				<HStack w="100%" justifyContent={{ base: 'center', md: 'space-between' }}>
					<Text variant="Text-S-Regular">Nombre de SCPI par partenaire (max)</Text>
					<Text fontSize="sm" color="grey.500">
						{maxScpiByPartner}/5
					</Text>
				</HStack>
				<Controller
					name="maxScpiByPartner"
					control={control}
					render={({ field: { onChange, value } }) => (
						<Slider value={value} min={1} max={5} step={1} onChange={onChange}>
							<SliderTrack>
								<SliderFilledTrack />
							</SliderTrack>
							<SliderThumb />
						</Slider>
					)}
				/>
			</VStack>
		</VStack>
	);
};

export default PersonalInformation;
