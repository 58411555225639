import { Route, Routes } from 'react-router-dom';

import ArtDetails from './art/ArtDetails';
import ArtList from './art/ArtList';
import CrowdfundingDetails from './crowdfunding/CrowdfundingDetails';
import CrowdfundingList from './crowdfunding/CrowdfundingList';

const PartnershipArtCopilot = () => (
	<Routes>
		<Route path="/art" element={<ArtList />} />
		<Route path="/art/:id" element={<ArtDetails />} />

		<Route path="/crowdfunding" element={<CrowdfundingList />} />
		<Route path="/crowdfunding/:id" element={<CrowdfundingDetails />} />
	</Routes>
);

export default PartnershipArtCopilot;
