import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Divider, Heading, SimpleGrid, Text, VStack } from '@chakra-ui/react';

import { RecentClient } from 'pages/ops/super/client/Client';

type RecentClientsProps = {
	recentClients: RecentClient[];
	basePath?: string;
};

const RecentClients: React.FC<RecentClientsProps> = ({ basePath = 'ops', recentClients }) => {
	if (recentClients.length === 0) {
		return null;
	}

	return (
		<VStack align="start" width="100%" mb={6}>
			<Heading size="md">Clients récents</Heading>
			<Divider />
			<SimpleGrid columns={3} spacing={4} width="100%">
				{recentClients.map((client) => (
					<Link to={`/${basePath}/super/client/${client.id}`} key={client.id}>
						<Box p={2} borderBottom="1px solid" borderColor="gray.100" _hover={{ bg: 'gray.50' }} cursor="pointer">
							<Text fontWeight="medium">{client.email}</Text>
						</Box>
					</Link>
				))}
			</SimpleGrid>
		</VStack>
	);
};

export default RecentClients;
