import { OpsCryptoDeal } from 'services/deal';
import { SubscriptionStatus } from 'types/global.type';
import { User } from 'types/user.type';

enum ClientRiskRating {
	LOW = 'LOW',
	MEDIUM = 'MEDIUM',
	HIGHT = 'HIGHT',
}

enum CryptoTimeHorizon {
	SHORT = 'SHORT',
	MEDIUM = 'MEDIUM',
	LONG = 'LONG',
}

enum CryptoKnowledge {
	NO_KNOWLEDGE = 'NO_KNOWLEDGE',
	KNOWLEDGE = 'KNOWLEDGE',
	EXPERIENCE = 'EXPERIENCE',
}

export type CryptoSubscription = {
	id: string;
	createdAt: Date;
	updatedAt: Date;

	// Ip Verification
	addressIp?: string;
	isIpApproved?: boolean;

	// Client Risk - Update during onboarding and set at account creation
	riskRating: ClientRiskRating;
	riskExplanation: string[];

	// Onboarding data
	cryptoKnowledge?: CryptoKnowledge;
	timeHorizon?: CryptoTimeHorizon;
	pastInvestAmount?: number;

	// have approved the terms and conditions
	isTermsApproved?: boolean;
	CGUVersion?: string;
	acceptDate?: Date;

	// Opening Process
	status: SubscriptionStatus;
	isCryptoPartnerCompleted?: boolean;
	isVirtualIbanPartnerCompleted?: boolean;
	isAdequationPartnerCompleted?: boolean;

	hubspotId?: string;

	partnerAdequationUrl?: string;
	user: User;
};

export enum PartnerAdequationState {
	CREATED = 'CREATED',
	REVIEWING = 'REVIEWING',
	PROCESSING = 'PROCESSING',
	COMPLETED = 'COMPLETED',
	REFUSED = 'REFUSED',
	EXPIRED = 'EXPIRED',
}

export type PartnerAdequation = {
	id: string;
	provider: string;
	externalProviderAccountId: string;

	adequationId: string;
	adequationRunId: string;
	status: PartnerAdequationState;

	partnerAdequationUrl?: string;

	createdAt: Date;
	updatedAt: Date;
};

export type VirtualIbanEndUser = {
	provider: string;
	externalProviderAccountId: string;
};

export type FiatrepublicVirtualIbanAccountResponse = {
	id: string;
	bankDetails: {
		iban: string;
		bic: string;
		accountHolderName: string;
	};
	label: string;
	metadata?: Partial<Record<string, string>>;
};

export enum GlobalPaymentTransactionStatus {
	CREATED = 'CREATED',
	PROCESSING = 'PROCESSING',
	COMPLETED = 'COMPLETED',
	REJECTED = 'REJECTED',
	COMPLIANCE_REVIEW = 'COMPLIANCE_REVIEW',
	MEMBER_REVIEW = 'MEMBER_REVIEW',
}

enum GlobalPaymentTransactionType {
	PAYIN = 'PAYIN',
	PAYOUT = 'PAYOUT',
}

export type VirtualIbanPaymentTransactionToReturn = {
	id: string;
	amount: string;
	status: GlobalPaymentTransactionStatus;
	type: GlobalPaymentTransactionType;
	fees?: string;
	iban: string;
	step: string;
	createdAt: Date;
	updatedAt: Date;
};

export type VirtualIbanBankAccount = {
	id: string;
	externalProviderId: string;
	iban: string;
	bic: string;
	bankName?: string;
};

export type CryptoBoUserProfile = {
	subscription: OpsCryptoDeal;
	partnerAdequation: PartnerAdequation;
	virtualIbanEndUser: VirtualIbanEndUser;
	virtualIbanAccounts?: (FiatrepublicVirtualIbanAccountResponse & {
		userWithdrawAccount: VirtualIbanBankAccount;
		userPaymentsTransactions: VirtualIbanPaymentTransactionToReturn[];
	})[];
};

enum CryptoPocketName {
	CORE = 'CORE',
	ETH = 'ETH',
	BTC = 'BTC',
	DOGE = 'DOGE',
	SOL = 'SOL',
	ADA = 'ADA', // Cardano
	AVAX = 'AVAX', // Avalanche
	LINK = 'LINK', // Link
	DOT = 'DOT', // polkadot
	PEPE = 'PEPE', // Pepe
	NEAR = 'NEAR', // Near
	ARB = 'ARB', // Arbitrum
	STRK = 'STRK', // StarkNet
	APE = 'APE', // ApeCoin
	AAVE = 'AAVE', // Aave
	CRV = 'CRV', // Curve
}

export enum CryptoInvestPlanPeriod {
	DAILY = 'DAILY',
	WEEKLY = 'WEEKLY',
	BI_WEEKLY = 'BI_WEEKLY',
	MONTHLY = 'MONTHLY',
}

enum PocketStrategy {
	NONE,
	REBALANCING,
}

export type CryptoPortfolio = {
	id: string;

	name: CryptoPocketName;
	strategy: PocketStrategy;
	logo: string;
	fullName?: string;
};

export const OnfidoStatusTranslate: Record<string, string> = {
	[PartnerAdequationState.CREATED]: "Created, en attente de l'utilisateur",
	[PartnerAdequationState.PROCESSING]: 'Processing, Une verification manuelle est en requise !',
	[PartnerAdequationState.REVIEWING]: 'Reviewing',
	[PartnerAdequationState.COMPLETED]: 'Completed',
	[PartnerAdequationState.REFUSED]: 'Refused',
};
