import { dashboardApi } from 'store/api';
import { PortfolioType } from 'types/investmentPreferences.type';
import { Kyc } from 'types/kyc.type';
import { User } from 'types/user.type';

export type TestAccount = {
	user: User;
};

export type OnboardedUser = User & { kyc: Kyc; completedAt?: string };

export type DefiscalisationPropalBody = {
	email: string;
	portfolioType: Exclude<PortfolioType, 'CUSTOM'>;
	riskScore: number;
	esg: boolean;
	temperature?: number;

	typePropal: ('pe' | 'per' | 'girardin')[];
	useBlackFees: boolean;

	amountToInvest: number;
	amountToInvestPE?: number;
	amountToInvestPER?: number;
	amountToInvestGirardin?: number;
};

type FiscaliteUserResult = {
	optimal_amount_to_invest: number;
	pe_amount: number;
	per_amount: number;
};

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		createDefiscalisationPropal: builder.mutation<void, DefiscalisationPropalBody>({
			query: (data) => ({
				url: '/bo/cpm/propale-defiscalisation',
				method: 'POST',
				body: data,
			}),
		}),
		createTestAccount: builder.mutation<TestAccount, void>({
			query: () => ({
				url: '/bo/cpm/propale-defiscalisation/test-account',
				method: 'POST',
			}),
		}),
		getUserAvisImposition: builder.query<{ url: string }, string>({
			query: (id) => ({
				url: `/bo/cpm/propale-defiscalisation/user/${id}/avis-imposition`,
				method: 'GET',
			}),
		}),
		getClientsWhoDidPropal: builder.query<OnboardedUser[], void>({
			query: () => ({
				url: `/bo/cpm/propale-defiscalisation/users-imposition`,
				method: 'GET',
			}),
		}),
		getResultFiscaliteForClient: builder.query<FiscaliteUserResult, { id: string }>({
			query: ({ id }) => ({
				url: `/bo/cpm/propale-defiscalisation/fiscalite/user/${id}/result`,
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useCreateDefiscalisationPropalMutation,
	useCreateTestAccountMutation,
	useGetUserAvisImpositionQuery,
	useGetClientsWhoDidPropalQuery,
	useGetResultFiscaliteForClientQuery,
} = endpoints;
