import { StructuredProductFund } from 'types/airtable/structured.airtable.type';
import { ArtFund } from 'types/art.type';
import { AvLuxProvider } from 'types/avLux.type';
import { CrowdfundingFund } from 'types/crowdfunding.type';
import { CryptoInvestPlanPeriod, CryptoPortfolio } from 'types/crypto.types';
import { GirardinProvider } from 'types/girardin.type';
import { LegalEntity } from 'types/global.type';
import { EnvelopeType, InsuranceProvider, InvestProjectType, VersementRequestType } from 'types/invest-deal.type';
import { PortfolioType } from 'types/investmentPreferences.type';
import { PeFund } from 'types/pe.type';
import {
	SavingsAccountInvestmentDuration,
	SavingsAccountProvider,
	SavingsAccountType,
} from 'types/savingsAccount.type';
import { ScpiFund, ScpiFundDemembrement, ScpiProjectType } from 'types/scpi.type';

export type CustomPortfolio = { ISIN: string; repartition: number }[];

export enum RamifyGreen {
	NO = 'no',
	TWO = '2.0',
	TWO_FIVE = '2.5',
}

export type InvestData = {
	id: number;
	initialDepositAmount: number;
	provider: InsuranceProvider;
	portfolioType: PortfolioType;
	envelope: EnvelopeType;
	objective: InvestProjectType;
	duration: number;
	green: RamifyGreen;
	monthlySaving: number;
	risk: string; // string to allow more precise edition in input
	birthYear?: number;
	workedYears?: number;
	customPortfolio?: CustomPortfolio;
	comment?: string;
};

export type VersementData = {
	id: number;
	contractId: string;
	versementRequestType: VersementRequestType;
	envelope: EnvelopeType;
	initialDepositAmount: number;
	contractCurrentAmount: number;
};

export type CryptoVersementData = {
	id: number;
	cryptoPocket: CryptoPortfolio;
	versementRequestType: VersementRequestType;
	initialDepositAmount: number;
	triggerAt?: Date; // Only for versementRequestType: PROGRAMME
	period?: CryptoInvestPlanPeriod; // Only for versementRequestType: PROGRAMME
	comment?: string;
};

export type StructuredData = {
	id: number;
	initialDepositAmount: number;
	product: StructuredProductFund;
	comment?: string;
};

export type CashData = {
	id: number;
	initialDepositAmount: number;
	provider: SavingsAccountProvider;
	type: SavingsAccountType;
	legalEntity: LegalEntity;
	duration?: SavingsAccountInvestmentDuration;
	comment?: string;
};

export type ScpiFundData = {
	id: number;
	initialDepositAmount: number;
	legalEntity: LegalEntity;
	fund: ScpiFund;
	projectType: ScpiProjectType;
	nbShare: number;
	demembrementDuration: number;
	demembrementData: ScpiFundDemembrement | null;
	comment?: string;
};

export type PeFundData = {
	id: number;
	initialDepositAmount: number;
	legalEntity: LegalEntity;
	fund: PeFund;
	isReUp: boolean;
	reUpAmount?: number;
	reUpComment?: string;
	comment?: string;
};

export type CrowdfundingFundData = {
	id: number;
	initialDepositAmount: number;
	legalEntity: LegalEntity;
	fund: CrowdfundingFund;
	comment?: string;
};

export type ArtData = {
	id: number;
	fund: ArtFund;
	legalEntity: LegalEntity;
	initialDepositAmount: number;
	comment?: string;
};

export type AvLuxData = {
	id: number;
	initialDepositAmount: number;
	risk: number;
	provider: AvLuxProvider;
	comment?: string;
};

export type GirardinData = {
	id: number;
	initialDepositAmount: number;
	provider: GirardinProvider;
	comment?: string;
};
