import { dashboardApi } from 'store/api';
import { CrowdfundingFund } from 'types/crowdfunding.type';

type ConvertToStrings<T> = {
	[K in keyof T]: K extends 'versementDesInterets' ? string[] : string;
};
export type StringifiedCrowdfundingFundDTO = ConvertToStrings<CrowdfundingFund>;

export function convertToStringifiedArtFundDTO(crowfund: CrowdfundingFund): StringifiedCrowdfundingFundDTO {
	const stringifiedCrowdfundingFund: Partial<StringifiedCrowdfundingFundDTO> = {};

	for (const key of Object.keys(crowfund) as Array<keyof CrowdfundingFund>) {
		if (key === 'versementDesInterets') {
			stringifiedCrowdfundingFund[key] = crowfund[key];
		} else {
			stringifiedCrowdfundingFund[key] = String(crowfund[key]);
		}
	}
	stringifiedCrowdfundingFund.versementDesInterets = crowfund.versementDesInterets;
	return stringifiedCrowdfundingFund as StringifiedCrowdfundingFundDTO;
}

export type UploadStatus = {
	status: string;
	id: string;
};

export type CrowdfundingFixedDTO = {
	id: string;
	rawCrowdfunding: CrowdfundingFund;
	updatedCrowdfunding: StringifiedCrowdfundingFundDTO;
	opsComments: Record<string, string>;
};

export type CrowdfundingEditDTO = {
	alreadyCreated: boolean;
	crowdfunding: CrowdfundingFund;
};

export type CrowdfundingStatus = {
	id: string;
	status: boolean;
	name: string;
	progress: number;
};

export interface CrowdfundingWithSources extends CrowdfundingFund {
	sources: Record<string, string>;
}

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		uploadCrowdfundingNote: builder.mutation<UploadStatus, FormData>({
			query: (formData) => ({
				url: '/ai/crowdfundings',
				method: 'POST',
				body: formData,
			}),
		}),
		correctCrowdfundingNote: builder.mutation<CrowdfundingEditDTO, CrowdfundingFixedDTO>({
			query: ({ id, ...body }) => ({
				url: `/ai/crowdfundings/${id}`,
				method: 'PUT',
				body,
			}),
		}),
		getAllCrowdfundingNotes: builder.query<CrowdfundingStatus[], void>({
			query: () => ({
				url: '/ai/crowdfundings',
				method: 'GET',
			}),
		}),
		getCrowdfundingNote: builder.query<CrowdfundingWithSources | undefined, string>({
			query: (id) => ({
				url: `/ai/crowdfundings/${id}`,
				method: 'GET',
			}),
		}),
		deleteCrowdfundingNote: builder.mutation<void, string>({
			query: (id) => ({
				url: `/ai/crowdfundings/${id}`,
				method: 'DELETE',
			}),
		}),
	}),
});

export const {
	useUploadCrowdfundingNoteMutation,
	useCorrectCrowdfundingNoteMutation,
	useGetAllCrowdfundingNotesQuery,
	useGetCrowdfundingNoteQuery,
	useDeleteCrowdfundingNoteMutation,
} = endpoints;
