import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseIcon, DownloadIcon } from '@chakra-ui/icons';
import { Button, Heading, HStack, IconButton, Skeleton, Spinner, Text, VStack } from '@chakra-ui/react';

import FilePicker from 'components/FilePicker';
import {
	useDeleteCrowdfundingNoteMutation,
	useGetAllCrowdfundingNotesQuery,
	useUploadCrowdfundingNoteMutation,
} from 'services/ai/crowdfunding';

const CrowdfundingList = () => {
	const navigate = useNavigate();

	const { data: crowdfunds, isLoading, refetch } = useGetAllCrowdfundingNotesQuery();
	const [uploadDocument, { isLoading: isUploadLoading }] = useUploadCrowdfundingNoteMutation();
	const [deleteCrowdFund] = useDeleteCrowdfundingNoteMutation();

	const [fileToUpload, setFileToUpload] = useState<File[] | null>(null);

	const resetFileInput = useCallback(() => {
		setFileToUpload(null);
	}, []);

	const handleFileUpload = async (files: File[]) => {
		const formData = new FormData();
		formData.append('file', files[0]);

		await uploadDocument(formData).unwrap();
		resetFileInput();
		refetch();
	};

	const handleDelete = async (id: string) => {
		await deleteCrowdFund(id).unwrap();
		refetch();
	};

	useEffect(() => {
		refetch();

		const interval = setInterval(() => {
			refetch();
		}, 10000);

		return () => clearInterval(interval);
	}, [refetch]);

	if (isLoading) return <Skeleton />;
	return (
		<VStack width="100%" minHeight="calc(100vh - 65px)" bg="white" spacing="160px" py="32px">
			<HStack justify="end" width="100%" px="32px">
				<Button
					bg="yellow.400"
					color="black"
					_hover={{ bg: 'yellow.500' }}
					size="lg"
					onClick={() => navigate('/partnership/copilot/art')}
				>
					Art
				</Button>
				<Button
					bg="yellow.400"
					color="black"
					_hover={{ bg: 'yellow.500' }}
					size="lg"
					onClick={() => navigate('/partnership/copilot/scpi')}
					isDisabled
				>
					SCPI
				</Button>
				<Button
					bg="yellow.400"
					color="black"
					_hover={{ bg: 'yellow.500' }}
					size="lg"
					onClick={() => navigate('/partnership/copilot/pe')}
					isDisabled
				>
					PE
				</Button>
			</HStack>

			<VStack align="center" spacing="16px" py="90px">
				<Heading as="h1" size="lg">
					Copilot Club deal
				</Heading>
				<Text fontSize="md" color="gray.600">
					Facilite et vous accompagne dans l'intégration d'une note d'investissement
				</Text>

				<VStack spacing={4} width="100%" maxWidth="550px">
					{crowdfunds?.map((artwork) => (
						<HStack key={artwork.id} width="100%" spacing={2}>
							{artwork.status === false ? (
								<Button
									bg={`linear-gradient(to right, green ${artwork.progress}%, gray ${artwork.progress}%)`}
									color="black"
									_hover={{
										bg: `linear-gradient(to right, green ${artwork.progress}%, gray ${artwork.progress}%)`,
									}}
									size="lg"
									width="100%"
									isDisabled
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									<Text>En cours</Text>
									<Spinner size="sm" ml={2} />
								</Button>
							) : (
								<Button
									bg="black"
									color="white"
									_hover={{ bg: 'gray.700' }}
									size="lg"
									width="100%"
									onClick={() => navigate(`/partnership/copilot/crowdfunding/${artwork.id}`)}
								>
									{artwork.name}
								</Button>
							)}
							<IconButton
								aria-label="Delete"
								icon={<CloseIcon />}
								variant="outline"
								colorScheme="red"
								size="lg"
								onClick={() => handleDelete(artwork.id)}
							/>
						</HStack>
					))}

					<HStack align="center">
						<FilePicker
							onDrop={(files) => setFileToUpload(files)}
							accept=".pdf"
							shouldShowSelectedFiles={!!fileToUpload}
							shouldShowSelectButton={!fileToUpload}
							files={fileToUpload}
							maxSize={30_000_000}
							buttonComponent={
								<Button
									as="label"
									htmlFor="file-upload"
									bg="yellow.400"
									color="black"
									_hover={{ bg: 'yellow.500' }}
									size="lg"
									width="100%"
									leftIcon={<DownloadIcon />}
								>
									Importer une note d'investissement
								</Button>
							}
						/>
						{fileToUpload && (
							<HStack>
								<Button
									as="label"
									bg="yellow.400"
									color="black"
									_hover={{ bg: 'yellow.500' }}
									size="lg"
									width="100%"
									isLoading={isUploadLoading}
									onClick={() => handleFileUpload(fileToUpload)}
								>
									Uploader
								</Button>
								<CloseIcon cursor="pointer" boxSize="12px" onClick={() => resetFileInput()} />
							</HStack>
						)}
					</HStack>
				</VStack>
			</VStack>
		</VStack>
	);
};

export default CrowdfundingList;
