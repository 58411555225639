import { FC, useCallback, useMemo } from 'react';
import { Divider, Heading, VStack } from '@chakra-ui/react';

import useDelay from 'hooks/useDelay';
import OffersFilters from 'pages/ops/offers/OfferFilters';
import OfferTable from 'pages/ops/offers/OfferTable';
import { useLazyGetOffersEligibleSubscriptionsQuery } from 'services/ops/offers';
import { useAppSelector } from 'store';

const OffersTool: FC = () => {
	const { filters, pagination } = useAppSelector((state) => state.offersTool);

	const [getOffersEligibleSubscriptions, { data: data, isFetching: isFetching }] =
		useLazyGetOffersEligibleSubscriptionsQuery();

	useDelay(
		useCallback(
			() => getOffersEligibleSubscriptions({ filters, pagination }, true),
			[getOffersEligibleSubscriptions, filters, pagination],
		),
	);

	const offerPaymentList = useMemo(() => data?.results, [data]);

	return (
		<VStack align="start" w="100%" px="24px" spacing="24px" pb="40px" pt="24px">
			<Heading>Offres</Heading>
			<Divider />
			<VStack mt="24px" w="100%" px="32px" spacing="40px" marginBottom="60px">
				<OffersFilters />
				<OfferTable offerPaymentList={offerPaymentList || []} total={data?.total || 0} isFetching={isFetching} />
			</VStack>
		</VStack>
	);
};

export default OffersTool;
