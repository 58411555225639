import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
	Button,
	Collapse,
	Divider,
	Heading,
	HStack,
	Icon,
	Skeleton,
	Table,
	TableCellProps,
	TableColumnHeaderProps,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tooltip,
	Tr,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import Cardlayout from 'components/CardLayout';
import SubCategorySection from 'features/UserDataTable/SubCategorySection';
import {
	OnfidoWorkflowTranslate,
	useGetCryptoProfileByEmailQuery,
	useUnlockPartnerAdequationMutation,
	useValidateCryptoSubscriptionMutation,
} from 'services/ops/crypto';
import { OnfidoStatusTranslate, PartnerAdequationState } from 'types/crypto.types';
import { SubscriptionStatus } from 'types/global.type';

import CryptoPartnerFlowResetModal from './CryptoPartnerFlowResetModal';

type CryptoInsepctorProps = {
	userEmail: string;
};

const TableValue = ({ width, children, ...props }: TableCellProps) => (
	<Td overflowX="auto" borderColor="gray.300" minW={width} maxW={width} {...props}>
		{children}
	</Td>
);

const TableHeader = ({ children, ...props }: TableColumnHeaderProps) => (
	<Th borderColor="gray.300" {...props}>
		{children}
	</Th>
);

const CryptoInspector: FC<CryptoInsepctorProps> = ({ userEmail }) => {
	const navigate = useNavigate();
	const { data: userProfile, isFetching: isFetchingUserProfile } = useGetCryptoProfileByEmailQuery({
		email: userEmail,
	});

	const [triggerUnlockAdequation, { isLoading: isLoadingUnlockAdequation }] = useUnlockPartnerAdequationMutation();
	const [triggerValidateAdequation, { isLoading: isLoadingValidateAdequation }] =
		useValidateCryptoSubscriptionMutation();

	//
	// PARTNER VALIDATION LOGICS
	//

	// Handling visibility

	const canSeeOnfidoCallsBack = useMemo(
		() =>
			!!userProfile?.subscription &&
			!!userProfile?.partnerAdequation &&
			userProfile?.subscription.status === SubscriptionStatus.PENDING &&
			userProfile?.partnerAdequation?.status !== PartnerAdequationState.CREATED &&
			userProfile?.partnerAdequation?.status !== PartnerAdequationState.COMPLETED,
		[userProfile],
	);

	// Handling call back

	const handleSeeOnfido = useCallback(() => {
		if (!userProfile?.partnerAdequation?.partnerAdequationUrl) return;
		window.open(userProfile?.partnerAdequation?.partnerAdequationUrl);
	}, [userProfile]);

	const handleUnlock = useCallback(
		() => triggerUnlockAdequation({ email: userEmail }),
		[triggerUnlockAdequation, userEmail],
	);

	//
	// CRYPTO SUB LOGICS
	//

	// Translate

	const subscriptionStatusExplanation: string = useMemo(() => {
		if (!userProfile?.subscription) return 'Pas de souscription';

		// Subscription is not finished
		if (userProfile?.subscription.status === SubscriptionStatus.REQUESTED)
			return "Requested - L'utilisateur fait son parcours de souscription";
		if (userProfile?.subscription.status === SubscriptionStatus.PENDING && canSeeOnfidoCallsBack)
			return 'Pending - une action au requise au niveau du PARTNER VALIDATION';
		if (userProfile?.subscription.status === SubscriptionStatus.PENDING && !canSeeOnfidoCallsBack)
			return 'Pending - Le risque du client empêche la validation du parcours';
		if (userProfile?.subscription.status === SubscriptionStatus.PARTNER_VALIDATION)
			return 'Partner Validation - Les partenaires crée les wallets/comptes bancaires';

		// Subscription is finished
		if (userProfile?.subscription.status === SubscriptionStatus.PAYMENT)
			return "Payment - Le user n'a pas encore fait de versement";
		if (userProfile?.subscription.status === SubscriptionStatus.REGISTERED)
			return 'Registered - Le user à déjà effectué un versement réussi';
		if (userProfile?.subscription.status === SubscriptionStatus.COMPLETED) return 'Completed';

		return userProfile?.subscription.status;
	}, [canSeeOnfidoCallsBack, userProfile?.subscription]);

	// Handling visibility

	const { isOpen: isOpenCryptoSub, onToggle: onToogleCryptoSub } = useDisclosure({ defaultIsOpen: false });
	const canSeeSubscriptionCallsBack = useMemo(
		() =>
			!!userProfile?.subscription &&
			!!userProfile?.partnerAdequation &&
			(userProfile?.subscription.status === SubscriptionStatus.PENDING ||
				userProfile?.subscription.status === SubscriptionStatus.PARTNER_VALIDATION) &&
			userProfile?.partnerAdequation?.status === PartnerAdequationState.COMPLETED,
		[userProfile],
	);

	// Handling call back

	const handleValidate = useCallback(
		() => triggerValidateAdequation({ email: userEmail }),
		[triggerValidateAdequation, userEmail],
	);

	const {
		isOpen: isPartnerFlowResetModalOpen,
		onOpen: onOpenPartnerFlowResetModal,
		onClose: onClosePartnerFlowResetModal,
	} = useDisclosure();

	return (
		<>
			<CryptoPartnerFlowResetModal
				isOpen={isPartnerFlowResetModalOpen}
				onClose={onClosePartnerFlowResetModal}
				userEmail={userEmail}
			/>
			<Cardlayout title="Crypto Inspector">
				{isFetchingUserProfile || !userProfile ? (
					<Skeleton height="120px" />
				) : (
					<VStack w="100%" align="start" spacing="24px">
						<Divider />

						{/*PARTNER VALIDATION*/}
						<VStack w="100%" spacing="12px" align="start">
							<Heading size="md">Partner Validation</Heading>
							{!!userProfile?.partnerAdequation ? (
								<VStack w="100%" align="start" spacing="12px">
									<SubCategorySection
										values={[
											{ key: 'Provider', value: userProfile?.partnerAdequation.provider },
											{
												key: 'Type de flow',
												value: !!userProfile?.partnerAdequation?.adequationId
													? OnfidoWorkflowTranslate[userProfile.partnerAdequation.adequationId]
													: 'Unknown',
											},
											{ key: 'Adequation run ID', value: userProfile?.partnerAdequation.adequationRunId },
											{
												key: 'Adequation Status',
												value: userProfile?.partnerAdequation?.status
													? OnfidoStatusTranslate[userProfile?.partnerAdequation?.status]
													: 'Unknown',
											},
										]}
									/>

									{canSeeOnfidoCallsBack && (
										<HStack>
											<Button onClick={handleSeeOnfido} colorScheme="blue" isLoading={isLoadingUnlockAdequation}>
												Voir le parcours sur Onfido
											</Button>
											<Button onClick={handleUnlock} colorScheme="orange" isLoading={isLoadingUnlockAdequation}>
												Donner accès au parcours
											</Button>
											<Button onClick={onOpenPartnerFlowResetModal} colorScheme="red">
												Reset
											</Button>
										</HStack>
									)}
								</VStack>
							) : (
								<Text>Pas de Partner Validation en cours</Text>
							)}
						</VStack>

						<Divider />

						{/*CRYPTO SUBSCRIPTION*/}
						<VStack w="100%" spacing="12px" align="start">
							<Heading size="md">Crypto Subscription</Heading>
							{userProfile?.subscription ? (
								<VStack w="100%" align="start" spacing="12px">
									<SubCategorySection
										values={[
											{ key: 'Status', value: subscriptionStatusExplanation },
											{ key: 'Risk Explanation', value: userProfile?.subscription?.riskExplanation.join(', ') },
											{ key: 'Risk Rating', value: userProfile?.subscription?.riskRating },
											{
												key: 'Vérification identité terminé',
												value: userProfile?.subscription?.isAdequationPartnerCompleted ? 'true' : 'false',
											},
											{
												key: 'Le virtual Iban est crée',
												value: userProfile?.subscription?.isVirtualIbanPartnerCompleted ? 'true' : 'false',
											},
											{
												key: 'Le wallet crypto est crée',
												value: userProfile?.subscription?.isCryptoPartnerCompleted ? 'true' : 'false',
											},
										]}
									/>

									<VStack w="100%" align="start" spacing="12px">
										<HStack w="100%" justify="space-between" onClick={onToogleCryptoSub} cursor="pointer">
											<Heading size="md">Details</Heading>
											<Icon as={isOpenCryptoSub ? ChevronDownIcon : ChevronUpIcon} boxSize="24px" />
										</HStack>
										<Collapse in={isOpenCryptoSub} animateOpacity style={{ width: '100%', overflow: 'visible' }}>
											<SubCategorySection
												values={[
													{ key: 'Ip', value: userProfile?.subscription?.addressIp },
													{ key: 'Ip Approved', value: userProfile?.subscription?.isIpApproved ? 'true' : 'false' },
													{ key: 'Crypto Knowledge', value: userProfile?.subscription?.cryptoKnowledge },
													{ key: 'Time Horizon', value: userProfile?.subscription?.timeHorizon },
													{ key: 'Past Investment Amount', value: userProfile?.subscription?.pastInvestAmount },
													{
														key: 'Terms Approved',
														value: userProfile?.subscription?.isTermsApproved ? 'true' : 'false',
													},
													{ key: 'CGU Version', value: userProfile?.subscription?.CGUVersion },
													{
														key: 'Accept Date',
														value: new Date(userProfile?.subscription?.acceptDate || '0').toLocaleDateString(),
													},

													{ key: 'Hubspot ID', value: userProfile?.subscription?.hubspotId },
												]}
											/>
										</Collapse>
									</VStack>

									{canSeeSubscriptionCallsBack && (
										<HStack>
											<Tooltip
												label={
													userProfile.subscription.hasBlockingInstance
														? 'Le wallet ne peut pas être créé car il existe une instance bloquante en cours liée à ce deal'
														: undefined
												}
											>
												<Button
													isDisabled={userProfile.subscription.hasBlockingInstance}
													onClick={handleValidate}
													colorScheme="green"
													isLoading={isLoadingValidateAdequation}
												>
													Valider la création du wallet
												</Button>
											</Tooltip>

											<Button colorScheme="blue" onClick={() => navigate('/ops/super/blocking-instance')}>
												Créer une instance bloquante
											</Button>
										</HStack>
									)}
								</VStack>
							) : (
								<Text>Pas de Crypto Subscription en cours</Text>
							)}
						</VStack>

						<Divider />

						{/*CRYPTO Virtual Iban*/}
						<VStack w="100%" spacing="24px" align="start">
							<Heading size="md">Crypto Virtual Iban Account</Heading>
							{userProfile?.virtualIbanEndUser ? (
								<VStack w="100%" align="start" spacing="12px">
									<SubCategorySection
										values={[
											{ key: 'Provider', value: userProfile?.virtualIbanEndUser?.provider },
											{ key: 'Account Id', value: userProfile?.virtualIbanEndUser?.externalProviderAccountId },
										]}
									/>
								</VStack>
							) : (
								<Text>Pas de Crypto Virtual Iban ouvert</Text>
							)}

							{userProfile?.virtualIbanAccounts ? (
								userProfile?.virtualIbanAccounts.map((account) => (
									<VStack w="100%" align="start" spacing="12px" key={account.id}>
										<Heading size="md">Account scope : {account?.metadata?.partnerName}</Heading>
										<SubCategorySection
											values={[
												{ key: 'Account Id', value: account?.id },
												{ key: 'provider Deposit Account IBAN', value: account?.bankDetails?.iban },
												{ key: 'provider Deposit Account BIC', value: account?.bankDetails?.bic },
												{ key: 'user Withdraw Account IBAN', value: account?.userWithdrawAccount?.iban },
												{ key: 'user Withdraw Account BIC', value: account?.userWithdrawAccount?.bic },
											]}
										/>

										{account?.userPaymentsTransactions && account?.userPaymentsTransactions.length > 0 && (
											<VStack w="100%" align="start" spacing="12px" key={account.id}>
												<Heading size="md">transactions list</Heading>
												<TableContainer
													w="100%"
													bg="white"
													paddingTop="8px"
													borderRadius="8px"
													border="1px solid"
													borderColor="gray.300"
												>
													<Table w="100%" variant="simple" size="sm" __css={{ tableLayout: 'auto' }}>
														<Thead w="100%">
															<Tr w="100%">
																<TableHeader w="300px">ID</TableHeader>
																<TableHeader w="300px">Amount</TableHeader>
																<TableHeader w="300px">Date</TableHeader>
																<TableHeader w="300px">Type</TableHeader>
																<TableHeader w="300px">Status</TableHeader>
																<TableHeader w="300px">IBAN</TableHeader>
																<TableHeader w="300px">Location</TableHeader>
															</Tr>
														</Thead>
														<Tbody w="100%">
															{account?.userPaymentsTransactions.map((transaction) => (
																<Tr w="100%" key={transaction?.id} bg="gray.white">
																	<TableValue>
																		<Text>{transaction.id}</Text>
																	</TableValue>
																	<TableValue>
																		<Text>{transaction.amount}</Text>
																	</TableValue>
																	<TableValue>
																		<Text>{new Date(transaction.createdAt).toISOString()}</Text>
																	</TableValue>
																	<TableValue>
																		<Text>{transaction.type}</Text>
																	</TableValue>
																	<TableValue>
																		<Text>{transaction.status}</Text>
																	</TableValue>
																	<TableValue>
																		<Text>{transaction.iban}</Text>
																	</TableValue>
																	<TableValue>
																		<Text>{transaction.step}</Text>
																	</TableValue>
																</Tr>
															))}
														</Tbody>
													</Table>
												</TableContainer>
											</VStack>
										)}
									</VStack>
								))
							) : (
								<Text>Pas de Crypto Virtual Iban ouvert</Text>
							)}
						</VStack>

						<Divider />
					</VStack>
				)}
			</Cardlayout>
		</>
	);
};

export default CryptoInspector;
