import { FC, useCallback, useMemo, useState } from 'react';
import {
	Box,
	Button,
	Divider,
	FormControl,
	FormLabel,
	HStack,
	Input,
	Radio,
	RadioGroup,
	Select,
	Skeleton,
	Text,
	Textarea,
	VStack,
	Wrap,
} from '@chakra-ui/react';
import { MultiValue, Select as MultiSelect } from 'chakra-react-select';

import NumberInputWithStepper from 'components/NumberInput';
import useDelay from 'hooks/useDelay';
import { CrowdfundingFundCard } from 'pages/cpm/product-propale/cards/CrowdfundingFundCards';
import { CryptoVersementCard } from 'pages/cpm/product-propale/cards/CryptoVersementCard';
import ProductAccordion from 'pages/cpm/product-propale/cards/ProductAccordion';
import useAddProduct from 'pages/cpm/product-propale/useAddProduct';
import { AssetCpm } from 'services/cpm/product-propale';
import { OpsInvestDeal, useLazyGetDealsQuery } from 'services/deal';
import { CPM, cpmDisplayName } from 'types/cpm-list.type';
import { ProductType } from 'types/global.type';
import { SavingsAccountType } from 'types/savingsAccount.type';

import { ArtCard } from './cards/ArtCard';
import { AvLuxCard } from './cards/AvLuxCard';
import { CashCard } from './cards/CashCards';
import { GirardinCard } from './cards/GirardinCard';
import { InvestCard } from './cards/InvestCard';
import { PeFundCard } from './cards/PeFundCards';
import { ScpiFundCard } from './cards/ScpiFundCards';
import StructuredCard from './cards/StructuredCard';
import { VersementCard } from './cards/VersementCard';
import { avLuxSelectOptions, cashSelectOptions, getFundList, sortString } from './utils';

type ProductPropaleProps = {
	customCpm?: CPM;
	customEmail?: string;
	customNotionLink?: string;
};

export const ProductPropale: FC<ProductPropaleProps> = ({ customCpm, customEmail, customNotionLink }) => {
	const getUrlParams = new URLSearchParams(window.location.search);

	const [selectedCPM, setSelectedCPM] = useState(customCpm ?? (getUrlParams.get('assignedCpm') as CPM));
	const [email, setEmail] = useState(customEmail ?? getUrlParams.get('clientEmail') ?? '');
	const [message, setMessage] = useState('');
	const [notionLink, setNotionLink] = useState(customNotionLink ?? getUrlParams.get('notionLink') ?? '');
	const [expectedReturnRate, setExpectedReturnRate] = useState('1');
	const [isBlack, setIsBlack] = useState<`${boolean}`>('false');
	const [useExpectedRR, setUseExpectedRR] = useState<`${boolean}`>('false');
	const [assets, SetAssets] = useState<MultiValue<Record<string, string>>>(
		(getUrlParams.get('assets') ?? '')
			.split(',')
			.filter((a) => Object.values(AssetCpm).includes(a as AssetCpm))
			.map((a) => ({ value: a, label: a })),
	);
	const [product, setProduct] = useState<string>();
	const [pickedFund, setPickedFund] = useState<string>();

	const [getUserDeals, { data: dealData }] = useLazyGetDealsQuery();

	const {
		addFund,
		totalAmount,
		createPropale,
		isCreateLoading,
		scpiFunds,
		peFunds,
		artsFunds,
		crwdfgDeals,
		investFunds,
		structuredFunds,
		cryptoFunds,
		isFundsFetching,
		...productSelection
	} = useAddProduct();

	const fundList = useMemo(
		() => getFundList(product, scpiFunds, peFunds, artsFunds, crwdfgDeals, structuredFunds, cryptoFunds),
		[product, scpiFunds, peFunds, artsFunds, crwdfgDeals, structuredFunds, cryptoFunds],
	);

	useDelay(useCallback(() => getUserDeals({ searchBy: 'email', input: email }), [email, getUserDeals]));

	const fundDealsList = useMemo(() => {
		if (product !== 'VERSEMENT') return fundList;

		const normalizedFunds = fundList.map((f) => ({ name: f.name, id: f.id }));
		const normalizedDealList = ((dealData ?? []) as OpsInvestDeal[])
			.filter((d) => d.productType === ProductType.INVEST)
			.map((d) => ({ name: d.envelope.name + ' - ' + d.amount + '€', id: d.id }));

		return [...normalizedFunds, ...normalizedDealList];
	}, [fundList, dealData, product]);

	const handleAddFund = useCallback(() => {
		if (!product || !pickedFund) return;
		const dealInvest =
			product === 'VERSEMENT' ? (dealData as OpsInvestDeal[])?.find((d) => d.id === pickedFund) : undefined;
		addFund(product, pickedFund, dealInvest);
		setPickedFund('');
	}, [product, pickedFund, dealData, addFund]);

	const handleCreatePropale = useCallback(
		() =>
			createPropale({
				ownerEmail: customEmail ?? email,
				cpm: customCpm ?? selectedCPM!,
				notionURL: customNotionLink ?? notionLink ?? undefined,
				message: message || undefined,
				estimatedDistributionRate: useExpectedRR === 'true' ? +expectedReturnRate / 100 : undefined,
				isBlack: isBlack === 'true',
				assets: assets.map((a) => a.value) as AssetCpm[],
			}),
		// eslint-disable-next-line prettier/prettier
		[createPropale, customEmail, email, customCpm, selectedCPM, customNotionLink, notionLink, message, useExpectedRR, expectedReturnRate, isBlack, assets],
	);

	if (isFundsFetching) return <Skeleton mt="32px" w="1000px" h="300px" />;

	const missingFunds = [];
	if (!scpiFunds) missingFunds.push('SCPI');
	if (!peFunds) missingFunds.push('PE');
	if (!artsFunds) missingFunds.push('Art');
	if (!crwdfgDeals) missingFunds.push('Crowdfunding');
	if (!investFunds) missingFunds.push('Invest');
	if (!structuredFunds) missingFunds.push('Structured');
	if (!cryptoFunds) missingFunds.push('Crypto');

	return (
		<Box p="32px" mt="32px" borderRadius="10px" borderWidth="1px" borderColor="grey.200" bg="white" w="1000px">
			<VStack align="start" spacing="20px">
				{missingFunds.length > 0 && (
					<Text fontSize="sm" color="red">
						Aucun fonds trouvé pour: {missingFunds.join(', ')}
					</Text>
				)}

				<HStack justify="space-between" w="100%" spacing="32px">
					<FormControl isRequired>
						<VStack w="100%" align="start" spacing="0px">
							<FormLabel fontSize="sm">CPM</FormLabel>
							<Select
								placeholder="Choisissez votre CPM"
								value={selectedCPM}
								onChange={(e) => setSelectedCPM(e.target.value as CPM)}
							>
								{Object.values(CPM).map((cpm) => (
									<option key={cpm} value={cpm}>
										{cpmDisplayName[cpm]}
									</option>
								))}
							</Select>
						</VStack>
					</FormControl>

					<FormControl isRequired>
						<VStack w="100%" align="start" spacing="0px">
							<FormLabel fontSize="sm" ml="0px !important">
								Adresse email du client
							</FormLabel>
							<Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
						</VStack>
					</FormControl>
				</HStack>

				<HStack align="start" justify="space-between" w="100%" spacing="32px">
					<FormControl>
						<VStack w="100%" align="start" spacing="0px">
							<FormLabel fontSize="sm">Message lié à la proposition</FormLabel>
							<Textarea value={message} onChange={(e) => setMessage(e.target.value)} />
						</VStack>
					</FormControl>

					<FormControl>
						<VStack w="100%" align="start" spacing="0px">
							<FormLabel fontSize="sm">Lien Notion</FormLabel>
							<Input
								type="text"
								value={notionLink}
								onChange={(e) => setNotionLink(e.target.value)}
								placeholder="https://notion.so/"
							/>
						</VStack>
					</FormControl>
				</HStack>

				<HStack justify="space-between" w="100%" spacing="32px">
					<FormControl>
						<VStack w="100%" align="start" spacing="0px">
							<FormLabel fontSize="sm" w="125px">
								Client Ramify Black
							</FormLabel>
							<RadioGroup onChange={(e) => setIsBlack(e === 'true' ? 'true' : 'false')} value={isBlack}>
								<HStack>
									<Radio size="sm" value="false">
										Non
									</Radio>
									<Radio size="sm" value="true">
										Oui
									</Radio>
								</HStack>
							</RadioGroup>
						</VStack>
					</FormControl>

					<FormControl>
						<HStack w="100%" align="end" spacing="0px">
							<VStack w="100%" align="start" spacing="0px">
								<FormLabel fontSize="sm">Spécifier un rendement</FormLabel>
								<RadioGroup onChange={(e) => setUseExpectedRR(e === 'true' ? 'true' : 'false')} value={useExpectedRR}>
									<HStack>
										<Radio size="sm" value="false">
											Non
										</Radio>
										<Radio size="sm" value="true">
											Oui
										</Radio>
									</HStack>
								</RadioGroup>
							</VStack>

							{useExpectedRR === 'true' && (
								<HStack w="100%" ml="64px !important">
									<NumberInputWithStepper
										precision={1}
										min={0}
										max={100}
										value={expectedReturnRate + '%'}
										onChange={(s) => setExpectedReturnRate(s)}
									/>
								</HStack>
							)}
						</HStack>
					</FormControl>
				</HStack>

				<FormControl isRequired>
					<HStack w="100%">
						<FormLabel fontSize="sm" w="225px">
							Classe d'actifs souhaités
						</FormLabel>
						<MultiSelect
							chakraStyles={{ container: (provided) => ({ ...provided, w: '100%' }) }}
							isMulti
							variant="outline"
							options={[
								{ label: 'Private Equity', value: AssetCpm.PRIVATE_EQUITY },
								{ label: 'Immobilier', value: AssetCpm.IMMOBILIER },
								{ label: 'Obligations', value: AssetCpm.OBLIGATIONS },
								{ label: 'Actions', value: AssetCpm.ACTIONS },
								{ label: 'Fonds Euro', value: AssetCpm.FONDS_EURO },
								{ label: 'Art', value: AssetCpm.ART },
								{ label: 'Crypto', value: AssetCpm.CRYPTO },
							]}
							value={assets}
							placeholder="Selectionnez des actifs"
							onChange={(e) => SetAssets(e)}
						/>
					</HStack>
				</FormControl>

				<Divider />

				<FormControl isRequired>
					<HStack w="100%">
						<FormLabel fontSize="sm" w="125px">
							Produit
						</FormLabel>
						<RadioGroup
							value={product}
							onChange={(e) => {
								setPickedFund('');
								setProduct(e);
							}}
						>
							<Wrap>
								<Radio size="sm" value="SCPI">
									SCPI
								</Radio>
								<Radio size="sm" value="PE">
									PE
								</Radio>
								<Radio size="sm" value="INVEST">
									Invest
								</Radio>
								<Radio size="sm" value="VERSEMENT">
									Versement
								</Radio>
								<Radio size="sm" value="CER">
									CER
								</Radio>
								<Radio size="sm" value="CAT">
									CAT
								</Radio>
								<Radio size="sm" value="ART">
									Art
								</Radio>
								<Radio size="sm" value="CROWDFUNDING">
									Crowdfunding
								</Radio>
								<Radio size="sm" value="GIRARDIN">
									Girardin
								</Radio>
								<Radio size="sm" value="AV_LUX">
									AV Lux (Onelife)
								</Radio>
								<Radio size="sm" value="STRUCTURED">
									Structured
								</Radio>
								<Radio size="sm" value="VERSEMENT_CRYPTO">
									Versement Crypto
								</Radio>
							</Wrap>
						</RadioGroup>
					</HStack>
				</FormControl>

				<FormControl isRequired>
					<HStack w="100%">
						<FormLabel fontSize="sm" w="150px">
							Fonds
						</FormLabel>
						<MultiSelect
							chakraStyles={{ container: (provided) => ({ ...provided, w: '100%' }) }}
							variant="outline"
							options={fundDealsList
								.filter((f) => !!f.name)
								.sort((a, b) => sortString(a.name, b.name))
								.map((f) => ({ label: f.name, value: f.id, key: f.id }))}
							value={{
								label: fundDealsList.find((f) => f.id === pickedFund)?.name,
								value: pickedFund,
							}}
							isDisabled={!product}
							placeholder="Selectionnez un fond"
							onChange={(e) => setPickedFund(e?.value)}
						/>
						<Button w="250px" isDisabled={!pickedFund} onClick={handleAddFund}>
							Ajouter le fond
						</Button>
					</HStack>
				</FormControl>

				<Divider />

				{productSelection.selectedScpi.map((sub) => (
					<ProductAccordion
						key={sub.id}
						header={`SCPI - ${sub.fund.name}`}
						body={<ScpiFundCard data={sub} editData={productSelection.setSelectedScpiFunds} />}
						onDelete={() => productSelection.setSelectedScpiFunds((sf) => sf.filter((ff) => ff !== sub))}
					/>
				))}

				{productSelection.selectedPe.map((sub) => (
					<ProductAccordion
						key={sub.id}
						header={`PE - ${sub.fund.name}`}
						body={<PeFundCard data={sub} editData={productSelection.setSelectedPeFunds} />}
						onDelete={() => productSelection.setSelectedPeFunds((sf) => sf.filter((ff) => ff !== sub))}
					/>
				))}

				{productSelection.selectedInvest.map((sub) => (
					<ProductAccordion
						key={sub.id}
						header={`Invest - ${sub.provider} - ${sub.portfolioType} - ${sub.envelope}`}
						body={<InvestCard data={sub} editData={productSelection.setSelectedInvest} />}
						onDelete={() => productSelection.setSelectedInvest((sf) => sf.filter((ff) => ff !== sub))}
					/>
				))}

				{productSelection.selectedCash.map((sub) => (
					<ProductAccordion
						key={sub.id}
						header={`Cash - ${sub.type} - ${sub.legalEntity} - ${
							cashSelectOptions.find((f) => f.id === sub.provider)?.name
						}`}
						body={<CashCard data={sub} editData={productSelection.setSelectedCash} />}
						onDelete={() => productSelection.setSelectedCash((sf) => sf.filter((ff) => ff !== sub))}
					/>
				))}

				{productSelection.selectedAvLux.map((sub) => (
					<ProductAccordion
						key={sub.id}
						header={`AV Lux - ${avLuxSelectOptions.find((f) => f.id === sub.provider)?.name}`}
						body={<AvLuxCard data={sub} editData={productSelection.setSelectedAvLux} />}
						onDelete={() => productSelection.setSelectedAvLux((sa) => sa.filter((fa) => fa !== sub))}
					/>
				))}

				{productSelection.selectedArt.map((sub) => (
					<ProductAccordion
						key={sub.id}
						header={`Art - ${sub.fund.name}`}
						body={<ArtCard data={sub} editData={productSelection.setSelectedArt} />}
						onDelete={() => productSelection.setSelectedArt((sa) => sa.filter((fa) => fa !== sub))}
					/>
				))}

				{productSelection.selectedCrowdfunding.map((sub) => (
					<ProductAccordion
						key={sub.id}
						header={`Crowdfunding - ${sub.fund.name}`}
						body={<CrowdfundingFundCard data={sub} editData={productSelection.setSelectedCrowdfunding} />}
						onDelete={() => productSelection.setSelectedCrowdfunding((sa) => sa.filter((fa) => fa !== sub))}
					/>
				))}

				{productSelection.selectedGirardin.map((sub) => (
					<ProductAccordion
						key={sub.id}
						header={`Girardin - ${sub.provider}`}
						body={<GirardinCard data={sub} editData={productSelection.setSelectedGirardin} />}
						onDelete={() => productSelection.setSelectedGirardin((sa) => sa.filter((fa) => fa !== sub))}
					/>
				))}

				{productSelection.selectedStructured.map((sub) => (
					<ProductAccordion
						key={sub.id}
						header={`Structuré - ${sub.product.name}`}
						body={<StructuredCard data={sub} editData={productSelection.setSelectedStructured} />}
						onDelete={() => productSelection.setSelectedStructured((sa) => sa.filter((fa) => fa !== sub))}
					/>
				))}

				{productSelection.selectedVersement.map((sub) => (
					<ProductAccordion
						key={sub.id}
						header={`Versement - ${sub.envelope} - ${sub.contractCurrentAmount} €`}
						body={<VersementCard data={sub} editData={productSelection.setSelectedVersement} />}
						onDelete={() => productSelection.setSelectedVersement((sa) => sa.filter((fa) => fa !== sub))}
					/>
				))}

				{productSelection.selectedCryptoVersement.map((sub) => (
					<ProductAccordion
						key={sub.id}
						header={`Crypto Versement - ${sub.cryptoPocket.fullName} - ${sub.initialDepositAmount} €`}
						body={<CryptoVersementCard data={sub} editData={productSelection.setSelectedCryptoVersement} />}
						onDelete={() => productSelection.setSelectedCryptoVersement((sa) => sa.filter((fa) => fa !== sub))}
					/>
				))}
			</VStack>

			<HStack mt="32px" justify="space-between">
				<Text>
					Montant a investir: <b>{totalAmount}€</b>
				</Text>
				{productSelection.selectedCash.filter((c) => c.type === SavingsAccountType.CER).length > 1 && (
					<Text fontSize="sm" color="red">
						Il ne peut y avoir qu'un seul compte épargne rémunéré par client
					</Text>
				)}
				<Button colorScheme="blue" onClick={handleCreatePropale} isLoading={isCreateLoading}>
					Envoyer la proposition
				</Button>
			</HStack>
		</Box>
	);
};

export default ProductPropale;
