import { OpsKpiType } from 'pages/ops/kpi/utils';
import { dashboardApi } from 'store/api';
import { OpsProcess } from 'types/airtable/global.airtable.type';
import { BlockingInstanceLifecycle } from 'types/blocking-instance.type';
import { DocumentName } from 'utils/documentNamingMap';

export type OpsDealKpi = {
	id: string;
	product: string;
	email: string;

	submittedTime: string;
	treatedTime: string;
	completedTime: string;

	treatmentDuration: number;
	completionDuration: number;

	handled: boolean;
	isBlack: boolean;
	hasBlockingInstance: boolean;
	process: OpsProcess;
};

export type OpsBiKpi = {
	id: string;
	email: string;

	handled: boolean;
	isBlack: boolean;

	openAt: string;
	sentToClientAt: string;
	documentsSubmittedAt: string;
	sentToPartnerAt: string;
	closedAt: string;

	durationA: number;
	durationB: number;
	durationC: number;
	durationD: number;

	type: BlockingInstanceLifecycle;
};

export type OpsDocumentsInstancesKpi = {
	id: string;
	email: string;
	isBlack: boolean;
	type: BlockingInstanceLifecycle;
	missingDocumentsName: DocumentName[];
};

type OpsKpiParams = { startDate: string; endDate: string; type: OpsKpiType };

type OpsKpiResponse = {
	results: { product: string }[];
	dealsWithLabels: ({ label: string; percentage: number } & (
		| { deals: OpsDealKpi[]; type: 'Deal' }
		| { deals: OpsBiKpi[]; type: 'BlockingInstance' }
		| { deals: OpsBiKpi[]; type: 'DocumentsInstances' }
	))[];
};

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getOpsKpi: builder.query<OpsKpiResponse, OpsKpiParams>({
			query: (queryParams) => ({
				url: '/bo/ops/kpi',
				params: queryParams,
				method: 'GET',
				responseHandler: 'content-type',
				cache: 'no-cache',
			}),
		}),
	}),
});

export const { useGetOpsKpiQuery } = endpoints;
