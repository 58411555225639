import { dashboardApi } from 'store/api';
import { Adequation } from 'types/adequation.type';
import { Kyc } from 'types/kyc.type';
import { MoralPerson, MoralPersonBeneficiary } from 'types/moralPerson.type';
import { InvestorCategory, User } from 'types/user.type';

export type UserWithKycAndMoral = Omit<User, 'password'> & {
	kyc: Pick<Kyc, 'lastName' | 'firstName'>;
	moralPersons: Pick<MoralPerson, 'id' | 'denomination'>[];
};

export type MoralPersonWithBeneficiaries = MoralPerson & {
	moralPersonBeneficiaries: MoralPersonBeneficiary[];
};

export type KycAndAdequation = {
	kyc: Kyc;
	adequation: Adequation;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	historiqueFiscale: any[];
};

export enum BoLinkPromotionType {
	email = 'email',
	code = 'code',
}

export type UpdatePromotion = {
	emailClient: string;
	emailSponsorToLink: string;
	sponsorshipCode: string;
	promotionType: BoLinkPromotionType;
};

export type SponsorshipStatus = {
	sponsorCode: string;
} & (
	| {
			activated: true;
			codeUsed: string;
			isExpired: boolean;
			startedAt: Date;
			deadline: Date;
			sponsorshipType: 'PARTNER' | 'AFFILIATE' | 'PROMOTION';
			by?: string;
	  }
	| {
			activated: false;
	  }
);

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getClients: builder.query<UserWithKycAndMoral[], { searchBy: string; input: string | undefined }>({
			query: (queryParams) => ({
				url: '/bo/clients',
				params: queryParams,
				method: 'GET',
			}),
			providesTags: ['OPS_CLIENTS'],
		}),
		getClientById: builder.query<UserWithKycAndMoral, string>({
			query: (id) => ({
				url: `/bo/clients/id/${id}`,
				method: 'GET',
			}),
			providesTags: ['OPS_CLIENTS'],
		}),
		getClientByEmail: builder.query<UserWithKycAndMoral, string>({
			query: (id) => ({
				url: `/bo/clients/email/${id}`,
				method: 'GET',
			}),
			providesTags: ['OPS_CLIENTS'],
		}),
		getClientKYC: builder.query<KycAndAdequation, { email: string }>({
			query: ({ email }) => ({
				url: '/bo/clients/kyc',
				params: { email },
				method: 'GET',
			}),
		}),
		getClientMoralPersons: builder.query<MoralPersonWithBeneficiaries[], { email: string }>({
			query: ({ email }) => ({
				url: '/bo/clients/moral-persons',
				params: { email },
				method: 'GET',
			}),
		}),
		getAutologinToken: builder.query<{ token: string }, { email: string }>({
			query: ({ email }) => ({
				url: `/bo/clients/autologin-token`,
				params: { email },
			}),
		}),
		deleteClient: builder.mutation<void, { email: string }>({
			query: ({ email }) => ({
				url: '/bo/clients',
				params: { email },
				method: 'DELETE',
			}),
			invalidatesTags: ['OPS_CLIENTS', 'OPS_SUBSCRIPTIONS', 'OPS_DEALS'],
		}),
		updateEmail: builder.mutation<void, { oldEmail: string; newEmail: string }>({
			query: (body) => ({
				url: `/bo/clients/update-email`,
				method: 'POST',
				body: body,
			}),
			invalidatesTags: ['OPS_CLIENTS', 'OPS_SUBSCRIPTIONS'],
		}),
		getSponsor: builder.query<User, string>({
			query: (emailFilleul) => ({
				url: `/bo/clients/sponsor`,
				params: { emailFilleul },
				method: 'GET',
			}),
			providesTags: ['OPS_CLIENTS'],
		}),
		updateBlackStatus: builder.mutation<void, { email: string; isBlack: boolean }>({
			query: (body) => ({
				url: `/bo/clients/update-black-status`,
				body,
				method: 'PUT',
			}),
			invalidatesTags: ['OPS_CLIENTS'],
		}),
		updateInvestorCategory: builder.mutation<void, { email: string; investorCategory: InvestorCategory }>({
			query: (body) => ({
				url: `/bo/clients/update-investor-category`,
				body,
				method: 'PUT',
			}),
			invalidatesTags: ['OPS_CLIENTS'],
		}),
		updateFeatureFlags: builder.mutation<void, { email: string; featureFlags: string[] }>({
			query: (body) => ({
				url: `/bo/clients/update-feature-flags`,
				body,
				method: 'PUT',
			}),
			invalidatesTags: ['OPS_CLIENTS'],
		}),

		// Sponsorship
		getClientPromotion: builder.query<SponsorshipStatus, string>({
			query: (userId) => ({
				url: `/bo/clients/${userId}/promotion`,
				method: 'GET',
			}),
			providesTags: ['OPS_PROMOTIONS'],
		}),
		getClientBySponsorshipCode: builder.query<UserWithKycAndMoral, string>({
			query: (code) => ({
				url: `/bo/clients/sponsorship-code/${code}`,
				method: 'GET',
			}),
			providesTags: ['OPS_PROMOTIONS'],
		}),
		updatePromotion: builder.mutation<void, UpdatePromotion>({
			query: (body) => ({
				url: `/bo/clients/update-promotion`,
				body,
				method: 'PUT',
			}),
			invalidatesTags: ['OPS_PROMOTIONS'],
		}),
		updatePromotionDate: builder.mutation<void, { emailClient: string; promotionStartDate: Date }>({
			query: ({ promotionStartDate, ...rest }) => ({
				url: `/bo/clients/update-promotion/date`,
				body: { ...rest, promotionStartDate: promotionStartDate.toISOString() },
				method: 'PUT',
			}),
			invalidatesTags: ['OPS_PROMOTIONS'],
		}),
	}),
});

export const {
	useGetClientsQuery,
	useGetClientByIdQuery,
	useGetClientByEmailQuery,
	useLazyGetClientsQuery,
	useLazyGetClientByIdQuery,
	useLazyGetClientByEmailQuery,
	useGetClientKYCQuery,
	useGetClientMoralPersonsQuery,
	useLazyGetAutologinTokenQuery,
	useDeleteClientMutation,
	useUpdateEmailMutation,
	useGetSponsorQuery,
	useUpdateBlackStatusMutation,
	useUpdateInvestorCategoryMutation,
	useUpdateFeatureFlagsMutation,

	// Sponsorship
	useGetClientPromotionQuery,
	useLazyGetClientBySponsorshipCodeQuery,
	useUpdatePromotionMutation,
	useUpdatePromotionDateMutation,
} = endpoints;
