import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
	Button,
	HStack,
	Switch,
	Table,
	TableCellProps,
	TableColumnHeaderProps,
	TableContainer,
	Tag,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	theme,
	Tr,
	VStack,
	Wrap,
} from '@chakra-ui/react';

import SearchByFilter from 'components/filters/SearchBy';
import YesNoIcon from 'components/icons/YesNo';
import { OpsCryptoDeal } from 'services/deal';

const TableValue = ({ width, children, ...props }: TableCellProps) => (
	<Td overflowX="auto" borderColor="gray.300" minW={width} maxW={width} {...props}>
		{children}
	</Td>
);

const TableHeader = ({ children, ...props }: TableColumnHeaderProps) => (
	<Th borderColor="gray.300" {...props}>
		{children}
	</Th>
);

type CryptoSubTableProps = {
	subscriptionToInspect: (OpsCryptoDeal & { partnerAdequationUrl?: string })[];
};

const CryptoSubTable: FC<CryptoSubTableProps> = ({ subscriptionToInspect }) => {
	const [input, setInput] = useState('');
	const [includeDealsWithBI, setIncludeDealsWithBI] = useState(true);

	const subscriptionsFiltered = useMemo(
		() =>
			subscriptionToInspect
				.filter((sub) => sub.user.email.includes(input))
				.filter((sub) => (includeDealsWithBI ? true : !sub.hasBlockingInstance)),
		[subscriptionToInspect, input, includeDealsWithBI],
	);

	const navigate = useNavigate();

	return (
		<VStack w="100%" align="start" spacing="24px">
			<Wrap>
				<SearchByFilter size="sm" search={input} onChangeSearch={setInput} searchBy="email" />
				<Button
					size="sm"
					_hover={{ cursor: 'auto' }}
					rightIcon={
						<Switch
							size="sm"
							isChecked={includeDealsWithBI}
							onChange={(event) => setIncludeDealsWithBI(event.target.checked)}
						/>
					}
				>
					Instances
				</Button>
			</Wrap>
			<TableContainer bg="white" paddingTop="8px" borderRadius="8px" border="1px solid" borderColor="gray.300">
				<Table w="100%" variant="simple" size="sm" __css={{ tableLayout: 'fixed' }}>
					<Thead w="100%">
						<Tr w="100%">
							<TableHeader>User</TableHeader>
							<TableHeader>Risk Level</TableHeader>
							<TableHeader>Risk Details</TableHeader>
							<TableHeader>
								<HStack align="start">
									<Text>Lien Onfido</Text>
									<ExternalLinkIcon />
								</HStack>
							</TableHeader>
							<TableHeader>Instance</TableHeader>
						</Tr>
					</Thead>
					<Tbody w="100%">
						{subscriptionsFiltered.length > 0 ? (
							subscriptionsFiltered.map((sub) => (
								<Tr w="100%" key={sub?.id} bg="gray.white">
									<TableValue
										onClick={() => navigate(`/ops/super/subscription/${sub?.id}?productType=CRYPTO`)}
										_hover={{ bg: theme.colors.gray[100], textDecoration: 'underline' }}
										cursor="pointer"
									>
										<Text>{sub?.user?.email}</Text>
									</TableValue>

									<TableValue>
										<Text fontWeight="600">{sub.riskRating}</Text>
									</TableValue>

									<TableValue>
										<Wrap>
											{sub.riskExplanation.map((riskExplanation) => (
												<Tag key={riskExplanation} size="sm">
													<Text fontWeight="600">{riskExplanation}</Text>
												</Tag>
											))}
										</Wrap>
									</TableValue>

									<TableValue
										onClick={() => window.open(sub?.partnerAdequationUrl || '/', '_blank')}
										_hover={{ bg: theme.colors.gray[100], textDecoration: 'underline' }}
										cursor="pointer"
									>
										<Text>Voir le flow</Text>
									</TableValue>
									<TableValue>
										<YesNoIcon isTrue={sub.hasBlockingInstance} />
									</TableValue>
								</Tr>
							))
						) : (
							<Tr w="100%" bg="gray.white">
								<TableValue p="24px" textAlign="center" colSpan={5}>
									<Text color="gray.500">Aucune souscription trouvée avec ces filtres.</Text>
								</TableValue>
							</Tr>
						)}
					</Tbody>
				</Table>
			</TableContainer>
		</VStack>
	);
};

export default CryptoSubTable;
