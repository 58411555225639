import { AllDeal, statusChangeDict } from 'services/deal';
import { BlockingInstance } from 'types/blocking-instance.type';
import { AnyObject, ProductType } from 'types/global.type';

export const isNotNone = <T>(value: T | null | undefined): value is T => value !== undefined && value !== null;
export const isNone = <T>(value: T | null | undefined): value is null | undefined => !isNotNone(value);

export const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

export const removeObjectFields = (obj: AnyObject, fields: string[]) => {
	const newObj = { ...obj };
	try {
		for (const field of fields) delete newObj[field];
	} catch (error) {}
	return newObj;
};

export const reorderObjectFields = (inputObject: AnyObject, fieldOrder: string[]): AnyObject => {
	const reorderedObject: AnyObject = {};

	// Add specified fields in the specified order
	for (const field of fieldOrder) {
		if (inputObject.hasOwnProperty(field)) {
			reorderedObject[field] = inputObject[field];
		}
	}

	// Add remaining fields not in the specified order
	for (const field in inputObject) {
		if (!reorderedObject.hasOwnProperty(field)) {
			reorderedObject[field] = inputObject[field];
		}
	}

	return reorderedObject;
};

export const renameObjectFields = (
	inputObject: AnyObject | null | undefined,
	fieldNames: { [key: string]: string },
): AnyObject | null => {
	if (!inputObject) return null;
	const renamedObject: AnyObject = {};

	for (const field in inputObject) {
		if (fieldNames.hasOwnProperty(field)) {
			renamedObject[fieldNames[field]] = inputObject[field];
		} else {
			renamedObject[field] = inputObject[field];
		}
	}

	return renamedObject;
};

export const isEmptyObject = (obj: AnyObject | null | undefined): boolean => {
	for (const prop in obj) {
		if (Object.hasOwn(obj, prop)) {
			return false;
		}
	}

	return true;
};

export const formatNumber = (number: number, decimals = 0): string => {
	if (typeof number === 'number') {
		return number.toLocaleString('fr-FR', { minimumFractionDigits: 0, maximumFractionDigits: decimals });
	}
	return '';
};

// To avoid error  for out of range: https://stackoverflow.com/a/41045289 + https://github.com/andyearnshaw/Intl.js/issues/123 the minimumFractionDigits is set to 2 by default
export const displayMoney = (number: number | string | undefined, precision = 1): string => {
	if (typeof number === 'number') {
		return number.toLocaleString('fr-FR', {
			style: 'currency',
			currency: 'EUR',
			minimumFractionDigits: 0,
			maximumFractionDigits: precision,
		});
	}
	if (typeof number === 'string') return displayMoney(Number(number));
	return '';
};

// Reason for "minimumFractionDigits: 0": https://stackoverflow.com/a/41045289
export const displayMoneyNoDigits = (number: number): string => {
	if (typeof number === 'number') {
		return number.toLocaleString('fr-FR', {
			style: 'currency',
			currency: 'EUR',
			maximumFractionDigits: 0,
			minimumFractionDigits: 0,
		});
	}
	return '';
};

export const displayNumberRoundedThousands = (number: number): number => {
	if (number > 10000000000) {
		// 10B (will never happen)
		return Math.round(number / 1000000000) * 1000000000; // round to billion
	}
	if (number > 10000000) {
		// 10M
		return Math.round(number / 1000000) * 1000000; // round to million
	}
	if (number > 10000) {
		// 10K
		return Math.round(number / 1000) * 1000; // round to thousand
	}
	return number;
};

export const isIsoDate = (str: string | undefined) => {
	if (!str) return false;
	if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
	const d = new Date(str);
	return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str;
};

export const emailPattern =
	/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const getUpdatedStatusTime = (item: AllDeal | BlockingInstance) => {
	const status = item.status;
	const statusChange = statusChangeDict[status];

	if (statusChange in item) {
		const statusChangeTime = item[statusChange as keyof typeof item];
		if (isNone(statusChangeTime)) return null;
		return new Date(statusChangeTime as string);
	}
	return null;
};

const replacer = (_: string, value: unknown) => (value === null ? '' : value);

export const toCSV = (data: unknown[], opts = { removeSpaces: true }): string => {
	const { removeSpaces } = opts;
	// @ts-ignore
	const header = Object.keys(data[0]);
	const csv = data.map((row) =>
		header
			// @ts-ignore
			.map((fieldName) => JSON.stringify(row[fieldName], replacer)?.replaceAll(' ', removeSpaces ? '' : ' '))
			.join(';')
			.replaceAll('"', ''),
	);
	csv.unshift(header.join(';'));
	return csv.join('\r\n');
};

export const toBase64 = (file: File): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = reject;
	});

export const getProductTypeKey = (deal: AllDeal, statuses: string[]) => {
	// does fund have specific statuses ? (ex: Eurazeo Entrepreneurs Club 2)
	if ('airtableFundId' in deal && isNotNone(deal.airtableFundId) && statuses.includes(deal.airtableFundId))
		return deal.airtableFundId;

	let keySuffix = '';
	const isInAppSignFund =
		![ProductType.ART, ProductType.CROWDFUNDING].includes(deal.productType) &&
		'process' in deal &&
		deal.process === 'mail';

	if (isInAppSignFund) {
		// PE / SCPI funds have normal or AUTO statuses depending on if the fund is automated with in app sign
		keySuffix = '_AUTO';
	} else if (deal.productType === ProductType.CROWDFUNDING) {
		// CROWDFUNDING deals have specific statuses for each provider and person type (moral / physical)
		keySuffix = `_${deal.legalEntity}_${deal.provider.toUpperCase()}`;
	} else if (deal.productType === ProductType.INVEST) {
		if (deal.dealType === 'TRANSFER') return 'TRANSFER';
		// INVEST deals have specific statuses for each partner
		keySuffix = `_${deal.partner.toUpperCase()}`;
	} else if (deal.productType === ProductType.CASH) {
		// CAT/CER have specific statuses for each provider and person type (moral / physical)
		return `${deal.type}_${deal.legalEntity}_${deal.provider}`;
	}

	return `${deal.productType}${keySuffix}`;
};
