import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	Table,
	TableContainer,
	Tbody,
	Td,
	Textarea,
	Tr,
	VStack,
} from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import DatePicker from 'components/DatePicker';
import RequiredDocsUpload, { DocumentToUpload } from 'components/RequiredDocsUpload';
import useDealStatus from 'hooks/useDealStatus';
import useThemedToast from 'hooks/useThemedToast';
import { AllDeal, useUpdateDealMutation, useUploadShareAttestationMutation } from 'services/deal';
import { ProductType, SubscriptionStatus } from 'types/global.type';
import { isNone, isNotNone } from 'utils/functions';

import DealBoInfo from './DealBoInfo';

import './markdown.css';

const defaultFiles: DocumentToUpload = {
	sharesAttestation: { content: null, label: 'Attestation des parts' },
};

type FormValues = {
	nbShares: string;
	typePart: string;
	comment: string;
	effectiveDate: Date;
	files: DocumentToUpload;
};

const NextStatusModal = ({ isOpen, onToggle, deal }: { isOpen: boolean; onToggle: () => void; deal: AllDeal }) => {
	const toast = useThemedToast();
	const { nextStatus } = useDealStatus(deal);

	const [updateStatus, { isLoading }] = useUpdateDealMutation();
	const [uploadShareAttestation, { isLoading: isLoadingUploadAirtableDocs }] = useUploadShareAttestationMutation();

	const {
		control,
		handleSubmit,
		setValue,
		watch,
		reset,
		formState: { errors },
	} = useForm<FormValues>({
		defaultValues: {
			nbShares: '',
			typePart: '',
			comment: '',
			effectiveDate: new Date(),
			files: defaultFiles,
		},
		mode: 'onChange',
	});

	const formValues = watch();

	const needsDoc = useMemo(
		() =>
			deal.status === SubscriptionStatus.REGISTERED &&
			(deal.productType === ProductType.SCPI ||
				deal.productType === ProductType.PE ||
				deal.productType === ProductType.CROWDFUNDING ||
				deal.productType === ProductType.ART ||
				deal.productType === ProductType.GIRARDIN),
		[deal.productType, deal.status],
	);

	const registeredStatusInfoFilled = useMemo(() => {
		// attestationDesParts is required for all products that reach the REGISTERED status
		if (!Object.values(formValues.files).every((f) => isNotNone(f.content))) return false;
		if (deal.productType === ProductType.GIRARDIN) return true;
		if (deal.productType === ProductType.CROWDFUNDING) return true;
		if (deal.productType === ProductType.ART) return true;
		if (deal.productType === ProductType.PE) return isNotNone(formValues.typePart);
		if (deal.productType === ProductType.SCPI)
			return isNotNone(formValues.nbShares) && isNotNone(formValues.effectiveDate);
	}, [deal.productType, formValues]);

	const handleClose = useCallback(() => {
		onToggle();
		reset({
			nbShares: '',
			typePart: '',
			comment: '',
			effectiveDate: new Date(),
			files: defaultFiles,
		});
	}, [onToggle, reset]);

	const handleUpdateStatus = useCallback(
		async (data: FormValues) => {
			if (isNone(nextStatus)) return;

			try {
				// upload shares docs and data first if needed, dopending on the product type
				if (needsDoc) {
					const payload = new FormData();

					payload.append('dealId', deal.id);
					payload.append('productType', deal.productType);
					if (data.nbShares) payload.append('nbShare', data.nbShares);
					if (data.typePart) payload.append('partType', data.typePart);
					if (deal.productType === ProductType.SCPI && data.effectiveDate)
						payload.append('effectiveDate', data.effectiveDate.toISOString());
					Object.entries(data.files).forEach(([key, value]) => {
						if (isNotNone(value.content)) payload.append(key, value.content[0]);
					});

					await uploadShareAttestation(payload).unwrap();
				}

				await updateStatus({
					id: deal.id,
					productType: deal.productType,
					status: nextStatus,
					properties: {
						id: deal.opsPropertiesId ?? undefined,
						comment: data.comment.length > 0 ? data.comment : undefined,
					},
				}).unwrap();

				toast({ title: 'Statut mis à jour', status: 'success' });
				handleClose();
			} catch (error) {
				toast({ title: 'Erreur lors de la mise a jour du statut', status: 'error' });
			}
		},
		// eslint-disable-next-line prettier/prettier
		[deal.id, deal.opsPropertiesId, deal.productType, handleClose, needsDoc, nextStatus, toast, updateStatus, uploadShareAttestation],
	);

	useEffect(() => {
		if (isNone(deal) || (deal.productType !== ProductType.SCPI && deal.productType !== ProductType.PE)) return;
		if (deal.nbShare) setValue('nbShares', deal.nbShare.toString());
	}, [deal, setValue]);

	return (
		<AlertDialog
			size="3xl"
			isOpen={isOpen}
			onClose={handleClose}
			header="Changement de statut du deal"
			body={
				<VStack align="start" w="100%">
					<VStack align="start" w="100%">
						<TableContainer w="100%">
							<Table size="sm">
								<Tbody>
									{[
										{ id: 'Produit', value: deal.productType },
										{ id: 'Client', value: deal.user.email },
										{ id: 'Statut actuel', value: deal.status },
										{ id: 'Nouveau statut', value: nextStatus ?? 'N/A' },
									].map(({ id, value }) => (
										<Tr key={id}>
											<Td>{id}</Td>
											<Td>{value}</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						</TableContainer>

						<FormControl mt="16px" w="100%" isInvalid={!!errors.comment}>
							<Controller
								name="comment"
								control={control}
								render={({ field }) => <Textarea {...field} placeholder="Laisser un commentaire" />}
							/>
							{errors.comment && <FormErrorMessage>{errors.comment.message}</FormErrorMessage>}
						</FormControl>

						{needsDoc && (
							<VStack align="start" mt="16px" w="100%">
								{(deal.productType === ProductType.PE || deal.productType === ProductType.SCPI) && (
									<FormControl w="100%" isInvalid={!!errors.nbShares}>
										<FormLabel>Nombre de parts</FormLabel>
										<Controller
											name="nbShares"
											control={control}
											rules={{ required: 'Ce champ est requis' }}
											render={({ field }) => <Input type="number" {...field} />}
										/>
										{errors.nbShares && <FormErrorMessage>{errors.nbShares.message}</FormErrorMessage>}
									</FormControl>
								)}
								{deal.productType === ProductType.PE && (
									<FormControl w="100%" isInvalid={!!errors.typePart}>
										<FormLabel>Type de part</FormLabel>
										<Controller
											name="typePart"
											control={control}
											rules={{ required: 'Ce champ est requis' }}
											render={({ field }) => <Input {...field} />}
										/>
										{errors.typePart && <FormErrorMessage>{errors.typePart.message}</FormErrorMessage>}
									</FormControl>
								)}
								{deal.productType === ProductType.SCPI && (
									<FormControl w="100%" isInvalid={!!errors.effectiveDate}>
										<FormLabel>Date d'effet</FormLabel>
										<Controller
											name="effectiveDate"
											control={control}
											rules={{ required: 'Ce champ est requis' }}
											render={({ field }) => (
												<DatePicker
													selected={field.value}
													onChange={(date) => isNotNone(date) && field.onChange(date)}
												/>
											)}
										/>
										{errors.effectiveDate && <FormErrorMessage>{errors.effectiveDate.message}</FormErrorMessage>}
									</FormControl>
								)}
								<Controller
									name="files"
									control={control}
									render={({ field }) => (
										<RequiredDocsUpload
											files={field.value}
											addFiles={(key, value) => {
												setValue('files', {
													...field.value,
													[key]: { content: value, label: field.value[key].label },
												});
											}}
										/>
									)}
								/>
							</VStack>
						)}

						<DealBoInfo deal={deal} />
					</VStack>
				</VStack>
			}
			footer={
				<HStack>
					<Button isLoading={isLoading || isLoadingUploadAirtableDocs} onClick={handleClose}>
						Annuler
					</Button>
					<Button
						isDisabled={isNone(nextStatus) || (needsDoc && !registeredStatusInfoFilled)}
						isLoading={isLoading || isLoadingUploadAirtableDocs}
						onClick={handleSubmit(handleUpdateStatus)}
						colorScheme="blue"
					>
						Valider
					</Button>
				</HStack>
			}
		/>
	);
};

export default NextStatusModal;
