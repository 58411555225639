import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Box, Checkbox, CheckboxGroup, HStack, Input, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react';

import { ScpiDirectSimulationForm } from 'pages/cpm/scpi-direct/forms/formTypes';
import { SecteurPreference } from 'services/cpm/scpi-direct';

enum Regions {
	IDF = 'Île-de-France',
	EUROPE_HF = 'Europe (hors France)',
	FRANCE_PROVINCE = 'France (hors Île-de-France)',
}

enum InvestPreference {
	DYNAMISER_REVENUS = 'maximize capital',
	REVENU_COMPLEMENTAIRE = 'maximize additional revenue',
}

type PreferencesProps = {
	formMethods: UseFormReturn<ScpiDirectSimulationForm>;
};

const Preferences: FC<PreferencesProps> = ({ formMethods }) => (
	<HStack spacing="64px" w="100%" align="start">
		<Box p="32px" borderRadius="10px" borderWidth="1px" borderColor="grey.200" w="100%" maxW="1080px" bg="white">
			<VStack align="start" spacing="30px" w="100%">
				<VStack w="100%" align="start">
					<Text variant="Text-S-Regular">Souhaitez-vous uniquement avoir des fonds labellisés ISR ?</Text>
					<Controller
						name="isrLabel"
						control={formMethods.control}
						render={({ field }) => (
							<RadioGroup onChange={field.onChange} value={field.value ? 'Oui' : 'Non'}>
								<HStack spacing="24px">
									<Radio value="Oui">Oui</Radio>
									<Radio value="Non">Non</Radio>
								</HStack>
							</RadioGroup>
						)}
					/>
				</VStack>
				<VStack w="100%" align="start">
					<Text variant="Text-S-Regular">Souhaitez-vous des régions en particulier ? (optionnel)</Text>
					<Controller
						name="regions"
						control={formMethods.control}
						render={({ field: { onChange, value } }) => (
							<CheckboxGroup onChange={onChange} value={value}>
								<HStack spacing="24px" wrap="wrap">
									<Checkbox value={Regions.IDF}>Île-de-France</Checkbox>
									<Checkbox value={Regions.EUROPE_HF}>Europe (hors France)</Checkbox>
									<Checkbox value={Regions.FRANCE_PROVINCE}>France (hors Île-de-France)</Checkbox>
								</HStack>
							</CheckboxGroup>
						)}
					/>
				</VStack>
			</VStack>
		</Box>
		<Box p="32px" borderRadius="10px" borderWidth="1px" borderColor="grey.200" w="100%" maxW="1080px" bg="white">
			<VStack align="start" spacing="30px" w="100%">
				<VStack w="100%" align="start">
					<Text variant="Text-S-Regular">Avez-vous un mode d'investissement préférentiel ?</Text>
					<Controller
						name="investPreference"
						control={formMethods.control}
						render={({ field }) => (
							<RadioGroup onChange={field.onChange} value={field.value}>
								<HStack spacing="24px" wrap="wrap">
									<Radio value={InvestPreference.DYNAMISER_REVENUS}>Dynamiser mon revenu</Radio>
									<Radio value={InvestPreference.REVENU_COMPLEMENTAIRE}>Avoir des revenus complémentaires</Radio>
								</HStack>
							</RadioGroup>
						)}
					/>
				</VStack>
				{formMethods.watch('investPreference') === InvestPreference.REVENU_COMPLEMENTAIRE && (
					<VStack w="100%" align="start">
						<Text variant="Text-S-Regular">À partir de l'année</Text>
						<Controller
							name="beginningYear"
							control={formMethods.control}
							rules={{ required: true }}
							render={({ field }) => <Input {...field} type="number" placeholder="Année" bg="white" />}
						/>
					</VStack>
				)}
				<VStack w="100%" align="start">
					<Text variant="Text-S-Regular">Souhaitez-vous des secteurs en particulier ? (optionnel)</Text>
					<Controller
						name="sector"
						control={formMethods.control}
						render={({ field: { onChange, value } }) => (
							<CheckboxGroup onChange={onChange} value={value}>
								<HStack spacing="24px" wrap="wrap">
									<Checkbox value={SecteurPreference.BUREAUX}>Bureaux</Checkbox>
									<Checkbox value={SecteurPreference.COMMERCES}>Commerces</Checkbox>
									<Checkbox value={SecteurPreference.RESIDENTIEL}>Résidentiel</Checkbox>
									<Checkbox value={SecteurPreference.SANTE}>Santé</Checkbox>
								</HStack>
							</CheckboxGroup>
						)}
					/>
				</VStack>
			</VStack>
		</Box>
	</HStack>
);

export default Preferences;
