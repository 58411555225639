import { useCallback, useEffect, useMemo, useState } from 'react';
import { CopyIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Checkbox,
	Divider,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	Heading,
	HStack,
	Input,
	Radio,
	RadioGroup,
	Skeleton,
	Stack,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import NumberInputWithStepper from 'components/NumberInput';
import RebalancingContractsTable from 'components/tables/RebalancingContractsTable';
import useThemedToast from 'hooks/useThemedToast';
import {
	CustomArbitrageBody,
	CustomArbitrageDto,
	RebalancingType,
	useApplyContractRebalancingMutation,
	useCancelRebalancingMutation,
	useGetContractsToRebalanceQuery,
	usePreviewCustomRebalanceQuery,
	useRebalanceBatchMutation,
	useRecomputeRebalancingMutation,
	useSendSignRebalancingMutation,
} from 'services/rebalancing-contrat';
import { ContractRebalancing } from 'types/invest-deal.type';
import { PortfolioType } from 'types/investmentPreferences.type';
import { isNone } from 'utils/functions';

import { BatchBehaviorType, BatchModal } from './BatchModal';
import BarGraph from './GraphBar';
import LineGraph from './GraphLine';
import { defaultPortfolioTypeValues, GreenType } from './utils';

const RebalancingContrat = () => {
	const toast = useThemedToast();
	const { isOpen: isValidationOpen, onOpen: openValidation, onClose: closeValidation } = useDisclosure();

	const [rebalanceType, setRebalanceType] = useState<RebalancingType>(RebalancingType.GLOBAL_REBALANCE);
	const [minTurnover, setMinTurnover] = useState(2);
	const [maxTurnover, setMaxTurnover] = useState(15);
	const [minRisk, setMinRisk] = useState(1);
	const [maxRisk, setMaxRisk] = useState(10);
	const [selectedContract, setSelectedContract] = useState<ContractRebalancing>();
	const [arbitrageId, setArbitrageId] = useState<number>();
	const [greenFilter, setGreenFilter] = useState<GreenType>(GreenType.BOTH);

	// portfolioType
	const [portfolioFilter, setPortfolioFilter] = useState(defaultPortfolioTypeValues);
	const portfolioAllChecked = useMemo(() => Object.values(portfolioFilter).every(Boolean), [portfolioFilter]);
	const portfolioIsIndeterminate = useMemo(
		() => Object.values(portfolioFilter).some(Boolean) && !portfolioAllChecked,
		[portfolioAllChecked, portfolioFilter],
	);
	const portfolioTypeFormatted = useMemo(
		() =>
			Object.entries(portfolioFilter)
				.filter(([, value]) => value)
				.map(([key]) => key as PortfolioType),
		[portfolioFilter],
	);

	// holdings
	const [holdings, setHoldings] = useState<string>('');

	// batch
	const [batchBehavior, setBatchBehavior] = useState<BatchBehaviorType>(BatchBehaviorType.SKIP);
	const { isOpen: isBatchModalOpen, onOpen: openBatchModal, onClose: closeBatchModal } = useDisclosure();

	// custom
	const [maxCustomTurnover, setMaxCustomTurnover] = useState('15');
	const [assetsToBuy, setAssetsToBuy] = useState<string>('');
	const [assetsToSell, setAssetsToSell] = useState<string>('');
	const [assetsToSellTotally, setAssetsToSellTotally] = useState<string>('');
	const [minimalWeight, setMinimalWeight] = useState('0');

	const customRebalanceBody = useMemo<CustomArbitrageBody>(
		() => ({
			rebalance_turnover: +maxCustomTurnover / 100,
			assets_to_buy_imperatively: assetsToBuy.split(/[\s,]+/).filter(Boolean),
			assets_to_sell_potentially: assetsToSell.split(/[\s,]+/).filter(Boolean),
			assets_to_sell_totally: assetsToSellTotally.split(/[\s,]+/).filter(Boolean),
			minimal_weight_for_asset_to_buy_imperatively: +minimalWeight / 100,
		}),
		[assetsToBuy, assetsToSell, assetsToSellTotally, maxCustomTurnover, minimalWeight],
	);

	const customRebalanceDto = useMemo<CustomArbitrageDto>(() => {
		if (rebalanceType !== RebalancingType.CUSTOM_REBALANCE) return { rebalanceType };
		return {
			rebalanceType,
			...customRebalanceBody,
		};
	}, [customRebalanceBody, rebalanceType]);

	const [reCompute, { isLoading: isRecomputeLoading, reset }] = useRecomputeRebalancingMutation();
	const [applyRebalancing, { isLoading: isRebalancingLoading }] = useApplyContractRebalancingMutation();
	const [sendSignRebalancing] = useSendSignRebalancingMutation();
	const [triggerBatchRebalance] = useRebalanceBatchMutation();
	const [cancelRebalancing] = useCancelRebalancingMutation();
	const { data: customRebalancingPreview, isError: customRebalancingError } = usePreviewCustomRebalanceQuery(
		{
			contractId: selectedContract?.id as string,
			...customRebalanceBody,
		},
		{ skip: isNone(selectedContract) || rebalanceType !== RebalancingType.CUSTOM_REBALANCE },
	);

	const { data, isFetching } = useGetContractsToRebalanceQuery({
		min: +minTurnover / 100,
		max: +maxTurnover / 100,
		rebalanceType: rebalanceType,
		green: greenFilter === GreenType.BOTH ? undefined : greenFilter === GreenType.GREEN,
		portfolioTypes: portfolioTypeFormatted,
		maxRisk,
		minRisk,
		holdings: holdings.split(/[\s,]+/).filter(Boolean),
	});

	const handleRecompute = useCallback(
		(contract: ContractRebalancing, saveRecompute: boolean) => {
			reCompute({ contractId: contract.id, saveRecompute: saveRecompute })
				.unwrap()
				.then((r) => {
					setSelectedContract((c) => ({ ...c!, rebalancingData: r }));
					toast({ status: 'success', title: 'Succès', description: 'Recompute done' });
				})
				.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
		},
		[reCompute, toast],
	);

	const handleApplyRebalancing = useCallback(
		(contract: ContractRebalancing) => {
			applyRebalancing({ contractId: contract.id, ...customRebalanceDto })
				.unwrap()
				.then((res) => {
					if (res.requiredSignature) {
						setArbitrageId(res.arbitrageId);
						openValidation();
					} else {
						setSelectedContract(undefined);
						toast({ status: 'success', title: 'Succès', description: 'Rebalancing applied' });
					}
				})
				.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
		},
		[applyRebalancing, customRebalanceDto, openValidation, toast],
	);

	const handleValidation = useCallback(
		(sendSign: boolean) => {
			if (sendSign) {
				sendSignRebalancing({
					contractId: selectedContract!.id,
					arbitrageId: arbitrageId!,
					...customRebalanceDto,
				})
					.unwrap()
					.then(() => toast({ status: 'success', title: 'Succès', description: 'Rebalancing applied' }))
					.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
			} else {
				cancelRebalancing({ contractId: selectedContract!.id });
			}
			setArbitrageId(undefined);
			closeValidation();
		},
		[arbitrageId, cancelRebalancing, closeValidation, customRebalanceDto, selectedContract, sendSignRebalancing, toast],
	);

	const rebalancingData = useMemo(() => {
		if (!selectedContract) return null;

		if (rebalanceType === RebalancingType.CUSTOM_REBALANCE)
			return customRebalancingError ? undefined : customRebalancingPreview;

		return selectedContract.rebalancingData[rebalanceType];
	}, [customRebalancingError, customRebalancingPreview, rebalanceType, selectedContract]);

	// reset selected contract when custom input changes
	useEffect(() => {
		if (rebalanceType === RebalancingType.CUSTOM_REBALANCE) setSelectedContract(undefined);
	}, [customRebalanceBody, rebalanceType]);

	return (
		<VStack w="100%" p="32px" align="start" bg="white" spacing="32px">
			<Heading size="lg">Arbitrage Contrats Invest</Heading>
			<VStack w="100%" align="start" spacing="16px">
				<FormControl isRequired>
					<HStack w="100%">
						<FormLabel w="200px">Type d'arbitrage</FormLabel>
						<RadioGroup onChange={(e) => setRebalanceType(e as RebalancingType)} value={rebalanceType}>
							<HStack spacing="16px">
								<Radio value={RebalancingType.GLOBAL_REBALANCE}>GLOBAL</Radio>
								<Radio value={RebalancingType.SCPI_REBALANCE}>SCPI</Radio>
								<Radio value={RebalancingType.CUSTOM_REBALANCE}>CUSTOM</Radio>
							</HStack>
						</RadioGroup>
					</HStack>
				</FormControl>

				{rebalanceType !== RebalancingType.CUSTOM_REBALANCE && (
					<FormControl isRequired>
						<HStack w="100%">
							<FormLabel w="200px">Turnover</FormLabel>
							<HStack spacing="32px">
								<NumberInputWithStepper
									precision={1}
									min={0}
									max={100}
									value={minTurnover + '%'}
									onChange={(s) => setMinTurnover(+s)}
								/>

								<Text>à</Text>

								<NumberInputWithStepper
									precision={1}
									min={0}
									max={100}
									value={maxTurnover + '%'}
									onChange={(s) => setMaxTurnover(+s)}
								/>
							</HStack>
						</HStack>
					</FormControl>
				)}

				{rebalanceType === RebalancingType.CUSTOM_REBALANCE && (
					<>
						<FormControl isRequired>
							<HStack w="100%">
								<FormLabel w="200px">Turnover maximale</FormLabel>
								<NumberInputWithStepper
									precision={1}
									min={0}
									max={100}
									value={maxCustomTurnover + '%'}
									onChange={(s) => setMaxCustomTurnover(s)}
								/>
							</HStack>
						</FormControl>

						<FormControl isRequired>
							<HStack w="100%" align="start">
								<FormLabel w="200px">Assets à vendre potentiellement</FormLabel>
								<VStack spacing="8px" align="start">
									<Input value={assetsToSell} onChange={(e) => setAssetsToSell(e.target.value)} />
								</VStack>
							</HStack>
						</FormControl>

						<FormControl isRequired>
							<HStack w="100%" align="start">
								<FormLabel w="200px">Assets à vendre totalement</FormLabel>
								<VStack spacing="8px" align="start">
									<Input value={assetsToSellTotally} onChange={(e) => setAssetsToSellTotally(e.target.value)} />
								</VStack>
							</HStack>
						</FormControl>

						<FormControl isRequired>
							<HStack w="100%" align="start">
								<FormLabel w="200px">Assets à acheter impérativement</FormLabel>
								<VStack spacing="8px" align="start">
									<Input value={assetsToBuy} onChange={(e) => setAssetsToBuy(e.target.value)} />
								</VStack>
							</HStack>
						</FormControl>

						<FormControl isRequired>
							<HStack w="100%">
								<FormLabel w="200px">Poids minimal pour l'achat</FormLabel>
								<NumberInputWithStepper
									precision={1}
									min={0}
									max={100}
									value={minimalWeight + '%'}
									onChange={(s) => setMinimalWeight(s)}
								/>
							</HStack>
						</FormControl>
					</>
				)}

				<FormControl isRequired>
					<HStack w="100%">
						<FormLabel w="200px">Risk</FormLabel>
						<HStack spacing="32px">
							<NumberInputWithStepper
								precision={1}
								min={1}
								max={10}
								value={minRisk}
								onChange={(s, n) => setMinRisk(n)}
								step={0.1}
							/>

							<Text>à</Text>

							<NumberInputWithStepper
								precision={1}
								min={1}
								max={10}
								value={maxRisk}
								onChange={(s, n) => setMaxRisk(n)}
								step={0.1}
							/>
						</HStack>
					</HStack>
				</FormControl>

				<FormControl isRequired>
					<HStack w="100%" align="start">
						<FormLabel w="200px">ESG</FormLabel>
						<VStack spacing="8px" align="start">
							<Checkbox
								isIndeterminate={greenFilter !== GreenType.BOTH}
								isChecked={greenFilter === GreenType.BOTH}
								onChange={() => {
									setGreenFilter(GreenType.BOTH);
								}}
							/>
							<Stack pl="32px" spacing="4px">
								<Checkbox
									isChecked={greenFilter !== GreenType.NOTGREEN}
									isDisabled={greenFilter === GreenType.GREEN}
									onChange={() => {
										setGreenFilter(greenFilter !== GreenType.BOTH ? GreenType.BOTH : GreenType.NOTGREEN);
									}}
								>
									Oui
								</Checkbox>

								<Checkbox
									isChecked={greenFilter !== GreenType.GREEN}
									isDisabled={greenFilter === GreenType.NOTGREEN}
									onChange={() => {
										setGreenFilter(greenFilter !== GreenType.BOTH ? GreenType.BOTH : GreenType.GREEN);
									}}
								>
									Non
								</Checkbox>
							</Stack>
						</VStack>
					</HStack>
				</FormControl>

				<FormControl>
					<HStack w="100%" align="start">
						<FormLabel w="200px">Holdings</FormLabel>
						<VStack spacing="8px" align="start">
							<Input value={holdings} onChange={(e) => setHoldings(e.target.value)} />
						</VStack>
					</HStack>
				</FormControl>

				<FormControl isRequired isInvalid={portfolioTypeFormatted.length === 0}>
					<HStack w="100%" align="start">
						<FormLabel w="200px">Type de portefeuille</FormLabel>
						<VStack spacing="8px" align="start">
							<Checkbox
								isChecked={portfolioAllChecked}
								isIndeterminate={portfolioIsIndeterminate}
								onChange={() => {
									setPortfolioFilter(
										Object.entries(defaultPortfolioTypeValues).reduce(
											(acc, [key]) => ({ ...acc, [key]: !portfolioAllChecked }),
											portfolioFilter,
										),
									);
								}}
							/>

							<Stack pl="32px" spacing="4px">
								{Object.entries(portfolioFilter).map(([key, value]) => (
									<Checkbox
										key={key}
										isChecked={value}
										onChange={() => {
											setPortfolioFilter({ ...portfolioFilter, [key]: !value });
										}}
									>
										{key}
									</Checkbox>
								))}
							</Stack>
						</VStack>
					</HStack>
				</FormControl>
			</VStack>

			<FormControl>
				<HStack align="start">
					<FormLabel>
						<Heading size="sm">Arbitrage en batch</Heading>
					</FormLabel>

					<Stack pl="32px" spacing="4px">
						<Checkbox
							isChecked={batchBehavior === BatchBehaviorType.ASK_SIGN}
							onChange={() => {
								setBatchBehavior(BatchBehaviorType.ASK_SIGN);
							}}
						>
							Demander la signature
						</Checkbox>

						<Checkbox
							isChecked={batchBehavior === BatchBehaviorType.SKIP}
							onChange={() => {
								setBatchBehavior(BatchBehaviorType.SKIP);
							}}
						>
							Skip les contrats
						</Checkbox>
					</Stack>

					<Button isDisabled={data?.length === 0} onClick={openBatchModal}>
						Arbitrer en batch {data?.length} contrats
					</Button>
				</HStack>
			</FormControl>

			<BatchModal
				isOpen={isBatchModalOpen}
				onClose={closeBatchModal}
				behavior={batchBehavior}
				rebalanceType={rebalanceType}
				contractsNb={data?.length ?? 0}
				onTrigger={() => {
					if (!data) return;
					triggerBatchRebalance({
						contractIds: data.map((contract) => contract.id),
						sendSignatures: batchBehavior === BatchBehaviorType.ASK_SIGN,
						...customRebalanceDto,
					})
						.unwrap()
						.then(() => {
							toast({
								status: 'success',
								title: 'Les arbitrages ont été déclenchés',
								description: 'Un reporting vous sera envoyé (~1s/contrat)',
							});
						})
						.catch(() => {
							toast({
								status: 'error',
								title: "Une erreur s'est produite",
							});
						});
				}}
			/>

			<Divider />

			<VStack w="100%" align="start" spacing="16px">
				<Heading as="h4" size="md">
					Contracts à arbitrer
				</Heading>

				<Skeleton isLoaded={!isFetching} w="100%">
					<RebalancingContractsTable
						contracts={data ?? []}
						pageSize={10}
						selectedContract={selectedContract}
						onClick={(contract) => {
							setSelectedContract(contract);
							reset();
							document.getElementById('visualisation')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
						}}
					/>
				</Skeleton>
			</VStack>

			{selectedContract && rebalancingData && (
				<>
					<Divider />

					<VStack w="100%" align="start" spacing="16px">
						<HStack w="100%" justify="space-between">
							<Heading as="h4" size="md" id="visualisation">
								Visualisation - {selectedContract.user.email}
							</Heading>
							<Text>
								Last refresh date: <b>{new Date(rebalancingData.last_refresh_date).toLocaleDateString('fr-FR')}</b>
							</Text>
						</HStack>

						<Grid templateRows="repeat(4, 1fr)" templateColumns="repeat(10, 1fr)" columnGap="30px" w="100%">
							<GridItem colSpan={5} rowSpan={2}>
								<BarGraph
									data={rebalancingData.turnover_asset_category_view_dict}
									turnover={rebalancingData.turnover_asset_category_view_euro}
									aum={+selectedContract.amount!}
									category="Asset Category"
									title="Asset Category View"
								/>
							</GridItem>
							<GridItem colSpan={5} rowSpan={2}>
								<BarGraph
									data={rebalancingData.turnover_asset_class_view_dict}
									turnover={rebalancingData.turnover_asset_class_view_euro}
									aum={+selectedContract.amount!}
									category="Asset Class"
									title="Asset Class View"
								/>
							</GridItem>
							<GridItem colSpan={6} rowSpan={2}>
								<BarGraph
									data={rebalancingData.turnover_basic_view_dict}
									turnover={rebalancingData.turnover_basic_view_euro}
									aum={+selectedContract.amount!}
									category=""
									title="Basic View"
									height="340px"
								/>
							</GridItem>
							<GridItem colSpan={4} rowSpan={2}>
								<LineGraph
									data={{
										actual: rebalancingData.actual_volatility_and_return_tuple,
										ideal: rebalancingData.ideal_volatility_and_return_tuple,
										new: rebalancingData.new_volatility_and_return_tuple,
									}}
									isOptimal={rebalancingData.distance_is_optimized}
									reachableTemperature={rebalancingData.reachable_temperature}
									temperature={selectedContract.investmentPreferences.temperature}
									isEsg={selectedContract.investmentPreferences.esg}
								/>
							</GridItem>
						</Grid>

						<HStack w="100%" align="start">
							<Box borderWidth="2px" borderRadius="4px" p="16px" w="50%">
								<VStack align="start">
									<HStack w="100%" justify="space-between">
										<Heading as="h4" size="md">
											arbitrage_percentage_buy_dict
										</Heading>
										<Button
											onClick={() => {
												navigator.clipboard.writeText(
													JSON.stringify(rebalancingData.arbitrage_percentage_buy_dict, undefined, 4),
												);
												toast({
													title: 'Copié',
													status: 'success',
													duration: 2000,
												});
											}}
										>
											<CopyIcon />
										</Button>
									</HStack>
									<Text as="pre">{JSON.stringify(rebalancingData.arbitrage_percentage_buy_dict, undefined, 4)}</Text>
								</VStack>
							</Box>

							<Box borderWidth="2px" borderRadius="4px" p="16px" w="50%">
								<VStack align="start">
									<HStack w="100%" justify="space-between">
										<Heading as="h4" size="md">
											arbitrage_percentage_sell_dict
										</Heading>
										<Button
											onClick={() => {
												navigator.clipboard.writeText(
													JSON.stringify(rebalancingData.arbitrage_percentage_sell_dict, undefined, 4),
												);
												toast({
													title: 'Copié',
													status: 'success',
													duration: 2000,
												});
											}}
										>
											<CopyIcon />
										</Button>
									</HStack>
									<Text as="pre">{JSON.stringify(rebalancingData.arbitrage_percentage_sell_dict, undefined, 4)}</Text>
								</VStack>
							</Box>
						</HStack>
					</VStack>

					<HStack w="100%" justify="space-between">
						<HStack>
							<Button isLoading={isRecomputeLoading} onClick={() => handleRecompute(selectedContract, false)}>
								Visualize rebalancing
							</Button>
							<Button isLoading={isRecomputeLoading} onClick={() => handleRecompute(selectedContract, true)}>
								Recompute rebalancing
							</Button>
						</HStack>
						<Button
							colorScheme="red"
							isLoading={isRebalancingLoading}
							onClick={() => handleApplyRebalancing(selectedContract)}
						>
							Arbitrer le contrat
						</Button>
					</HStack>

					<AlertDialog
						isOpen={isValidationOpen}
						onClose={closeValidation}
						header="Envoyer la signature"
						body="Cette opération de réabitrage nécessite la signature du client, souhaitez-vous continuer et lui envoyer ?"
						footer={
							<>
								<Button onClick={() => handleValidation(false)}>Annuler</Button>
								<Button colorScheme="red" onClick={() => handleValidation(true)} ml={3}>
									Valider
								</Button>
							</>
						}
					/>
				</>
			)}
		</VStack>
	);
};

export default RebalancingContrat;
