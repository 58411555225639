import { dashboardApi } from 'store/api';
import { ArtSubscription } from 'types/art.type';
import { AvLuxContract } from 'types/avLux.type';
import { CPM } from 'types/cpm-list.type';
import { CrowdfundingSubscription } from 'types/crowdfunding.type';
import { CryptoInvestPlanPeriod } from 'types/crypto.types';
import { GirardinSubscription } from 'types/girardin.type';
import { EnvelopeType, InsuranceProvider, InvestProjectType } from 'types/invest-deal.type';
import { FinancialLostType, LostReactionType, PortfolioType } from 'types/investmentPreferences.type';
import { PeSubscription } from 'types/pe.type';
import { SavingsAccount } from 'types/savingsAccount.type';
import { ScpiSubscription } from 'types/scpi.type';
import { StructuredProduct } from 'types/structured.type';

export enum AssetCpm {
	ACTIONS = 'ACTIONS',
	IMMOBILIER = 'REAL_ESTATE',
	OBLIGATIONS = 'OBLIGATIONS',
	FONDS_EURO = 'EURO_FUND',
	PRIVATE_EQUITY = 'PRIVATE_EQUITY',
	ART = 'ART',
	CRYPTO = 'CRYPTO',
}

type ScpiISubscriptionCpm = {
	projectType: ScpiSubscription['projectType'];
	nbShare: ScpiSubscription['nbShare'];
	legalEntity: ScpiSubscription['legalEntity'];
	demembrementDuration?: ScpiSubscription['demembrementDuration'];
	airtableFundId: ScpiSubscription['airtableFundId'];
};

type PeSubscriptionCpm = {
	initialDepositAmount: number;
	legalEntity: PeSubscription['legalEntity'];
	airtableFundId: ScpiSubscription['airtableFundId'];
};

type CrowdfundingSubscriptionCpm = {
	initialDepositAmount: number;
	airtableFundId: CrowdfundingSubscription['airtableFundId'];
};

type ArtSubscriptionCpm = {
	initialDepositAmount: number; // ArtSubscription['initialDepositAmount'] is a string
	airtableFundId: ArtSubscription['airtableFundId'];
};

type GirardinSubscriptionCpm = {
	initialDepositAmount: number; // GirardinSubscription['initialDepositAmount'] is a string
	provider: GirardinSubscription['provider'];
};

type AvLuxSubscriptionCpm = {
	initialDepositAmount: number; // AvLuxContract['initialDepositAmount'] is a string
	risk: NonNullable<AvLuxContract['risk']>;
	provider: AvLuxContract['provider'];
};

type CashSubscriptionCpm = {
	initialDepositAmount: number; // SavingsAccount['initialDepositAmount'] is a string
	provider: SavingsAccount['provider'];
	type: SavingsAccount['type'];
	legalEntity: SavingsAccount['legalEntity'];
	investmentDuration?: SavingsAccount['investmentDuration'];
};

type InvestSubscriptionCpm = {
	initialDepositAmount: number; // InvestSubscription['initialDepositAmount'] is a string
	recurrentDepositAmount?: number; // InvestSubscription['recurrentDepositAmount'] is a string
	envelopeType: EnvelopeType;
	projectType: InvestProjectType;
	provider: InsuranceProvider;
	esg: boolean;
	timeHorizon: number;
	temperature?: number;
	birthYear?: number;
	workedYears?: number;
	hasEverUsedSavingPlan: boolean;
	financialLost: FinancialLostType;
	lostReaction: LostReactionType;
	portfolioType: PortfolioType;
	risk: number;
	customPortfolio?: Array<{ ISIN: string; repartition: number }>;
};

type StructuredSubscriptionCpm = {
	airtableFundId: StructuredProduct['airtableFundId'];
	fundName: StructuredProduct['fundName'];
	isin: StructuredProduct['isin'];
	isGuaranteed: StructuredProduct['isGuaranteed'];
	initialDepositAmount: number; // InvestSubscription['initialDepositAmount'] is a string
};

type InvestVersementCpm = {
	initialDepositAmount: number;
	versementRequestType: string;
	contractId: string;
};

type CreateCryptoVersementDTO = {
	cryptoPocketId: string;
	versementRequestType: string;
	initialDepositAmount: number;
	triggerAt?: Date; // Only for versementRequestType: PROGRAMME
	period?: CryptoInvestPlanPeriod; // Only for versementRequestType: PROGRAMME
};

type CreateProductPropaleCpm = {
	ownerEmail: string;
	cpm: CPM;
	message?: string;
	notionURL?: string;
	estimatedDistributionRate?: number;
	isBlack: boolean;
	assets: AssetCpm[];
	ScpiSubscriptions?: ScpiISubscriptionCpm[];
	PeSubscriptions?: PeSubscriptionCpm[];
	investSubscriptions?: InvestSubscriptionCpm[];
	investVersements?: InvestVersementCpm[];
	structuredSubscriptions?: StructuredSubscriptionCpm[];
	cashSubscriptions?: CashSubscriptionCpm[];
	artSubscriptions?: ArtSubscriptionCpm[];
	crowdfundingSubscriptions?: CrowdfundingSubscriptionCpm[];
	girardinSubscriptions?: GirardinSubscriptionCpm[];
	avLuxSubscriptions?: AvLuxSubscriptionCpm[];
	cryptoVersements?: CreateCryptoVersementDTO[];
};

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		createProposal: builder.mutation<Record<string, string[]>, CreateProductPropaleCpm>({
			query: (body) => ({
				url: '/bo/cpm/propale-product',
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const { useCreateProposalMutation } = endpoints;
