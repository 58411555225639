import { FC } from 'react';
import { Select, SelectProps } from '@chakra-ui/react';

const InvestmentHorizonSelect: FC<SelectProps> = ({ ...props }) => (
	<Select {...props}>
		<option value={5}>0 à 5 ans</option>
		<option value={10}>5 à 10 ans</option>
		<option value={15}>10 à 15 ans</option>
		<option value={20}>15 à 20 ans</option>
		<option value={30}>+ de 20 ans</option>
	</Select>
);

export default InvestmentHorizonSelect;
