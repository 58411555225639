import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
	Checkbox,
	HStack,
	Select,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Text,
	VStack,
} from '@chakra-ui/react';

import { ScpiDirectSimulationForm } from 'pages/cpm/scpi-direct/forms/formTypes';
import FamilySituationReferentiel, { FamilySituation } from 'types/referentiels/familySituation';

type PersonalInformationsProps = {
	formMethods: UseFormReturn<ScpiDirectSimulationForm>;
};

const PersonalInformations: FC<PersonalInformationsProps> = ({ formMethods }) => {
	const { control, watch } = formMethods;
	const childrenCount = watch('children');

	return (
		<VStack align="start" spacing="30px" w="100%">
			<HStack w="100%" justifyContent={{ base: 'center', md: 'space-between' }}>
				<Controller
					name="moralPerson"
					control={control}
					render={({ field: { onChange, value, ref } }) => (
						<Checkbox onChange={onChange} isChecked={value} ref={ref}>
							<Text variant="Text-S-Regular">Personne morale</Text>
						</Checkbox>
					)}
				/>
			</HStack>
			<VStack w="100%" align="start">
				<Text variant="Text-S-Regular">Situation familiale</Text>
				<Controller
					name="familySituation"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<Select {...field} placeholder="Sélectionner" bg="white">
							{Object.values(FamilySituation).map((situation) => (
								<option key={situation} value={situation}>
									{FamilySituationReferentiel[situation]}
								</option>
							))}
						</Select>
					)}
				/>
			</VStack>
			<VStack w="100%" align="start">
				<HStack w="100%" justifyContent={{ base: 'center', md: 'space-between' }}>
					<Text variant="Text-S-Regular">Nombre d'enfants</Text>
					<Text fontSize="sm" color="grey.500">
						{childrenCount} /10
					</Text>
				</HStack>
				<Controller
					name="children"
					control={control}
					render={({ field: { onChange, value } }) => (
						<Slider value={value} min={0} max={10} step={1} onChange={onChange}>
							<SliderTrack>
								<SliderFilledTrack />
							</SliderTrack>
							<SliderThumb />
						</Slider>
					)}
				/>
			</VStack>
		</VStack>
	);
};

export default PersonalInformations;
