import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from '@chakra-ui/react';

import DatePicker from 'components/DatePicker';
import { isNotNone } from 'utils/functions';

import KpiTable from './KpiTable';
import { OpsKpiType } from './utils';

const opsTypes = [
	OpsKpiType.PRODUCT,
	OpsKpiType.BLOCKING_INSTANCE,
	OpsKpiType.DOCUMENTS_INSTANCES,
	OpsKpiType.PROCESS,
	OpsKpiType.PARTNER,
];

const KpiResultsPage: FC = () => {
	const navigate = useNavigate();

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [type, setType] = useState<OpsKpiType>();

	const currentIndex = useMemo(() => opsTypes.findIndex((t) => t === type), [type]);

	return (
		<VStack w="100%" align="start" spacing={5} p="40px" bg="white">
			<HStack fontSize="24px">
				<ArrowBackIcon _hover={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
				<Text fontWeight="bold">KPI Ops</Text>
			</HStack>

			<HStack>
				<DatePicker width="150px" selected={startDate} onChange={(d) => isNotNone(d) && setStartDate(d)} />
				<DatePicker width="150px" selected={endDate} onChange={(d) => isNotNone(d) && setEndDate(d)} />
			</HStack>

			<Tabs isLazy w="100%" index={currentIndex} onChange={(i) => setType(opsTypes[i])}>
				<TabList mb="16px">
					<Tab value={OpsKpiType.PRODUCT}>Produits</Tab>
					<Tab value={OpsKpiType.BLOCKING_INSTANCE}>Instances</Tab>
					<Tab value={OpsKpiType.DOCUMENTS_INSTANCES}>Documents Instances</Tab>
					<Tab value={OpsKpiType.PROCESS}>Process</Tab>
					<Tab value={OpsKpiType.PARTNER}>Partners</Tab>
				</TabList>

				{type && (
					<TabPanels>
						{opsTypes.map((t) => (
							<TabPanel key={t}>
								<KpiTable startDate={startDate} endDate={endDate} type={t} />
							</TabPanel>
						))}
					</TabPanels>
				)}
			</Tabs>
		</VStack>
	);
};

export default KpiResultsPage;
