import { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Table, TableContainer, Tbody, Text, Thead, Tr } from '@chakra-ui/react';

import { ConditionalLink } from 'components/tables/columns/ConditionalLink';
import { BoolTd, DefaultTd } from 'components/tables/columns/Td';
import { DefaultTh } from 'components/tables/columns/Th';
import { ClientSectionPossiblePath } from 'pages/ops/super/client';
import { MAX_RECENT_CLIENTS, RecentClient } from 'pages/ops/super/client/Client.d';
import useClientListFilters from 'pages/ops/super/client/utils';
import { UserWithKycAndMoral } from 'services/client';

type ClientTableProps = {
	clients: UserWithKycAndMoral[];
	selectedClient?: UserWithKycAndMoral;
	onClick?: (c: UserWithKycAndMoral) => void;
	pageSize: number;
	basePath?: ClientSectionPossiblePath;
};

const addToRecentClients = (
	client: UserWithKycAndMoral,
	recentClients: RecentClient[],
	setRecentClients: (clients: RecentClient[]) => void,
) => {
	const filteredClients = recentClients.filter((c) => c.id !== client.id);

	const updatedClients = [
		{
			id: client.id,
			email: client.email,
			firstName: client.kyc?.firstName,
			lastName: client.kyc?.lastName,
		},
		...filteredClients,
	];

	const limitedClients = updatedClients.slice(0, MAX_RECENT_CLIENTS);

	setRecentClients(limitedClients);
};

const ClientsTable = ({ clients, onClick, selectedClient, pageSize, basePath = 'ops' }: ClientTableProps) => {
	const [pageIndex, setPageIndex] = useState<number>(0);
	const { recentClients, setRecentClients } = useClientListFilters();

	const handleClientClick = (client: UserWithKycAndMoral) => {
		addToRecentClients(client, recentClients, setRecentClients);
		if (onClick) onClick(client);
	};

	return (
		<Box>
			<TableContainer bg="white">
				<Table variant="simple" size="sm">
					<Thead>
						<Tr>
							<DefaultTh>Email</DefaultTh>
							<DefaultTh>Nom</DefaultTh>
							<DefaultTh>Prénom</DefaultTh>
							<DefaultTh>Categorie Investisseur</DefaultTh>
							<DefaultTh>Morale</DefaultTh>
							<DefaultTh>Société(s)</DefaultTh>
							<DefaultTh>Black</DefaultTh>
							<DefaultTh>Phoenix</DefaultTh>
							<DefaultTh>Corporate</DefaultTh>
						</Tr>
					</Thead>

					<Tbody>
						{clients.slice(pageIndex, pageIndex + pageSize).map((client) => (
							<ConditionalLink
								key={client.id}
								style={{ display: 'contents' }}
								to={`/${basePath}/super/client/${client.id}`}
								shouldrenderLink={!onClick}
							>
								<Tr
									cursor="pointer"
									bg={selectedClient?.id === client.id ? 'blue.50' : 'white'}
									onClick={() => handleClientClick(client)}
								>
									<DefaultTd>{client.email}</DefaultTd>
									<DefaultTd>{client.kyc?.lastName}</DefaultTd>
									<DefaultTd>{client.kyc?.firstName}</DefaultTd>
									<DefaultTd>{client.investorCategory}</DefaultTd>
									<BoolTd value={client.moralPersons?.length > 0} />
									<DefaultTd>
										{client.moralPersons?.map((m) => m.denomination || 'Sans dénomination').join(', ') || 'Aucune'}
									</DefaultTd>
									<BoolTd value={client.isBlack} />
									<BoolTd value={client.isPhoenix} />
									<BoolTd value={client.isCorporate} />
								</Tr>
							</ConditionalLink>
						))}
					</Tbody>
				</Table>
			</TableContainer>

			<Box w="100%" textAlign="right" my="16px" fontWeight="semibold" userSelect="none">
				<Text as="span" color="gray.600">
					Show {pageIndex + 1} to {pageIndex + pageSize} of {clients.length} entries{' '}
				</Text>
				<ChevronLeftIcon
					mx="16px"
					boxSize="24px"
					bg="gray.100"
					cursor="pointer"
					onClick={() =>
						setPageIndex(pageIndex === 0 ? Math.floor(clients.length / pageSize) * 10 : pageIndex - pageSize)
					}
				/>
				<ChevronRightIcon
					boxSize="24px"
					bg="gray.100"
					cursor="pointer"
					onClick={() => setPageIndex(pageIndex + 1 * pageSize >= clients.length ? 0 : pageIndex + pageSize)}
				/>
			</Box>
		</Box>
	);
};

export default ClientsTable;
