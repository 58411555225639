import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	Box,
	Button,
	Card,
	CardBody,
	CardHeader,
	Flex,
	FormControl,
	FormLabel,
	HStack,
	Image,
	Input,
	Skeleton,
	Text,
	Textarea,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import useThemedToast from 'hooks/useThemedToast';
import {
	convertToStringifiedArtFundDTO,
	StringifiedCrowdfundingFundDTO,
	useCorrectCrowdfundingNoteMutation,
	useGetCrowdfundingNoteQuery,
} from 'services/ai/crowdfunding';
import { CrowdfundingFund } from 'types/crowdfunding.type';

const CrowdfundingDetails = () => {
	const { id } = useParams<{ id: string }>();

	const { data: crowdfund, isLoading } = useGetCrowdfundingNoteQuery(id!);
	const [submitAirtable] = useCorrectCrowdfundingNoteMutation();

	const [selectedItem, setSelectedItem] = useState<keyof CrowdfundingFund>('name');
	const [visibleItems, setVisibleItems] = useState<number>(1);
	const [comments, setComments] = useState<Partial<Record<keyof CrowdfundingFund, string>>>({});

	const toast = useThemedToast();

	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const lastItemRef = useRef<HTMLDivElement>(null);

	const [originalCrowdfund, setOriginalCrowdfund] = useState<CrowdfundingFund | null>(null);
	const [updatedCrowdfund, setUpdatedCrowdfund] = useState<StringifiedCrowdfundingFundDTO | null>(null);
	const [selectedVersements, setSelectedVersements] = useState<Set<number>>(new Set([1]));
	const [selectedInstruments, setSelectedInstruments] = useState<number>(0);
	const [selectedProvider, setSelectedProvider] = useState<number>(0);

	const toggleVersement = (index: number) => {
		setSelectedVersements((prev) => {
			const newSet = new Set(prev);
			if (newSet.has(index)) {
				newSet.delete(index);
			} else {
				newSet.add(index);
			}
			return newSet;
		});

		if (updatedCrowdfund) {
			updatedCrowdfund.versementDesInterets = Array.from(selectedVersements).map(
				(i) => crowdfund?.versementDesInterets[i] || '',
			);
		}
	};

	const parseArrayFromString = (str: string): string[] => {
		try {
			return JSON.parse(str.replace(/'/g, '"')) as string[];
		} catch (error) {
			console.error('Error parsing string to array:', error);
			return [];
		}
	};
	useEffect(() => {
		if (crowdfund) {
			const { sources: sources, ...crowdFund } = crowdfund;
			setOriginalCrowdfund(crowdFund as CrowdfundingFund);
			setUpdatedCrowdfund(convertToStringifiedArtFundDTO(crowdFund as CrowdfundingFund));
			setSelectedVersements(new Set([0]));
		}
	}, [crowdfund]);

	const handleSelectItem = (key: keyof CrowdfundingFund) => {
		setSelectedItem(key);
	};

	const handleCommentChange = (key: keyof CrowdfundingFund, value: string) => {
		setComments((prev) => ({ ...prev, [key]: value }));
	};

	const handleNextItem = () => {
		setVisibleItems((prev) => prev + 1);
	};

	const handleInputChange = (key: keyof CrowdfundingFund, value: string) => {
		setUpdatedCrowdfund((prev) => {
			if (prev) {
				return { ...prev, [key]: value };
			}
			return prev;
		});
	};

	const handleSubmit = async () => {
		try {
			if (updatedCrowdfund?.instrumentEmis) {
				updatedCrowdfund.instrumentEmis = parseArrayFromString(originalCrowdfund?.instrumentEmis ?? '')[
					selectedInstruments
				];
			}

			if (updatedCrowdfund?.provider) {
				updatedCrowdfund.provider = parseArrayFromString(originalCrowdfund?.provider ?? '')[selectedProvider];
			}

			if (updatedCrowdfund?.versementDesInterets) {
				updatedCrowdfund.versementDesInterets = Array.from(selectedVersements).map(
					(index) => originalCrowdfund?.versementDesInterets?.[index] || '',
				);
			}

			const data = await submitAirtable({
				id: id as string,
				rawCrowdfunding: originalCrowdfund as CrowdfundingFund,
				updatedCrowdfunding: updatedCrowdfund as StringifiedCrowdfundingFundDTO,
				opsComments: comments as Record<keyof CrowdfundingFund, string>,
			}).unwrap();

			if (data.alreadyCreated) {
				toast({
					status: 'error',
					title:
						"Ce deal a déjà été intégrée. Veuillez vous rendre sur le Airtable pour effectuer d'autres modifications.",
				});
			} else {
				toast({
					status: 'success',
					title: 'Félicitations! Le deal a pu être intégré avec succès.',
				});
			}
		} catch (err: any) {
			let message = err.data.message;
			if (err.status == 422) {
				message = "Un ou plusieurs champs n'ont pas respecté le type requis par Airtable. " + message;
			}
			toast({
				status: 'error',
				title: message,
			});
		}
	};

	useEffect(() => {
		if (lastItemRef.current) {
			lastItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, [visibleItems]);

	// Check if crowdfund is undefined or loading
	if (isLoading) return <Skeleton />;
	if (!(originalCrowdfund && updatedCrowdfund) && !isLoading) {
		return (
			<Flex
				position="absolute"
				top="50%"
				left="50%"
				transform="translate(-50%, -50%)"
				direction="column"
				justify="center"
				align="center"
				w="100%"
				h="100vh"
			>
				<Card w="lg" p={4} borderRadius="lg" boxShadow="lg" borderColor="gray.200" borderWidth={1}>
					<CardHeader>
						<Text fontSize="xl" fontWeight="bold" textAlign="center" color="red.500">
							Deal non existant
						</Text>
					</CardHeader>
					<CardBody>
						<Text fontSize="lg" textAlign="center" color="gray.600">
							Le deal en question n'a pas été trouvé. Vérifie l'id et/ou réessaye ultérieurement
						</Text>
					</CardBody>
				</Card>
			</Flex>
		);
	}

	return (
		<Wrap w="100%" h="calc(100vh - 64px)" position="relative">
			<WrapItem flex="1" p={6} minW="500px" position="sticky" top="64px" justifyContent="center" alignItems="center">
				{selectedItem && crowdfund?.sources?.[selectedItem] ? (
					<Image
						src={`data:image/png;base64,${crowdfund.sources[selectedItem]}`}
						alt="Crowdfund"
						border="2px solid #ddd"
						borderRadius="lg"
						maxH="calc(100vh - 128px)"
						objectFit="contain"
					/>
				) : (
					<Card>
						<Text>VALEUR PAR DEFAULT</Text>
					</Card>
				)}
			</WrapItem>

			{crowdfund && (
				<WrapItem
					flex="1"
					p={6}
					borderLeft="2px solid #eee"
					overflowY="auto"
					h="calc(100vh - 64px)"
					position="relative"
				>
					<Flex direction="column" justify="space-between" h="100%" w="100%">
						<Box p={4} border="1px solid #ccc" borderRadius="md" flex="1" overflowY="scroll" ref={scrollContainerRef}>
							<Flex direction="column">
								{Object.entries(updatedCrowdfund as StringifiedCrowdfundingFundDTO)
									.filter(([key]) => key !== 'chartsArtWork')
									.slice(0, visibleItems)
									.map(([key], index) => (
										<Box
											key={index}
											display="block"
											m={2}
											p={4}
											minH="120px"
											minW="100%"
											border="2px solid"
											borderColor={selectedItem === key ? 'green.500' : 'gray.300'}
											borderRadius="md"
											cursor="pointer"
											_hover={{ borderColor: 'green.500' }}
											onClick={() => handleSelectItem(key as keyof CrowdfundingFund)}
											ref={index === visibleItems - 1 ? lastItemRef : undefined}
										>
											<FormControl mb={6}>
												<FormLabel fontWeight="bold" fontSize="xl">
													{key}
												</FormLabel>

												<Box mb={3} />
												{key === 'versementDesInterets' ? (
													<>
														{Array.isArray(originalCrowdfund?.versementDesInterets)
															? (originalCrowdfund.versementDesInterets as string[]).map((value, btnIndex) => (
																	<Button
																		mr={3}
																		key={btnIndex}
																		bg={selectedVersements.has(btnIndex) ? 'green.500' : undefined}
																		onClick={() => toggleVersement(btnIndex)}
																	>
																		{value}
																	</Button>
															  ))
															: null}
													</>
												) : key === 'instrumentEmis' ? (
													<>
														{originalCrowdfund?.instrumentEmis
															? parseArrayFromString(originalCrowdfund.instrumentEmis).map(
																	(value: string, btnIndex: number) => (
																		<Button
																			key={btnIndex}
																			mr={3}
																			bg={btnIndex === selectedInstruments ? 'green.500' : undefined}
																			onClick={() => setSelectedInstruments(btnIndex)}
																		>
																			{value}
																		</Button>
																	),
															  )
															: null}
													</>
												) : key === 'provider' ? (
													<>
														{originalCrowdfund?.provider
															? parseArrayFromString(originalCrowdfund.provider).map(
																	(value: string, btnIndex: number) => (
																		<Button
																			key={btnIndex}
																			mr={3}
																			bg={btnIndex === selectedProvider ? 'green.500' : undefined}
																			onClick={() => setSelectedProvider(btnIndex)}
																		>
																			{value}
																		</Button>
																	),
															  )
															: null}
													</>
												) : (
													<Input
														value={updatedCrowdfund ? updatedCrowdfund[key as keyof CrowdfundingFund]?.toString() : ''}
														onChange={(e) => handleInputChange(key as keyof CrowdfundingFund, e.target.value)}
														fontSize="lg"
													/>
												)}
											</FormControl>

											<FormControl mb={6}>
												<FormLabel fontWeight="bold" fontSize="xl">
													Commentaires
												</FormLabel>
												<Textarea
													placeholder="Ajouter un commentaire"
													value={comments[key as keyof CrowdfundingFund] || ''}
													onChange={(e) => handleCommentChange(key as keyof CrowdfundingFund, e.target.value)}
												/>
											</FormControl>
										</Box>
									))}
							</Flex>
						</Box>

						<HStack spacing={4} mt={6} justify="flex-end">
							<Button
								colorScheme="blackAlpha"
								color="white"
								bg="black"
								size="md"
								onClick={handleNextItem}
								isDisabled={visibleItems >= Object.keys(updatedCrowdfund as StringifiedCrowdfundingFundDTO).length}
							>
								Suivant
							</Button>

							<Button colorScheme="blackAlpha" color="white" bg="black" size="md" onClick={handleSubmit}>
								Soumettre
							</Button>
						</HStack>
					</Flex>
				</WrapItem>
			)}
		</Wrap>
	);
};

export default CrowdfundingDetails;
