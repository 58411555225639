import { dashboardApi } from 'store/api';
import { InsuranceProvider } from 'types/invest-deal.type';
import { PortfolioType } from 'types/investmentPreferences.type';

export enum VehicleType {
	PER = 'per',
	AV = 'assurance_vie',
	AV_LUX = 'assurance_vie_lux',
	AV_LUX_HYBRID = 'assurance_vie_lux_hybride',
}

export const vehicleTypeLabel = {
	[VehicleType.PER]: 'PER',
	[VehicleType.AV]: 'Assurance Vie',
	[VehicleType.AV_LUX]: 'Assurance Vie Lux',
	[VehicleType.AV_LUX_HYBRID]: 'Assurance Vie Lux Hybride',
};

export type DirectInvestmentProduct = 'scpi' | 'pe' | 'pd' | 'fs' | 'ramify_x_fund';

export const directInvestmentProducts: { label: string; key: DirectInvestmentProduct }[] = [
	{ label: 'Ramify X Fund', key: 'ramify_x_fund' },
	{ label: 'SCPI', key: 'scpi' },
	{ label: 'Private Equity', key: 'pe' },
	{ label: 'Private Debt', key: 'pd' },
	{ label: 'Fonds structurés', key: 'fs' },
];

export enum BackTestFrequency {
	MONTHLY = 'M',
	QUARTERLY = 'Q',
	YEARLY = 'Y',
}

export const backtestFrequencyLabel = {
	[BackTestFrequency.MONTHLY]: 'Mensuelle',
	[BackTestFrequency.QUARTERLY]: 'Trimestrielle',
	[BackTestFrequency.YEARLY]: 'Annuelle',
};

export const insuranceProviderLabel = {
	[InsuranceProvider.APICIL]: 'Apicil',
	[InsuranceProvider.GENERALI]: 'Generali',
	[InsuranceProvider.ONELIFE]: 'OneLife',
	[InsuranceProvider.LMEP]: 'LMEP',
};

export type CrossAssetBacktestBody = {
	// InvestmentProject
	investmentHorizon: number;
	initialDepositAmount: number;
	amountVP: number;
	fees: number;
	// InvestmentPreferences
	esg?: number;
	risk?: number;
	// VehicleChoice
	insuranceProvider?: InsuranceProvider;
	vehicleType?: VehicleType;
	vehicleRisk: number;
	portfolioType?: PortfolioType;
	isLombardLoan?: boolean;
	// DirectInvestments
	directInvestments: {
		[key: string]: {
			usingRamifyRec: boolean;
			percentage?: number;
			fees?: number;
			annualYield?: number; // fs
			maturity?: number; //fs
		};
	};
	// BacktestDetails
	backtestFrequency: BackTestFrequency;
};

export type CrossAssetBacktestResponse = {
	ltvLombardInfo?: {
		ltvMax: number;
		effectiveLtvDict: Record<string, number>;
		effectiveLtvGlobal: number;
	};
	trackedIndexRepartition?: Record<string, number>;
	vehiculeRiskScore: number | null;
	globalRiskScore: number;
	backtestGraph: { id: string; value: number }[];
	backtestPeriod: string;
	totalReturn: number;
	annualizedReturn: number;
	annualizedVolatility: number;
	returnAdjustedToRisk: number;
	detailedPortfolio: { isin: string; label: string; weight: number; montant: number }[];
	assetClassRepartition: Record<string, number>;
	// trackedIndexRepartition: unknown;
	// ltvLombardInfo: unknown;
	equityExpectedReturn: number;
	bondsExpectedReturn: number;
	fondsEurosExpectedReturn: number;
	bonusExpectedReturn: number;
	scpiNumber: number;
	scpiExpectedReturn: number;
	maxDrawdown: number;
	var5pc: number;
	cvar5pc: number;
	vehiculeProjection: {
		dates: string[];
		capitalWithDeposits: number[];
		capitalWithoutDeposits: number[];
		epargneWithDeposits: number[];
		epargneWithoutDeposits: number[];
		vehiculeAnnualizedReturn: number;
	};
};

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		generateNewBacktest: builder.mutation<CrossAssetBacktestResponse, CrossAssetBacktestBody>({
			query: (body) => ({
				url: '/bo/cpm/cross-asset-backtest',
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const { useGenerateNewBacktestMutation } = endpoints;
