import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Box,
	Button,
	Checkbox,
	Divider,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	HStack,
	Input,
	Select,
	Step,
	StepIcon,
	StepIndicator,
	StepNumber,
	Stepper,
	StepSeparator,
	StepStatus,
	Switch,
	Text,
	Textarea,
	useDisclosure,
	useSteps,
	VStack,
} from '@chakra-ui/react';
import PostalMime, { Email } from 'postal-mime';

import AlertDialog from 'components/AlertDialog';
import DocumentButton from 'components/documentButton';
import CustomModal from 'components/Modal';
import useAssignedOps from 'hooks/useAssignedOps';
import useThemedToast from 'hooks/useThemedToast';
import DealList from 'pages/ops/super/deal/DealList';
import { AllDeal } from 'services/deal';
import {
	BlockingInstanceWithKyc,
	InstanceDocumentData,
	useContextNoteReplyMutation,
	useCreateBlockingInstancesMutation,
	useDeleteBlockingInstanceMutation,
	useMatchBlockingInstancesMutation,
} from 'services/ops/blocking-instance';
import { BlockingInstanceLifecycle, BlockingInstanceStatus } from 'types/blocking-instance.type';
import { ProductType } from 'types/global.type';
import { OPS, opsDisplayName, opsToEmail } from 'types/ops-list.type';
import { documentDataMap, DocumentName, S3Folder } from 'utils/documentNamingMap';
import { isNone, isNotNone } from 'utils/functions';

import {
	templateBlockedOperation,
	templateContextNote,
	templateMissingDoc,
	templateNoMissingDocs,
	templateRoadblock,
	templateUpdatingInfo,
} from './templateMail';

export enum Partner {
	APICIL = 'APICIL',
	CFCAL = 'CFCAL',
	EURAZEO = 'EURAZEO',
	NOVAXIA = 'NOVAXIA',
	OTHER = 'OTHER',
}

const b64DecodeUnicode = (str: string) =>
	decodeURIComponent(
		Array.prototype.map
			.call(
				atob(str.replace(/-/g, '+').replace(/_/g, '/')),
				(c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2),
			)
			.join(''),
	);

const parseEmail = async (emailBody: string, emailSubject: string) => {
	const parser = new PostalMime();
	let parsedBase64 = b64DecodeUnicode(emailBody).replace('<body', `\n<body`);
	if (!parsedBase64.includes('<html')) parsedBase64 = `\n<span>${parsedBase64.replaceAll('\n', '<br>')}</span>`;
	return parser.parse(`Subject: ${emailSubject}\nContent-Type: text/html; charset=utf-8\n${parsedBase64}.`);
};

const steps = [{ title: 'Mail' }, { title: 'Choix du deal' }, { title: 'Documents manquants' }];

const canGoToNext = (data: {
	activeStep: number;
	deal?: AllDeal;
	missingDocuments: InstanceDocumentData[];
	noMissingDocuments: boolean;
	contextNote?: boolean;
}) => {
	if (data.contextNote) return true;
	if (data.activeStep === 1 && isNone(data.deal)) return true;
	if (data.activeStep === 2 && data.missingDocuments.length === 0 && !data.noMissingDocuments) return true;
	if (data.activeStep === 2 && data.missingDocuments.length > 0 && data.missingDocuments.some((d) => d.comment === ''))
		return true;
	return false;
};

const canGoToPrevious = (data: { activeStep: number; hasMail: boolean }) => {
	if (data.activeStep === 0) return true;
	if (data.activeStep === 1 && !data.hasMail) return true;
	return false;
};

type BlockingInstanceCreateProps = {
	onClose: () => void;
	existingDeal?: AllDeal; // in case we want to create a blocking instance from souscription page
	existingBlockingInstance?: BlockingInstanceWithKyc; // in case we want to match an existing blocking instance
};

const BlockingInstanceCreate = ({ existingDeal, existingBlockingInstance, onClose }: BlockingInstanceCreateProps) => {
	const toast = useThemedToast();
	const navigate = useNavigate();
	const { isOpen: isDeleteDialogOpen, onOpen: openDeleteDialog, onClose: closeDeleteDialog } = useDisclosure();

	const [missingDocuments, setMissingDocuments] = useState<InstanceDocumentData[]>([]);
	const [selectedDocuments, setSelectedDocuments] = useState(DocumentName.JUSTIFICATIF_DOMICILE);
	const [documentFolder, setDocumentFolder] = useState(S3Folder.PJ);
	const [commentOps, setCommentOps] = useState('');
	const [message, setMessage] = useState('');
	const [lifecycle, setLifecycle] = useState<BlockingInstanceLifecycle>(BlockingInstanceLifecycle.MISSING_INFO);
	const [partner, setPartner] = useState<Partner>(Partner.APICIL);
	const [product, setProduct] = useState<ProductType>(ProductType.INVEST);
	const [selectedDeal, setSelectedDeal] = useState<AllDeal | undefined>(undefined);
	const [noMissingDocuments, setNoMissingDocuments] = useState(false);
	const [emailData, setEmailData] = useState<Email | undefined>(undefined);

	const { assignedOps, onChangeAssignedOps } = useAssignedOps();
	const { activeStep, goToPrevious, goToNext, setActiveStep } = useSteps({ index: 0, count: steps.length });

	const [createBi, { isLoading: isCreateLoading }] = useCreateBlockingInstancesMutation();
	const [matchBI, { isLoading: isMatchLoading }] = useMatchBlockingInstancesMutation();
	const [contextNoteReply, { isLoading: isContextNoteReplyLoading }] = useContextNoteReplyMutation();
	const [deleteBI, { isLoading: isDeleteLoading }] = useDeleteBlockingInstanceMutation();

	const handleCreate = async () => {
		createBi({
			missingDocuments,
			status: BlockingInstanceStatus.SENT_TO_CLIENT,
			message: message || undefined,
			product,
			lifecycle,
			partner,
			email: selectedDeal?.user.email,
			linkedEntityId: selectedDeal?.id,
			noMissingDocuments,
			properties: { id: undefined, assignedOpsEmail: assignedOps, comment: commentOps || undefined },
		})
			.unwrap()
			.then((bi) => {
				onClose();
				toast({ status: 'success', title: 'Instance bloquante crée avec succès' });
				navigate(`/ops/super/blocking-instance/${bi.id}`);
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	const handleMatch = () => {
		matchBI({
			id: existingBlockingInstance!.id,
			email: selectedDeal!.user.email,
			message: message || undefined,
			missingDocuments,
			noMissingDocuments,
			linkedEntityId: selectedDeal!.id,
			productType: selectedDeal!.productType,
			properties: {
				id: existingBlockingInstance!.opsPropertiesId,
				assignedOpsEmail: assignedOps,
				comment: commentOps || undefined,
			},
		})
			.unwrap()
			.then((bi) => {
				onClose();
				toast({ status: 'success', title: "Client associé à l'instance bloquante avec succès" });
				navigate(`/ops/super/blocking-instance/${bi.id}`);
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	const handleContextNoteReply = () => {
		contextNoteReply({
			id: existingBlockingInstance!.id,
			message,
		})
			.unwrap()
			.then(() => {
				onClose();
				toast({ status: 'success', title: 'Instance bloquante fermée avec succès' });
				navigate(`/ops/super/blocking-instance`);
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	const handleDelete = () => {
		deleteBI({ id: existingBlockingInstance!.id })
			.unwrap()
			.then(() => {
				closeDeleteDialog();
				toast({ status: 'success', title: 'Instance bloquante supprimée avec succès' });
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	// init data
	useEffect(() => {
		// setting blocking instance data
		if (!existingBlockingInstance) setActiveStep(1);
		if (existingBlockingInstance?.lifecycle) setLifecycle(existingBlockingInstance.lifecycle);
		if (existingBlockingInstance?.product) setProduct(existingBlockingInstance.product);
		if (existingBlockingInstance?.partner) setPartner(existingBlockingInstance.partner as Partner);
		if (existingBlockingInstance?.missingDocuments)
			setMissingDocuments(
				existingBlockingInstance.missingDocuments.map((d) => ({ documentName: d.documentName, comment: d.comment })),
			);
		if (!existingBlockingInstance?.mailBody) setEmailData(undefined);
		else
			parseEmail(existingBlockingInstance.mailBody, existingBlockingInstance.mailSubject)
				.then((res) => setEmailData(res))
				.catch((err) => console.log(err));

		// setting deal data
		if (isNotNone(existingDeal) && isNone(selectedDeal)) {
			setSelectedDeal(existingDeal);
			setActiveStep(2);
		}

		// setting blocking instance ops data
		if (existingBlockingInstance?.opsProperties?.assignedOpsEmail)
			onChangeAssignedOps(existingBlockingInstance?.opsProperties?.assignedOpsEmail);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// update data on deal change
	useEffect(() => {
		if (!selectedDeal) return;
		setProduct(selectedDeal.productType);
		if (selectedDeal.productType === ProductType.INVEST) setPartner(Partner.APICIL);
		else if (selectedDeal.productType === ProductType.CASH) setPartner(Partner.CFCAL);
		else setPartner(Partner.OTHER);
	}, [selectedDeal]);

	// update data on lifecycle change
	useEffect(() => {
		const firstName = selectedDeal?.user?.kyc.firstName || '';
		if (lifecycle === BlockingInstanceLifecycle.UPDATING_INFO)
			setMessage(templateUpdatingInfo(firstName, missingDocuments));
		if (lifecycle === BlockingInstanceLifecycle.MISSING_INFO)
			setMessage(templateMissingDoc(firstName, missingDocuments));
		if (lifecycle === BlockingInstanceLifecycle.ROADBLOCK) setMessage(templateRoadblock(firstName, missingDocuments));
		if (lifecycle === BlockingInstanceLifecycle.BLOCKED_OPERATION)
			setMessage(templateBlockedOperation(firstName, missingDocuments));
		if (noMissingDocuments) setMessage(templateNoMissingDocs(firstName));
	}, [lifecycle, selectedDeal?.user?.kyc.firstName, missingDocuments, noMissingDocuments]);

	// update default document to upload when upload folder changes
	useEffect(() => {
		setSelectedDocuments(
			Object.keys(documentDataMap).find(
				(key) => documentDataMap[key as DocumentName].folder === documentFolder,
			) as DocumentName,
		);
	}, [documentFolder]);

	const [contextNote, setContextNote] = useState(false);

	return (
		<CustomModal
			isOpen
			onClose={onClose}
			contentProps={{ minW: '1500px' }}
			headersProps={{ children: 'Créer une instance bloquante' }}
			footerProps={{
				children: (
					<HStack w="100%" mb="8px" justify="space-between">
						<HStack w="100%" mb="8px" justify="start">
							{isNotNone(existingBlockingInstance) && (
								<Button onClick={openDeleteDialog} colorScheme="red">
									Supprimer l'instance bloquante
								</Button>
							)}
							<Button
								_hover={{ cursor: 'auto' }}
								rightIcon={
									<Switch
										isChecked={contextNote}
										onChange={(event) => {
											if (event.target.checked) {
												setMessage(templateContextNote());
												setContextNote(true);
											} else setContextNote(false);
										}}
									/>
								}
							>
								Cette instance est une note contextuelle
							</Button>
						</HStack>

						<HStack w="100%" mb="8px" justify="end">
							<Button
								onClick={goToPrevious}
								colorScheme="blue"
								isDisabled={canGoToPrevious({ activeStep, hasMail: isNotNone(existingBlockingInstance) })}
							>
								Précédent
							</Button>

							{activeStep === 2 || contextNote ? (
								<Button
									colorScheme="blue"
									onClick={contextNote ? handleContextNoteReply : existingBlockingInstance ? handleMatch : handleCreate}
									isDisabled={canGoToNext({
										activeStep,
										deal: selectedDeal,
										missingDocuments,
										noMissingDocuments,
									})}
									isLoading={isCreateLoading || isMatchLoading || isContextNoteReplyLoading}
								>
									Créer et envoyer {!contextNote && 'au client'}
								</Button>
							) : (
								<Button
									colorScheme="blue"
									onClick={goToNext}
									isDisabled={canGoToNext({
										activeStep,
										deal: selectedDeal,
										missingDocuments,
										noMissingDocuments,
										contextNote,
									})}
								>
									Suivant
								</Button>
							)}
						</HStack>
					</HStack>
				),
			}}
		>
			<VStack align="start" w="100%" spacing="24px">
				{!contextNote && (
					<Stepper size="lg" index={activeStep} w="100%">
						{steps
							.filter((s) => !(s.title === 'Mail' && isNone(existingBlockingInstance)))
							.map((step) => (
								<Step key={step.title}>
									<StepIndicator boxSize={8}>
										<StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
									</StepIndicator>

									<Box flexShrink="0">
										<Heading size="sm">{step.title}</Heading>
									</Box>

									<StepSeparator />
								</Step>
							))}
					</Stepper>
				)}

				{activeStep == 0 && emailData && (
					<VStack align="start" w="100%" spacing="24px">
						<Heading size="md">Sujet: {emailData.subject}</Heading>
						<Box maxH="450px" overflowY="scroll" dangerouslySetInnerHTML={{ __html: emailData.html ?? '' }} />
						{contextNote && (
							<VStack align="start" w="100%">
								<Divider />
								<FormControl>
									<FormLabel>
										Répondre à <b>{existingBlockingInstance?.mailSender}</b>
									</FormLabel>
									<Textarea h="80px" value={message} onChange={(e) => setMessage(e.target.value)} />
								</FormControl>
							</VStack>
						)}
					</VStack>
				)}

				{activeStep === 1 && (
					<DealList
						selectedDeal={selectedDeal}
						customInput={existingDeal?.user.email}
						onClick={setSelectedDeal}
						pageSize={11}
						context="blocking-instance"
					/>
				)}

				{activeStep === 2 && selectedDeal && (
					<VStack align="start" w="100%" spacing="24px">
						<HStack w="100%">
							<VStack align="start" spacing="16px" flex="1.5">
								{isNone(existingBlockingInstance) && (
									<HStack align="start" w="100%">
										<VStack align="start" w="100%">
											<Text>Partenaire</Text>
											<Select value={partner} onChange={(e) => setPartner(e.target.value as Partner)}>
												{Object.values(Partner).map((s) => (
													<option key={s} value={s}>
														{s}
													</option>
												))}
											</Select>
										</VStack>

										<VStack align="start" w="100%">
											<Text>Lifecycle</Text>
											<Select
												value={lifecycle}
												onChange={(e) => setLifecycle(e.target.value as BlockingInstanceLifecycle)}
											>
												{Object.values(BlockingInstanceLifecycle).map((s) => (
													<option key={s} value={s}>
														{s}
													</option>
												))}
											</Select>
										</VStack>
									</HStack>
								)}

								<VStack align="start" w="100%">
									<HStack w="100%" justify="space-between">
										<Text>Documents manquants</Text>
										<Checkbox
											mr="16px"
											checked={noMissingDocuments}
											onChange={(event) => {
												setNoMissingDocuments(event.target.checked);
												setMissingDocuments([]);
											}}
										>
											Ne pas demander de documents
										</Checkbox>
									</HStack>
									<HStack w="100%">
										<Select value={documentFolder} onChange={(e) => setDocumentFolder(e.target.value as S3Folder)}>
											<option value={S3Folder.PJ}>Pièce justificative</option>
											<option value={S3Folder.SUBSCRIPTION}>Document lié à la souscription</option>
											<option value={S3Folder.BANK_ACCOUNT}>Document bancaire</option>
											<option value={S3Folder.MORAL_PERSON}>Document personne morale</option>
										</Select>
										<Select
											isDisabled={noMissingDocuments}
											value={selectedDocuments}
											onChange={(e) => setSelectedDocuments(e.target.value as DocumentName)}
										>
											{Object.entries(documentDataMap)
												.filter(([, value]) => value.folder === documentFolder)
												.map(([key, value]) => (
													<option key={key} value={key}>
														{value.displayName}
													</option>
												))}
										</Select>
										<Button
											w="150px"
											isDisabled={noMissingDocuments}
											onClick={() =>
												setMissingDocuments([...missingDocuments, { documentName: selectedDocuments, comment: '' }])
											}
										>
											Ajouter
										</Button>
									</HStack>
								</VStack>

								<VStack align="start" w="100%">
									<Text>Message pour le client</Text>
									<Textarea h="275px" value={message} onChange={(e) => setMessage(e.target.value)} />
								</VStack>
							</VStack>

							<VStack align="start" spacing="16px" flex="1">
								{isNone(existingBlockingInstance) && (
									<VStack align="start" w="100%">
										<Text>Produit</Text>
										<Select value={product} onChange={(e) => setProduct(e.target.value as ProductType)}>
											{Object.values(ProductType).map((s) => (
												<option key={s} value={s}>
													{s}
												</option>
											))}
										</Select>
									</VStack>
								)}

								<VStack align="start" w="100%">
									<Text>Responsable</Text>
									<Select value={assignedOps} onChange={(e) => onChangeAssignedOps(e.target.value)}>
										{Object.values(OPS).map((ops) => (
											<option key={ops} value={opsToEmail[ops]}>
												{opsDisplayName[ops]}
											</option>
										))}
									</Select>
								</VStack>

								<VStack align="start" w="100%">
									<Text>Commentaire OPS</Text>
									<Textarea h="275px" value={commentOps} onChange={(e) => setCommentOps(e.target.value)} />
								</VStack>
							</VStack>
						</HStack>

						<VStack align="start" spacing="16px" w="100%">
							{missingDocuments.map((doc) => (
								<HStack key={doc.documentName} align="center" borderRadius="4px" spacing="16px" w="100%">
									<Flex w="300px">
										<DocumentButton
											key={doc.documentName}
											name={documentDataMap[doc.documentName].displayName ?? doc}
											onDelete={() =>
												setMissingDocuments(missingDocuments.filter((d) => d.documentName !== doc.documentName))
											}
										/>
									</Flex>
									<Input
										placeholder="Raison"
										onChange={(e) =>
											setMissingDocuments(
												missingDocuments.map((d) =>
													d.documentName === doc.documentName ? { ...d, comment: e.target.value } : d,
												),
											)
										}
									/>
								</HStack>
							))}
						</VStack>
					</VStack>
				)}
			</VStack>

			<AlertDialog
				isOpen={isDeleteDialogOpen}
				onClose={closeDeleteDialog}
				header="Supprimer cette instance bloquante"
				body={<Text>Vous êtes sur le point de supprimer cette instance bloquante.</Text>}
				footer={
					<HStack w="100%" justify="space-between">
						<Button onClick={closeDeleteDialog}>Annuler</Button>
						<Box>
							<Button colorScheme="red" ml={3} isLoading={isDeleteLoading} onClick={handleDelete}>
								Supprimer
							</Button>
						</Box>
					</HStack>
				}
			/>
		</CustomModal>
	);
};

export default BlockingInstanceCreate;
