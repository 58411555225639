import { FC } from 'react';
import { RepeatIcon } from '@chakra-ui/icons';
import {
	Button,
	IconButton,
	Input,
	Menu,
	MenuButton,
	MenuItemOption,
	MenuList,
	MenuOptionGroup,
	Tooltip,
} from '@chakra-ui/react';

import { AllDeal, OpsTransferDeal } from 'services/deal';
import { BlockingInstanceWithKyc } from 'services/ops/blocking-instance';
import { isNotNone } from 'utils/functions';

export const searchByFunc = (
	item: AllDeal | BlockingInstanceWithKyc | OpsTransferDeal,
	searchBy: string,
	input: string,
) => {
	let field = item?.user?.kyc?.lastName ?? '';
	switch (searchBy) {
		case 'email':
			field = item?.user?.email ?? '';
			break;
		case 'pm':
			if ('moralPerson' in item) field = item.moralPerson?.denomination ?? '';
			break;
	}
	return field.toLowerCase().includes(input.toLowerCase());
};

export type SearchBy = 'email' | 'name' | 'pm';

type SearchByFilterProps = {
	isFetching?: boolean;
	onClearCache?: () => void;
	searchBy: SearchBy;
	onChangeSearchBy?: (v: SearchBy) => void;
	search: string;
	onChangeSearch: (v: string) => void;
	size?: 'sm' | 'md' | 'lg';
};

const SearchByFilter: FC<SearchByFilterProps> = ({
	isFetching,
	onClearCache,
	searchBy,
	onChangeSearchBy,
	search,
	onChangeSearch,
	size = 'md',
}) => (
	<>
		<Input
			value={search}
			onChange={(e) => onChangeSearch(e.target.value.trim())}
			placeholder={searchBy === 'email' ? 'Email' : 'Nom'}
			w="300px"
			bg="white"
			size={size}
		/>
		<Menu closeOnSelect={false}>
			{isNotNone(isFetching) && isNotNone(onClearCache) && (
				<Tooltip label="Actualiser en supprimant le cache">
					<IconButton size={size} isLoading={isFetching} onClick={onClearCache} aria-label="hard refresh">
						<RepeatIcon />
					</IconButton>
				</Tooltip>
			)}
			{isNotNone(onChangeSearchBy) && (
				<>
					<MenuButton size={size} as={Button}>
						Chercher par
					</MenuButton>
					<MenuList minWidth="240px">
						<MenuOptionGroup value={searchBy} onChange={(v) => onChangeSearchBy(v as SearchBy)} type="radio">
							<MenuItemOption value="email">Email</MenuItemOption>
							<MenuItemOption value="name">Nom</MenuItemOption>
							<MenuItemOption value="pm">Personne morale</MenuItemOption>
						</MenuOptionGroup>
					</MenuList>
				</>
			)}
		</Menu>
	</>
);

export default SearchByFilter;
