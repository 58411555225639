import { useState } from 'react';

function useLocalstorage<T>(key: string, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
	const [value, setValue] = useState<T>(() => {
		const storedValue = localStorage.getItem(key);
		try {
			return storedValue ? JSON.parse(storedValue) : initialValue;
		} catch (e) {
			return storedValue ?? initialValue;
		}
	});

	const setLocalstorage = (newVal: T | ((val: T) => T)) => {
		setValue((prevValue) => {
			const newValue = newVal instanceof Function ? newVal(prevValue) : newVal;
			localStorage.setItem(key, JSON.stringify(newValue));
			return newValue;
		});
	};

	return [value, setLocalstorage];
}

export default useLocalstorage;
