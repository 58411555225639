import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import { ResponsiveBar } from '@nivo/bar';

import { LabHoldings, LabTurnover } from 'services/rebalancing-contrat';

type BarGraphProps = {
	data: LabHoldings | LabTurnover;
	turnover?: number;
	aum: number;
	category: string;
	title: string;
	height?: string;
};

const BarGraph = ({ data, turnover, aum, category, title, height }: BarGraphProps) => (
	<Box borderWidth="2px" borderRadius="4px" p="16px">
		<VStack w="100%" align="start" justify="space-around" ml="24px" mt="8px">
			<Heading as="h4" size="md">
				{title}
			</Heading>
			{turnover && (
				<Text>
					Turnover {category}: <b>{turnover}€</b> - ({((turnover / aum) * 100).toFixed(2)} %)
				</Text>
			)}
		</VStack>
		<Box h={height ?? '300px'}>
			<ResponsiveBar
				data={Object.keys(data)
					.sort()
					.map((key) => ({ asset: key, amount: data[key] }))}
				keys={['amount']}
				indexBy="asset"
				margin={{ top: 25, right: 50, bottom: 25, left: 50 }}
				padding={0.3}
				groupMode="grouped"
				valueScale={{ type: 'linear' }}
				indexScale={{ type: 'band', round: true }}
				colors={{ scheme: 'nivo' }}
				colorBy="indexValue"
				// borderColor={{
				// 	from: 'color',
				// 	modifiers: [['darker', 1.6]],
				// }}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					// legend: 'country',
					legendPosition: 'middle',
					legendOffset: 32,
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					// legend: 'food',
					legendPosition: 'middle',
					legendOffset: -40,
				}}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelTextColor={{
					from: 'color',
					modifiers: [['darker', 1.6]],
				}}
			/>
		</Box>
	</Box>
);

export default BarGraph;
