import { useMemo } from 'react';

import { AllDeal } from 'services/deal';
import { useGetStatusesQuery, useGetStatusOptionsQuery } from 'services/ops/status';
import { AirtableAttachment } from 'types/airtable/global.airtable.type';
import { ProductType } from 'types/global.type';
import { getProductTypeKey, isNone } from 'utils/functions';

type BoInfo = {
	description?: string;
	screenshots?: AirtableAttachment[];
};

const useDealBoInfo = (deal: AllDeal): BoInfo | null => {
	const { data } = useGetStatusesQuery();
	const { data: statusOptions } = useGetStatusOptionsQuery();

	const dealBoInfo = useMemo<BoInfo | null>(() => {
		if (isNone(data) || isNone(statusOptions)) return null;

		// Get the relevant row in the airtable data
		let dealRow = data[getProductTypeKey(deal, Object.keys(statusOptions))];

		// For PE and SCPI deals, we use the fundId to get the relevant row in the airtable data
		// [warning]: because of this, the feature won't work properly for staging PE and SCPI deals,
		if (deal.productType === ProductType.SCPI || deal.productType === ProductType.PE) {
			if (deal.airtableFundId) dealRow = data[deal.airtableFundId];
			else return null;
		}

		// Get the relevant status column group in the airtable data
		const statusCol = dealRow?.[deal.status];

		return {
			description: statusCol?.boDescription,
			screenshots: statusCol?.boScreenshot,
		};
	}, [data, deal, statusOptions]);

	return dealBoInfo;
};

export default useDealBoInfo;
