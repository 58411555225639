import { FC, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Center, Divider, Heading, HStack, Input, VStack } from '@chakra-ui/react';
import { format } from 'd3-format';

import GraphPie from 'components/graphs/GraphPie';
import LineChart from 'components/graphs/LineChart';
import ScpiTable from 'components/graphs/ScpiTable';
import TableGraph from 'components/graphs/TableGraph';
import useThemedToast from 'hooks/useThemedToast';
import { ScpiDirectSimulationForm } from 'pages/cpm/scpi-direct/forms/formTypes';
import Preferences from 'pages/cpm/scpi-direct/forms/Preferences';
import {
	LabSCPIRecommendationResponse,
	PrefGeographieType,
	ScpiSecteurType,
	SecteurPreference,
	useCreateScpiDirectRecommendationMutation,
	useCreateScpiDirectSimulationMutation,
	useCreateScpiDirectTemplateMutation,
} from 'services/cpm/scpi-direct';
import { FamilySituation } from 'types/referentiels/familySituation';

import InvestInformations from './forms/InvestInformations';
import PersonalInformations from './forms/PersonalInformations';

const geographicPreferenceMap: { [key: string]: PrefGeographieType } = {
	'Île-de-France': 'Idf',
	'Europe (hors France)': 'Etranger',
	'France (hors Île-de-France)': 'Régions',
};

const PropaleScpiDirect: FC = () => {
	const toast = useThemedToast();

	const [createScpiDirectRecommendation, { isLoading: isLoadingRecommendation }] =
		useCreateScpiDirectRecommendationMutation();
	const [createScpiDirectSimulation, { data: simulationResult, isLoading: isLoadingSimulation }] =
		useCreateScpiDirectSimulationMutation();
	const [createScpiDirectTemplate, { isLoading: isLoadingTemplate }] = useCreateScpiDirectTemplateMutation();

	const [recommendationTable, setRecommendationTable] = useState<LabSCPIRecommendationResponse | null>(null);

	const totalInvestment = useMemo(() => {
		if (!recommendationTable) return 0;
		return Object.values(recommendationTable.allocation).reduce(
			(acc, scpi) => acc + scpi.montant_nu + scpi.montant_plein,
			0,
		);
	}, [recommendationTable]);

	const formMethods = useForm<ScpiDirectSimulationForm>({
		mode: 'onChange',
		defaultValues: {
			amount: 1000,
			time: 4,
			annualRevenue: 50000,

			moralPerson: false,
			familySituation: FamilySituation.SINGLE,
			children: 0,

			isrLabel: false,
			regions: [],

			investPreference: 'maximize capital',
			beginningYear: 1,
			sector: [],

			firstName: '',
			lastName: '',
		},
	});

	const [horizon, initialAmount] = formMethods.watch(['time', 'amount']);

	const isScpiTableValid = useMemo(() => {
		if (!recommendationTable) return false;
		for (const scpi of Object.values(recommendationTable.allocation)) {
			if (scpi.parts_nue > 0 && !scpi.decote_period)
				return 'Veuillez renseigner les valeurs des périodes de démembrement pour faire une simulation';
			if (scpi.parts_nue > 0 && scpi.decote_period > horizon)
				return "La période de démembrement doit être plus courte que la durée de l'investissment";
			if (scpi.parts_nue > 0 && scpi.decote_period < 3)
				return 'La période de démembrement doit être supérieure à 4 ans';
			if (scpi.parts_nue + scpi.parts_plein === 0) return 'Veuillez saisir un nombre de parts dans chaque SCPI';
			if (totalInvestment > initialAmount) return 'Le montant total est supérieur au montant investi souhaité';
		}
		return 'ok';
	}, [recommendationTable, horizon, initialAmount, totalInvestment]);

	const handleReco = formMethods.handleSubmit(async (data) => {
		const result = await createScpiDirectRecommendation({
			investment_horizon: data.time,
			family_situation: data.familySituation,
			number_children: data.children,
			initial_investment: Number(data.amount),
			investment_strategy: data.investPreference,
			isISR: data.isrLabel,
			pref_geo: data.regions.map((region) => geographicPreferenceMap[region]),
			pref_secteur: data.sector,
			personne_morale: data.moralPerson,
			reco_filter: true,
			annual_net_revenue: Number(data.annualRevenue),
			maximizing_year: data.investPreference === 'maximize additional revenue' ? Number(data.beginningYear) : 1,
			has_filled_tax_shares_form: false,
			has_filled_tax_form: false,
			retool_reco_filter: false,
		}).unwrap();
		setRecommendationTable(result);
	});

	const handleSimu = formMethods.handleSubmit(async (data) => {
		if (!recommendationTable) return;
		const portfolioAllocation = Object.entries(recommendationTable.allocation || {}).map(([, value]) => ({
			id: value.id_airtable,
			number_of_shares: value.parts_nue + value.parts_plein,
			montant_investi: value.montant_nu + value.montant_plein,
			decote_period: value.decote_period,
		}));

		if (totalInvestment > data.amount) {
			alert('Le montant total investi ne peut pas être supérieur au montant initial');
			return;
		}

		createScpiDirectSimulation({
			investment_horizon: data.time,
			annual_net_revenue: Number(data.annualRevenue),
			number_children: data.children,
			family_situation: data.familySituation,
			portfolio_allocation: portfolioAllocation,
			retool_reco_filter: false,
			has_filled_tax_form: false,
			has_filled_tax_shares_form: false,
			personne_morale: data.moralPerson,
		})
			.unwrap()
			.then(() => {
				toast({
					title: 'Success',
					status: 'success',
					description: 'Simulation effectuée avec succès',
				});
			})
			.catch((err) => {
				toast({
					title: 'Error',
					status: 'error',
					description: err.data.message,
				});
			});
	});

	const handleCreatePage = formMethods.handleSubmit(async (data) => {
		if (!simulationResult || !recommendationTable || !formMethods.formState.isValid) return;
		const raw = Object.entries(simulationResult.repartitons).map(([key, value]) => ({
			label: key.replace('geo', '').replace('secteur', '') as ScpiSecteurType,
			montant: value,
		}));
		const repartitionGeo = Object.entries(simulationResult.stats.gross_répartition_géographique).map(
			([key, value]) => ({
				label: key.replace('geo', '') as PrefGeographieType,
				value: value,
			}),
		);
		const repartitionSect = Object.entries(simulationResult.stats.gross_répartition_sectorielle).map(
			([key, value]) => ({
				label: key.replace('secteur', '') as SecteurPreference,
				value: value,
			}),
		);
		await createScpiDirectTemplate({
			firstName: data.firstName,
			lastName: data.lastName,
			periodeDemembrement: Math.max(...Object.values(recommendationTable.allocation).map((scpi) => scpi.decote_period)),
			initialAUM: totalInvestment,
			mode: recommendationTable.allocation[Object.keys(recommendationTable.allocation)[0]].parts_nue ? 'np' : 'pp',
			timeHorizon: data.time,
			prefGeographie: data.regions.map((region) => geographicPreferenceMap[region]),
			simulationData: {
				raw_rep: raw,
				repartition_data: {
					Géographique: repartitionGeo,
					Sectorielle: repartitionSect,
				},
				stats: {
					tri: simulationResult.stats.tri,
					TD_moyen: simulationResult.stats.TD_moyen,
					Reval_moyen: simulationResult.stats.Reval_moyen,
					Frais_entré_global: simulationResult.stats.Frais_entré_global,
					Frais_entré_global_avec_remise: simulationResult.stats.Frais_entré_global_avec_remise,
					decote_moyen: simulationResult.stats['Décote moyenne'],
				},
				graph_data_div: {
					Année: Object.keys(simulationResult.div['div net']),
					Dividende: Object.values(simulationResult.div['div net']),
				},
				graph_data_divcum: {
					Année: Object.keys(simulationResult.div['div net']),
					Dividende: Object.values(simulationResult.div['div net']).map((value, index, array) =>
						array.slice(0, index + 1).reduce((acc, curr) => acc + curr),
					),
				},
				graph_data_valo: {
					Année: Object.keys(simulationResult.valo['valo net']),
					Valorisation: Object.values(simulationResult.valo['valo net']),
				},
			},
			scpi_investments: Object.entries(recommendationTable.allocation).map(([key, value]) => ({
				amount: value.montant_nu + value.montant_plein,
				scpi: key,
			})),
		})
			.unwrap()
			.then(() => {
				toast({
					title: 'Success',
					status: 'success',
					description: 'Page Notion générée avec succès',
				});
			})
			.catch((err) => {
				toast({
					title: 'Error',
					status: 'error',
					description: err.data.message,
				});
			});
	});

	return (
		<Center p="24px" w="100%" pb="80px">
			<VStack spacing="32px" w="100%">
				<VStack w="100%" spacing="32px" maxW="1080px">
					<Heading size="md">Propale SCPI Direct</Heading>
					<Divider />
					<HStack spacing="64px" w="100%">
						<InvestInformations formMethods={formMethods} />
						<PersonalInformations formMethods={formMethods} />
					</HStack>
					<Preferences formMethods={formMethods} />
					<Button
						w="50%"
						isDisabled={!formMethods.formState.isValid}
						onClick={handleReco}
						colorScheme="yellow"
						isLoading={isLoadingRecommendation}
					>
						Generer la recommendation Ramify
					</Button>
					<Divider />
					{recommendationTable && (
						<>
							<ScpiTable recommendationTable={recommendationTable} setRecommendationTable={setRecommendationTable} />
							<Heading size="md">Montant total investi: {format(',.3~f')(totalInvestment)} €</Heading>
							<Button
								w="50%"
								colorScheme="yellow"
								onClick={handleSimu}
								isLoading={isLoadingSimulation}
								isDisabled={isScpiTableValid !== 'ok'}
							>
								{isScpiTableValid === 'ok' ? 'Faire une simulation' : isScpiTableValid}
							</Button>
						</>
					)}
				</VStack>
				{simulationResult && (
					<VStack w="100%" spacing="32px">
						<Divider />
						<HStack w="100%" justifyContent="space-around" alignItems="self-start">
							<TableGraph
								titleKey="Chiffres clés"
								titleValue="Valeur"
								data={[
									{ id: 'TD net et moyen', value: format(',.3~f')(simulationResult.stats.TD_moyen * 100) },
									{
										id: 'Frais d’entrées globales',
										value: format(',.3~f')(simulationResult.stats['Frais d’entrées']),
									},
									{ id: 'Décote moyenne', value: format(',.3~f')(simulationResult.stats['Décote moyenne']) },
									{
										id: 'Revalorisation moyenne',
										value: format(',.3~f')(simulationResult.stats.Reval_moyen * 100),
									},
									{ id: 'Gain', value: format(',.3~f')(simulationResult.stats.Gain) },
									{ id: 'TRI net', value: format(',.3~f')(simulationResult.stats.tri * 100) },
								]}
							/>
							<TableGraph
								titleKey="Année"
								titleValue="Div net"
								data={Object.entries(simulationResult.div['div net']).map(([year, divNet]) => ({
									id: year,
									value: format(',.3~f')(Number(divNet)),
								}))}
							/>
						</HStack>
						<HStack w="100%" justifyContent="space-around" alignItems="self-start">
							<GraphPie
								title="Répartition sectorielle"
								data={Object.entries(simulationResult.stats.gross_répartition_sectorielle).map(([key, value]) => ({
									id: key,
									label: key.replace('secteur', ''),
									value,
								}))}
							/>
							<GraphPie
								title="Répartition géographique"
								data={Object.entries(simulationResult.stats.gross_répartition_géographique).map(([key, value]) => ({
									id: key,
									label: key.replace('geo', ''),
									value,
								}))}
							/>
						</HStack>
						<HStack w="100%" justifyContent="space-around" alignItems="self-start">
							<LineChart
								xTitle="Dividende"
								yTitle="Dividendes cumulés"
								data={Object.entries(simulationResult.div['div net']).map(([key]) => ({
									id: key,
									value: Object.values(simulationResult.div['div net'])
										.slice(0, parseInt(key) + 1)
										.reduce((acc, curr) => acc + curr, 0),
								}))}
							/>
							<LineChart
								xTitle="Dividende"
								yTitle="Dividendes annuels nets"
								data={Object.entries(simulationResult.div['div net']).map(([key, value]) => ({
									id: key,
									value,
								}))}
							/>
						</HStack>
						<HStack w="100%" justifyContent="space-around" alignItems="self-start">
							<TableGraph
								titleKey="Repartition"
								titleValue="Valeur"
								data={Object.entries(simulationResult.repartitons).map(([key, value]) => ({
									id: key.replace('secteur', '').replace('geo', ''),
									value: format(',.3~%')(value),
								}))}
							/>
							<LineChart
								xTitle="Valorisation"
								yTitle="Valeur de cession net"
								data={Object.entries(simulationResult.valo['valo net']).map(([key, value]) => ({
									id: key,
									value,
								}))}
							/>
						</HStack>
						<TableGraph
							titleKey="Année"
							titleValue="Valo net"
							data={Object.entries(simulationResult.valo['valo net']).map(([key, value]) => ({
								id: key,
								value: format(',.3~f')(value),
							}))}
						/>
						<Divider />
						<HStack justifyContent="center" maxWidth="1080px" w="100%">
							<Controller
								name="lastName"
								control={formMethods.control}
								render={({ field }) => <Input {...field} placeholder="Nom" bg="white" w="30%" />}
							/>
							<Controller
								name="firstName"
								control={formMethods.control}
								render={({ field }) => <Input {...field} placeholder="Prénom" bg="white" w="30%" />}
							/>
						</HStack>
						<Button colorScheme="blue" w="50%" onClick={handleCreatePage} isLoading={isLoadingTemplate}>
							Générer la page Notion
						</Button>
					</VStack>
				)}
			</VStack>
		</Center>
	);
};

export default PropaleScpiDirect;
