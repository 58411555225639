import { FamilySituation } from 'types/referentiels/familySituation';

export type ScpiCreditSimulationForm = {
	firstName: string;
	lastName: string;

	creditDuration: number;
	creditAmount: number;
	maxMonthlySavings: number;
	annualNetIncome: number;
	interestRate: number;

	creditUniverse: string;
	isr: boolean;
	personalContribution: number;
	moralPerson: boolean;
	familySituation: FamilySituation;
	nbChildren: number;
	abroadPercentage: number;
	maxScpiByPartner: number;
};

export enum CreditUniverse {
	CIB_CFCA = 'cib cfcal',
	CIB_CREDIT_LIFT = 'cib creditlift',
	PRIMONIAL_CFCA = 'primonial cfcal',
	PRIMONIAL_CACF = 'primonial cacf',
	PRIMONIAL_ARKEA = 'primonial arkea',
	CRYSTAL = 'crystal',
	CRYSTAL_1 = 'crystal_1',
	CRYSTAL_2 = 'crystal_2',
}
