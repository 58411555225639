import { FormLabel, HStack, Radio, RadioGroup, VStack } from '@chakra-ui/react';

import DatePicker from 'components/DatePicker';
import NumberInputWithStepper from 'components/NumberInput';
import AddCommentPropale from 'pages/cpm/product-propale/cards/common/AddCommentPropale';
import { CryptoVersementData } from 'pages/cpm/product-propale/propaleData.type';
import { CryptoInvestPlanPeriod } from 'types/crypto.types';
import { VersementRequestType } from 'types/invest-deal.type';

type CryptoVersementCardProps = {
	data: CryptoVersementData;
	editData: React.Dispatch<React.SetStateAction<CryptoVersementData[]>>;
};

const CRYPTO_DAILY_INVEST_PLAN_MINIMUM = 25;
const CRYPTO_WEEKLY_INVEST_PLAN_MINIMUM = 25;
const CRYPTO_BI_WEEKLY_INVEST_PLAN_MINIMUM = 50;
const CRYPTO_MONTHLY_INVEST_PLAN_MINIMUM = 50;

export const minInvestPlanAmount: { key: CryptoInvestPlanPeriod; value: number; label: string }[] = [
	{
		key: CryptoInvestPlanPeriod.DAILY,
		value: CRYPTO_DAILY_INVEST_PLAN_MINIMUM,
		label: 'Journalière',
	},
	{
		key: CryptoInvestPlanPeriod.WEEKLY,
		value: CRYPTO_WEEKLY_INVEST_PLAN_MINIMUM,
		label: 'Hebdomadaire',
	},
	{
		key: CryptoInvestPlanPeriod.BI_WEEKLY,
		value: CRYPTO_BI_WEEKLY_INVEST_PLAN_MINIMUM,
		label: 'Toutes les deux semaines',
	},
	{
		key: CryptoInvestPlanPeriod.MONTHLY,
		value: CRYPTO_MONTHLY_INVEST_PLAN_MINIMUM,
		label: 'Mensuelle',
	},
];

export const CryptoVersementCard = ({ data, editData }: CryptoVersementCardProps) => {
	// If the is any issue with the data, the minimum amount will be the monthly minimum amount as it will work for all the cases
	const minAmount =
		data.versementRequestType === VersementRequestType.PROGRAMME
			? minInvestPlanAmount.find((p) => p?.key === data?.period)?.value ?? CRYPTO_MONTHLY_INVEST_PLAN_MINIMUM
			: CRYPTO_MONTHLY_INVEST_PLAN_MINIMUM;

	return (
		<VStack w="100%" spacing="32px">
			<HStack w="100%">
				<VStack align="start" spacing="16px" w="100%">
					<FormLabel fontSize="sm" w="150px">
						Montant
					</FormLabel>
					<NumberInputWithStepper
						step={1}
						min={minAmount}
						value={data.initialDepositAmount + '€'}
						onChange={(initialDepositAmount) =>
							editData((old) => {
								const newData = [...old];
								newData.splice(newData.indexOf(data), 1);
								return [...newData, { ...data, initialDepositAmount: +initialDepositAmount }].sort(
									(a, b) => a.id - b.id,
								);
							})
						}
					/>
				</VStack>
				<VStack align="start" spacing="16px" w="100%">
					<FormLabel fontSize="sm">Versement</FormLabel>
					<RadioGroup
						value={data.versementRequestType}
						onChange={(type) =>
							editData((old) => {
								const newData = [...old];
								newData.splice(newData.indexOf(data), 1);
								return [...newData, { ...data, versementRequestType: type as VersementRequestType }].sort(
									(a, b) => a.id - b.id,
								);
							})
						}
					>
						<HStack>
							<Radio size="sm" value={VersementRequestType.PROGRAMME}>
								Versement programmé
							</Radio>
							<Radio size="sm" value={VersementRequestType.COMPLEMENTAIRE}>
								Versement complémentaire
							</Radio>
						</HStack>
					</RadioGroup>
				</VStack>
			</HStack>

			{data.versementRequestType === VersementRequestType.PROGRAMME && (
				<VStack align="start" spacing="16px" w="100%">
					<RadioGroup
						name="period"
						onChange={(newPeriod) =>
							editData((old) => {
								const newData = [...old];
								newData.splice(newData.indexOf(data), 1);
								return [...newData, { ...data, period: newPeriod as CryptoInvestPlanPeriod }].sort(
									(a, b) => a.id - b.id,
								);
							})
						}
						value={data.period}
						w="100%"
					>
						<FormLabel>Choisissez une fréquence</FormLabel>
						<VStack spacing="16px" align="start">
							<Radio size="md" value={CryptoInvestPlanPeriod.DAILY}>
								Journalière
							</Radio>
							<Radio size="md" value={CryptoInvestPlanPeriod.WEEKLY}>
								Hebdomadaire
							</Radio>
							<Radio size="md" value={CryptoInvestPlanPeriod.BI_WEEKLY}>
								Toutes les deux semaines
							</Radio>
							<Radio size="md" value={CryptoInvestPlanPeriod.MONTHLY}>
								Mensuelle
							</Radio>
						</VStack>
					</RadioGroup>

					<DatePicker
						value={data.triggerAt?.toLocaleDateString()}
						onChange={(type) =>
							editData((old) => {
								const newData = [...old];
								newData.splice(newData.indexOf(data), 1);
								return [...newData, { ...data, triggerAt: type as Date }].sort((a, b) => a.id - b.id);
							})
						}
					/>
				</VStack>
			)}

			{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
			<AddCommentPropale data={data} editData={editData as React.Dispatch<React.SetStateAction<any[]>>} />
		</VStack>
	);
};
