import { useEffect, useMemo, useState } from 'react';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	Select,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	VStack,
} from '@chakra-ui/react';

import InvestmentHorizonSelect from 'components/InvestmentHorizon';
import NumberInputWithStepper from 'components/NumberInput';
import { CustomPortfolio, InvestData, RamifyGreen } from 'pages/cpm/product-propale/propaleData.type';
import { useGetInvestFundsQuery } from 'services/fund-data';
import { UniverseFund } from 'types/airtable/invest.airtable.type';
import { EnvelopeType, InsuranceProvider, InvestProjectType } from 'types/invest-deal.type';
import { PortfolioType } from 'types/investmentPreferences.type';
import { isNotNone } from 'utils/functions';
import { transformPortfolio } from 'utils/portfolio';
import { displayPercentage } from 'utils/rendering';

import AddCommentPropale from './common/AddCommentPropale';

type InvestCardProps = {
	data: InvestData;
	editData: React.Dispatch<React.SetStateAction<InvestData[]>>;
};

export const bannedIsins = [
	'LU1571051751',
	'FR001400EX68',
	'FR0013535176',
	'FR0013536109',
	'LU1829218319',
	'FR0013393220',
	'EURSKISKEURL',
];

export const hydrateAsset = (asset: CustomPortfolio[number], investFunds?: UniverseFund[]) => {
	const airtableAsset = investFunds?.find((fund) => fund.isin === asset.ISIN);
	const banned = bannedIsins.includes(asset.ISIN);

	return { ...asset, label: airtableAsset?.label, valid: isNotNone(airtableAsset) && !banned };
};

export const InvestCard = ({ data, editData }: InvestCardProps) => {
	const { data: investFunds } = useGetInvestFundsQuery();
	const [errorCustom, setErrorCustom] = useState<string>();

	const hydratedAssets = useMemo(
		() => data?.customPortfolio?.map((asset) => hydrateAsset(asset, investFunds)) ?? [],
		[data?.customPortfolio, investFunds],
	);

	useEffect(() => {
		if (data.envelope === EnvelopeType.PER) {
			editData((old) => {
				const newData = [...old];
				const index = newData.findIndex((d) => d.id === data.id);
				const currentCardData = newData[index];
				newData.splice(index, 1);
				return [...newData, { ...currentCardData, objective: InvestProjectType.RETIREMENT }].sort(
					(a, b) => a.id - b.id,
				);
			});
		}
	}, [data.envelope, data.id, editData]); // do not add data to the dependencies

	return (
		<VStack align="start" spacing="32px">
			<HStack align="start" spacing="32px">
				<VStack w="50%">
					{(data.provider === InsuranceProvider.APICIL || data.provider === InsuranceProvider.LMEP) && (
						<FormControl>
							<HStack w="100%" justify="space-between">
								<FormLabel fontSize="sm" w="150px">
									Type de portefeuille
								</FormLabel>
								<Select
									value={data.portfolioType}
									onChange={(e) => {
										editData((old) => {
											const monthlySaving =
												(e.target.value as PortfolioType) === PortfolioType.CUSTOM ? 0 : data.monthlySaving;
											const newData = [...old];
											newData.splice(newData.indexOf(data), 1);
											return [
												...newData,
												{ ...data, monthlySaving, portfolioType: e.target.value as PortfolioType },
											].sort((a, b) => a.id - b.id);
										});
									}}
								>
									<option key={PortfolioType.ESSENTIAL} value={PortfolioType.ESSENTIAL}>
										Essential
									</option>
									{data.provider === InsuranceProvider.APICIL && (
										<>
											<option key={PortfolioType.FLAGSHIP} value={PortfolioType.FLAGSHIP}>
												Flagship
											</option>
											<option key={PortfolioType.ELITE} value={PortfolioType.ELITE}>
												Elite
											</option>
											<option key={PortfolioType.TITANIUM} value={PortfolioType.TITANIUM}>
												Titanium
											</option>
											<option key={PortfolioType.CUSTOM} value={PortfolioType.CUSTOM}>
												Custom
											</option>
										</>
									)}
								</Select>
							</HStack>
						</FormControl>
					)}

					<FormControl>
						<HStack w="100%" justify="space-between">
							<FormLabel fontSize="sm" w="150px">
								Objectif de l'épargne
							</FormLabel>
							<Select
								value={data.objective}
								onChange={(e) => {
									editData((old) => {
										const newData = [...old];
										newData.splice(newData.indexOf(data), 1);
										return [...newData, { ...data, objective: e.target.value as InvestProjectType }].sort(
											(a, b) => a.id - b.id,
										);
									});
								}}
							>
								{data.provider === InsuranceProvider.APICIL && (
									<>
										<option value={InvestProjectType.REGULAR_REVENU}>Bénéficier de revenus réguliers</option>
										<option value={InvestProjectType.DYNAMIZE_CAPITAL}>Dynamiser votre capital</option>
										<option value={InvestProjectType.SAVING}>Épargner pour réaliser un projet</option>
										<option value={InvestProjectType.OTHER}>Autre</option>
									</>
								)}
								<option value={InvestProjectType.PRECAUTION_SAVING}>Acte de prévoyance</option>
								<option value={InvestProjectType.REIMBURSE_LOAN}>Garantie d’un emprunt</option>
								<option value={InvestProjectType.RETIREMENT}>Constitution d’un complément de retraite</option>
								<option value={InvestProjectType.TRANSMIT_PATRIMONY}>Organisation d’une succession</option>
							</Select>
						</HStack>
					</FormControl>

					<FormControl>
						<HStack w="100%" justify="space-between">
							<FormLabel fontSize="sm" w="150px">
								Durée de l'épargne
							</FormLabel>
							<InvestmentHorizonSelect
								value={data.duration}
								onChange={(e) => {
									editData((old) => {
										const newData = [...old];
										newData.splice(newData.indexOf(data), 1);
										return [...newData, { ...data, duration: +e.target.value }].sort((a, b) => a.id - b.id);
									});
								}}
							/>
						</HStack>
					</FormControl>

					<FormControl>
						<HStack w="100%" justify="space-between">
							<FormLabel fontSize="sm" w="150px">
								Enveloppe
							</FormLabel>
							<Select
								value={data.envelope}
								onChange={(e) => {
									editData((old) => {
										const newData = [...old];
										newData.splice(newData.indexOf(data), 1);
										return [...newData, { ...data, envelope: e.target.value as EnvelopeType }].sort(
											(a, b) => a.id - b.id,
										);
									});
								}}
							>
								<option value={EnvelopeType.AV}>Assurance Vie</option>
								{data.provider === InsuranceProvider.APICIL && (
									<>
										<option value={EnvelopeType.AV_HYBRIDE}>Assurance Vie Hybride</option>
										<option value={EnvelopeType.PER}>PER</option>
									</>
								)}
							</Select>
						</HStack>
					</FormControl>
				</VStack>

				<VStack w="50%">
					<FormControl>
						<HStack w="100%" justify="space-between">
							<FormLabel fontSize="sm" w="150px">
								Montant
							</FormLabel>
							<NumberInputWithStepper
								step={1000}
								min={data.portfolioType === PortfolioType.ELITE ? 10000 : 1000}
								value={data.initialDepositAmount + '€'}
								onChange={(initialDepositAmount) => {
									editData((old) => {
										const newFunds = [...old];
										newFunds.splice(newFunds.indexOf(data), 1);
										return [...newFunds, { ...data, initialDepositAmount: +initialDepositAmount }].sort(
											(a, b) => a.id - b.id,
										);
									});
								}}
							/>
						</HStack>
					</FormControl>

					{(data.provider === InsuranceProvider.APICIL || data.provider === InsuranceProvider.GENERALI) && (
						<FormControl>
							<HStack w="100%" justify="space-between">
								<FormLabel fontSize="sm" w="150px">
									Montant mensuel de l'épargne
								</FormLabel>
								<NumberInputWithStepper
									step={100}
									min={0}
									value={data.monthlySaving + '€'}
									isDisabled={data.portfolioType === PortfolioType.CUSTOM}
									onChange={(monthlySaving) => {
										editData((old) => {
											const newFunds = [...old];
											newFunds.splice(newFunds.indexOf(data), 1);
											return [...newFunds, { ...data, monthlySaving: +monthlySaving }].sort((a, b) => a.id - b.id);
										});
									}}
								/>
							</HStack>
						</FormControl>
					)}

					{(data.provider === InsuranceProvider.APICIL || data.provider === InsuranceProvider.LMEP) && (
						<>
							<FormControl>
								<HStack w="100%" justify="space-between">
									<FormLabel fontSize="sm" w="150px">
										Risque
									</FormLabel>
									<NumberInputWithStepper
										step={0.1}
										min={1}
										max={10}
										value={data.risk}
										onChange={(risk) => {
											editData((old) => {
												const newFunds = [...old];
												newFunds.splice(newFunds.indexOf(data), 1);
												return [...newFunds, { ...data, risk: risk }].sort((a, b) => a.id - b.id);
											});
										}}
									/>
								</HStack>
							</FormControl>

							<FormControl>
								<HStack w="100%" justify="space-between">
									<FormLabel fontSize="sm" w="265px">
										Green
									</FormLabel>
									<Select
										value={data.green}
										onChange={(e) => {
											editData((old) => {
												const newData = [...old];
												newData.splice(newData.indexOf(data), 1);
												return [...newData, { ...data, green: e.target.value as RamifyGreen }].sort(
													(a, b) => a.id - b.id,
												);
											});
										}}
									>
										<option value={RamifyGreen.NO}>Non</option>
										<option value={RamifyGreen.TWO}>2.0 °C</option>
										<option value={RamifyGreen.TWO_FIVE}>2.5 °C</option>
									</Select>
								</HStack>
							</FormControl>
						</>
					)}
				</VStack>
			</HStack>

			{data.portfolioType === PortfolioType.CUSTOM && (
				<VStack align="start" w="100%">
					<FormControl isInvalid={!!errorCustom}>
						<HStack w="100%" justify="space-between">
							<FormLabel fontSize="sm" w="150px">
								Repartition personnalisée
							</FormLabel>

							<VStack align="start" w="100%">
								<Input
									value={JSON.stringify(data.customPortfolio)}
									onChange={(e) => {
										editData((old) => {
											const newData = [...old];
											newData.splice(newData.indexOf(data), 1);
											try {
												const parsedPortfolio = JSON.parse(e.target.value) as Record<string, number>;

												const totalRepartition = Object.values(parsedPortfolio).reduce((acc, curr) => acc + curr, 0);
												if (totalRepartition <= 0.9 || totalRepartition >= 1.1)
													throw new Error('La somme des répartitions doit être égale à 1');

												const formattedPortfolio = transformPortfolio.toArray(parsedPortfolio);
												const leanPortfolio = transformPortfolio.lean(formattedPortfolio);
												const sortedPortfolio = leanPortfolio.sort((a, b) => b.repartition - a.repartition);

												setErrorCustom(undefined);
												return [...newData, { ...data, customPortfolio: sortedPortfolio }];
											} catch (error) {
												setErrorCustom(
													error instanceof SyntaxError
														? 'La répartition personnalisée est invalide'
														: error instanceof Error
														? error.message
														: 'Erreur inconnue',
												);
												return [...newData, { ...data }];
											}
										});
									}}
								/>
								{errorCustom && <FormErrorMessage>{errorCustom}</FormErrorMessage>}
							</VStack>
						</HStack>
					</FormControl>

					{hydratedAssets.length > 0 && (
						<TableContainer w="100%">
							<Table variant="simple">
								<Thead>
									<Tr>
										<Th>ISIN</Th>
										<Th>Nom</Th>
										<Th isNumeric>repartition</Th>
									</Tr>
								</Thead>
								<Tbody>
									{hydratedAssets.map((asset) => (
										<Tr key={asset.ISIN} bg={asset.valid ? undefined : 'red'}>
											<Td>{asset.ISIN}</Td>
											<Td>{asset.label}</Td>
											<Td isNumeric>{displayPercentage(asset.repartition)}</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						</TableContainer>
					)}
				</VStack>
			)}

			{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
			<AddCommentPropale data={data} editData={editData as React.Dispatch<React.SetStateAction<any[]>>} />
		</VStack>
	);
};
