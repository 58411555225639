import { FC, useCallback, useMemo, useState } from 'react';
import {
	Button,
	Card,
	FormControl,
	FormLabel,
	Input,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	VStack,
	Wrap,
} from '@chakra-ui/react';
import fileDownload from 'js-file-download';

import GraphPie from 'components/graphs/GraphPie';
import TableGraph from 'components/graphs/TableGraph';
import TimeChart from 'components/graphs/TimeChart';
import { CrossAssetBacktestResponse, VehicleType } from 'services/cpm/cross-asset-backtest';
import { displayMoney, isNone, isNotNone, toCSV } from 'utils/functions';
import { displayPercentage } from 'utils/rendering';

const ISINs = ['PE_ISIN_DIRECT', 'PD_ISIN_DIRECT', 'FS_ISIN_DIRECT', 'SCPI_ISIN_DIRECT'];

type OutputProps = {
	data: CrossAssetBacktestResponse;
	vehicleType: VehicleType;
};

const Output: FC<OutputProps> = ({ data, vehicleType }) => {
	const tableData = useMemo<{ id: string; value: string }[]>(
		() => [
			{ id: 'Période de backtest', value: data.backtestPeriod },
			{ id: 'Rendement total', value: `${(data.totalReturn * 100).toFixed(2)}%` },
			{ id: 'Rendement annualisé', value: `${(data.annualizedReturn * 100).toFixed(2)}%` },
			{ id: 'Volatilité annualisée', value: `${(data.annualizedVolatility * 100).toFixed(2)}%` },
			{ id: 'Rendement ajusté au risque', value: data.returnAdjustedToRisk.toFixed(2) },
			{ id: 'Risque global', value: data.globalRiskScore.toFixed(2) },
			{
				id: `Risque - ${vehicleType}`,
				value: isNotNone(data.vehiculeRiskScore) ? data.vehiculeRiskScore.toFixed(2) : '-',
			},
			{
				id: `Rendement annualisé - ${vehicleType}`,
				value: `${(data.vehiculeProjection.vehiculeAnnualizedReturn * 100).toFixed(2)}%`,
			},
			{ id: 'Maximum drawdown', value: `${(data.maxDrawdown * 100).toFixed(2)}%` },
			{ id: 'Value at risk - 5%', value: `${(data.var5pc * 100).toFixed(2)}%` },
			{ id: 'Conditional value at risk - 5%', value: `${(data.cvar5pc * 100).toFixed(2)}%` },
		],
		// eslint-disable-next-line prettier/prettier
		[data.annualizedReturn, data.annualizedVolatility, data.backtestPeriod, data.cvar5pc, data.globalRiskScore, data.maxDrawdown, data.returnAdjustedToRisk, data.totalReturn, data.var5pc, data.vehiculeProjection.vehiculeAnnualizedReturn, data.vehiculeRiskScore, vehicleType],
	);

	const [isinReplacements, setIsinReplacements] = useState<{ [key: string]: string }>(
		Object.fromEntries(ISINs.map((e) => [e, e])),
	);

	const labDataReplacedISINs = useMemo(
		() =>
			data.detailedPortfolio
				.map((e) => (ISINs.includes(e.label) ? { ...e, label: isinReplacements[e.label] ?? e } : e))
				.sort((a, b) => b.weight - a.weight),
		[data.detailedPortfolio, isinReplacements],
	);

	const [fsReplacement, setFsReplacement] = useState<string>('Fonds structurés');

	const generateGraphDataRepartition = useCallback(
		(dict: Record<string, number>) => {
			const modifiedFSAssetClass = fsReplacement === '' ? 'Fonds structurés' : fsReplacement;
			const result = Object.keys(dict).includes('Fonds structurés')
				? {
						...Object.fromEntries(Object.entries(dict).filter((x) => x[0] !== 'Fonds structurés')),
						[modifiedFSAssetClass]: dict['Fonds structurés'],
				  }
				: dict;
			return Object.entries(result).map(([label, value]) => ({ id: label, label, value }));
		},
		[fsReplacement],
	);

	const vehicleProjection = useMemo<{ id: string; data: { x: string; y: number }[] }[]>(
		() => {
			if (
				!data.vehiculeProjection ||
				isNone(data.vehiculeProjection.dates) ||
				data.vehiculeProjection.dates.length === 0
			) {
				return [];
			}

			return [
				{
					id: 'Capital avec dépôts',
					data: data.vehiculeProjection.dates.map((d, i) => ({
						x: d,
						y: data.vehiculeProjection.capitalWithDeposits[i],
					})),
				},
				{
					id: 'Capital sans dépôts',
					data: data.vehiculeProjection.dates.map((d, i) => ({
						x: d,
						y: data.vehiculeProjection.capitalWithoutDeposits[i],
					})),
				},
				{
					id: 'Épargne avec dépôts',
					data: data.vehiculeProjection.dates.map((d, i) => ({
						x: d,
						y: data.vehiculeProjection.epargneWithDeposits[i],
					})),
				},
				{
					id: 'Épargne sans dépôts',
					data: data.vehiculeProjection.dates.map((d, i) => ({
						x: d,
						y: data.vehiculeProjection.epargneWithoutDeposits[i],
					})),
				},
			];
		},
		// eslint-disable-next-line prettier/prettier
		[data],
	);

	const handleDownload = () => {
		if (!labDataReplacedISINs || labDataReplacedISINs.length === 0) return;

		const formatedData = labDataReplacedISINs.map((e) => ({
			...e,
			weight: (e.weight * 100).toFixed(2),
			montant: e.montant.toFixed(2),
		}));

		const csvData = toCSV(formatedData, { removeSpaces: false }).replaceAll(';', ',');
		fileDownload(csvData, 'extract', 'text/csv');
	};

	return (
		<VStack w="80%" spacing="24px">
			<Card align="center" w="100%" p="24px" gap="24px">
				<TimeChart
					hideLegend
					title="Backtest de la stratégie"
					xTitle="Date"
					yTitle="Montant (en €)"
					data={[
						{
							id: 'Backtest de la stratégie',
							data: data.backtestGraph.map((e) => ({ x: e.id, y: e.value })),
						},
					]}
				/>
				<TableGraph data={tableData} />
			</Card>

			<Card w="100%" p="24px" gap="24px" align="center">
				<TableContainer w="100%">
					<Table variant="simple" size="sm">
						<Thead style={{ backgroundColor: '#E7E7E7' }}>
							<Tr>
								<Th>ISIN</Th>
								<Th>Label</Th>
								<Th>Weight</Th>
								<Th>Montant</Th>
							</Tr>
						</Thead>
						<Tbody>
							{labDataReplacedISINs.map(({ label, isin, weight, montant }) => (
								<Tr key={isin}>
									<Td>{isin}</Td>
									<Td>{label}</Td>
									<Td isNumeric>{(weight * 100).toFixed(2)}</Td>
									<Td isNumeric>{displayMoney(montant)}</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
				<Button w="40%" onClick={handleDownload}>
					Télécharger (csv)
				</Button>
			</Card>

			<Card w="100%" p="24px" gap="24px">
				<GraphPie
					title="Portefeuille"
					data={Object.entries(labDataReplacedISINs).map(([id, value]) => ({
						id,
						label: value.label,
						value: value.weight,
					}))}
				/>
				<Wrap shouldWrapChildren>
					{ISINs.map((isin) => (
						<FormControl key={isin}>
							<FormLabel>Remplacer {isin}</FormLabel>
							<Input
								placeholder="ISIN"
								bg="white"
								onChange={(e) =>
									setIsinReplacements((pv) => ({ ...pv, [isin]: e.target.value === '' ? isin : e.target.value }))
								}
							/>
						</FormControl>
					))}
				</Wrap>
			</Card>

			<Card w="100%" p="24px">
				<GraphPie
					title="Répartition par classe d'actifs"
					data={generateGraphDataRepartition(data.assetClassRepartition)}
				/>
				<FormControl>
					<FormLabel>Remplacer Fonds structurés</FormLabel>
					<Input
						placeholder="Fonds structurés"
						bg="white"
						onChange={(e) => setFsReplacement(e.target.value === '' ? 'Fonds structurés' : e.target.value)}
					/>
				</FormControl>
			</Card>

			{isNotNone(data.ltvLombardInfo) && isNotNone(data.trackedIndexRepartition) && (
				<Card w="100%" p="24px">
					<VStack w="100%">
						<Text fontSize="22px">Répartition par indice suivi</Text>
						<GraphPie data={generateGraphDataRepartition(data.trackedIndexRepartition)} />
					</VStack>
					<VStack>
						<Text fontWeight="bold">Estimation LTV: {displayPercentage(data.ltvLombardInfo.effectiveLtvGlobal)}</Text>
						<TableContainer w="100%">
							<Table variant="simple" size="sm">
								<Thead style={{ backgroundColor: '#E7E7E7' }}>
									<Tr>
										<Th>ISIN</Th>
										<Th isNumeric>Estimated Effective LTV</Th>
									</Tr>
								</Thead>
								<Tbody>
									{Object.entries(data.ltvLombardInfo.effectiveLtvDict).map(([isin, estimatedEffectiveLtv]) => (
										<Tr key={isin}>
											<Td>{isin}</Td>
											<Td isNumeric>{displayPercentage(estimatedEffectiveLtv)}</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						</TableContainer>
					</VStack>
				</Card>
			)}

			<Card w="100%" p="24px">
				<TimeChart
					title="Projection du capital dans le véhicule d'investissement (avec et sans versements programmés)"
					data={vehicleProjection}
				/>
			</Card>
		</VStack>
	);
};

export default Output;
