import { dashboardApi } from 'store/api';
import { BankInformation } from 'types/bank-information.type';
import { ProductType, SubscriptionStatus } from 'types/global.type';
import { OfferPayment, PromotionOffer } from 'types/offer.type';
import { User } from 'types/user.type';

export interface GlobalSubscription {
	id: string;
	user: User;
	signedAt: Date;
	amount: number;
	status: SubscriptionStatus;
	productType: ProductType;
	productDetail: string;
}

export interface EligibleSubscriptionOffer extends GlobalSubscription {
	eligibleOffers: PromotionOffer[];
}

export interface OpsOfferSubscriptionPayment {
	id: string;
	subscription: GlobalSubscription;
	offer: PromotionOffer;
	payment: OfferPayment;
	hasBeenPaid: boolean;
	amount: number;
	date: string;
	reimbursementDeadline: string;
	offerReference: string;
	referenceId: string;
	bankAccount: BankInformation;
}

export interface OfferSystemFiltersDto {
	includeProductType?: ProductType[];
	includeOfferId?: string[];
	emailClient?: string;
	showPaid?: boolean;
}

export interface OfferSystemPaginationDto {
	page: number;
	limit: number;
}

interface CreateOfferPayment {
	productType: ProductType;
	subscriptionId: string;
	offerId: string;
	amountPaid: number;
	referenceId: string;
}

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getOffers: builder.query<PromotionOffer[], void>({
			query: () => ({
				url: `/bo/ops/offers`,
				method: 'GET',
			}),
			providesTags: ['OPS_OFFERS'],
		}),
		getOffersEligibleSubscriptions: builder.query<
			{ results: OpsOfferSubscriptionPayment[]; total: number },
			{
				filters?: OfferSystemFiltersDto;
				pagination?: OfferSystemPaginationDto;
			} | void
		>({
			query: (body) => ({
				url: `/bo/ops/offers/subscriptions`,
				method: 'POST',
				body,
			}),
			providesTags: ['OPS_OFFERS'],
		}),
		createOfferPayments: builder.mutation<void, { paymentOffers: CreateOfferPayment[] }>({
			query: (body) => ({
				url: `/bo/ops/offers/mark-as-paid`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['OPS_OFFERS'],
		}),
	}),
});

export const {
	useGetOffersQuery,
	useGetOffersEligibleSubscriptionsQuery,
	useLazyGetOffersEligibleSubscriptionsQuery,
	useCreateOfferPaymentsMutation,
} = endpoints;
