import { FC, useCallback } from 'react';
import { Divider, HStack, Skeleton, VStack } from '@chakra-ui/react';

import FilterPopovers from 'components/filters';
import SearchByFilter, { SearchBy } from 'components/filters/SearchBy';
import RecentClients from 'components/RecentClients';
import ClientsTable from 'components/tables/ClientsTable';
import useDelay from 'hooks/useDelay';
import { ClientSectionPossiblePath } from 'pages/ops/super/client';
import { useLazyGetClientsQuery, UserWithKycAndMoral } from 'services/client';

import useClientListFilters from './utils';

type ClientListProps = {
	selectedClient?: UserWithKycAndMoral;
	onClick?: (c: UserWithKycAndMoral) => void;
	pageSize?: number;
	customSearchBy?: SearchBy; // force filter at route level
	customInput?: string; // force filter at route level
	basePath?: ClientSectionPossiblePath;
};

const ClientList: FC<ClientListProps> = ({
	customInput,
	customSearchBy,
	onClick,
	pageSize,
	selectedClient,
	basePath,
}) => {
	const { input, searchBy, clientTypeFilter, setInput, setSearchBy, setClientTypeFilter, recentClients } =
		useClientListFilters(customSearchBy, customInput);

	const [getClients, { data: clients, isFetching: isClientsFetching }] = useLazyGetClientsQuery();

	useDelay(useCallback(() => getClients({ searchBy, input }, true), [getClients, input, searchBy]));

	return (
		<VStack w="100%" align="start" spacing={6}>
			<RecentClients basePath={basePath} recentClients={recentClients} />

			<Divider display={recentClients.length > 0 ? 'block' : 'none'} />

			<HStack w="100%">
				<SearchByFilter search={input} onChangeSearch={setInput} searchBy={searchBy} onChangeSearchBy={setSearchBy} />

				<FilterPopovers
					components={[
						{
							title: 'Client type',
							componentProps: {
								onChange: setClientTypeFilter,
								options: ['isBlack', 'isPhoenix', 'isCorporate'],
								value: clientTypeFilter,
							},
						},
					]}
				/>
			</HStack>

			<Skeleton isLoaded={!isClientsFetching} w="100%">
				<ClientsTable
					clients={(clients ?? []).filter((c) => {
						if (clientTypeFilter.length === 0) return true;
						return (
							(clientTypeFilter.includes('isBlack') && c.isBlack) ||
							(clientTypeFilter.includes('isPhoenix') && c.isPhoenix) ||
							(clientTypeFilter.includes('isCorporate') && c.isCorporate)
						);
					})}
					onClick={onClick}
					selectedClient={selectedClient}
					pageSize={pageSize ?? 50}
					basePath={basePath}
				/>
			</Skeleton>
		</VStack>
	);
};

export default ClientList;
