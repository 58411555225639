import { SearchBy } from 'components/filters/SearchBy';
import useLocalstorage from 'hooks/useLocalstorage';
import { RecentClient } from 'pages/ops/super/client/Client';

const initialState = {
	clientTypeFilter: [],
	input: '',
	searchBy: 'email' as const,
};

const useClientListFilters = (customSearchBy?: SearchBy, customInput?: string) => {
	const [input, setInput] = useLocalstorage(`CLIENT_SEARCH_INPUT`, customInput ?? initialState.input);
	const [searchBy, setSearchBy] = useLocalstorage<SearchBy>(
		`CLIENT_SEARCH_BY`,
		customSearchBy ?? initialState.searchBy,
	);
	const [clientTypeFilter, setClientTypeFilter] = useLocalstorage<string[]>(
		`CLIENT_TYPE_FILTER`,
		initialState.clientTypeFilter,
	);

	const [recentClients, setRecentClients] = useLocalstorage<RecentClient[]>(`RECENT_CLIENTS`, []);

	return {
		input,
		setInput,
		searchBy,
		setSearchBy,
		clientTypeFilter,
		setClientTypeFilter,
		recentClients,
		setRecentClients,
	};
};

export default useClientListFilters;
