import { dashboardApi } from 'store/api';
import { FamilySituation } from 'types/referentiels/familySituation';

//Notion Template

export type InvestmentMode = 'np' | 'pp';

type ScpiInvestment = {
	scpi: string;
	amount: number;
};

export type PrefGeographieType = 'Idf' | 'Etranger' | 'Régions';

interface GraphData {
	Année: string[];
	Dividende: number[];
}

type GeographicalRepartition = {
	label: PrefGeographieType;
	value: number;
};

export enum ScpiSecteurType {
	ETRANGER = 'Etranger',
	IDF = 'Idf',
	REGIONS = 'Régions',
	BUREAUX = 'Bureaux',
	COMMERCES = 'Commerces',
	HOTELS = 'Hôtels',
	LOCAUX_ACTIVITE = "Locaux d'activité",
	LOGISTIQUE = 'Logistique',
	RESIDENTIEL = 'Résidentiel',
	SANTE = 'Santé',
}

export enum SecteurPreference {
	AUTRE = 'Autre',
	BUREAUX = 'Bureaux',
	COMMERCES = 'Commerces',
	HOTELS = 'Hôtels',
	LOCAUX_ACTIVITE = "Locaux d'activité",
	LOGISTIQUE = 'Logistique',
	RESIDENTIEL = 'Résidentiel',
	SANTE = 'Santé',
}

type SectorielleRepartition = {
	label: SecteurPreference;
	value: number;
};

interface GraphDataValo {
	Année: string[];
	Valorisation: number[];
}

type RawRepartition = {
	montant: number;
	label: string;
};

interface RepartitionData {
	Géographique: GeographicalRepartition[];
	Sectorielle: SectorielleRepartition[];
}

interface SimulationStats {
	tri: number;
	TD_moyen: number;
	Reval_moyen: number;
	Frais_entré_global: number;
	Frais_entré_global_avec_remise: number;
	decote_moyen: number;
}

interface SimulationData {
	graph_data_div: GraphData;
	graph_data_divcum: GraphData;
	graph_data_valo: GraphDataValo;
	raw_rep: RawRepartition[];
	repartition_data: RepartitionData;
	stats: SimulationStats;
}

export interface NotionTemplateBody {
	mode: InvestmentMode;
	periodeDemembrement: number;
	firstName: string;
	lastName: string;
	timeHorizon: number;
	simulationData: SimulationData;
	initialAUM: number;
	prefGeographie: PrefGeographieType[];
	scpi_investments: ScpiInvestment[];
}

//SCPI Recommendation

export type ScpiDirectRecommendationBody = {
	investment_horizon: number;
	family_situation?: FamilySituation;
	number_children: number;
	initial_investment: number;
	investment_strategy: string;
	isISR: boolean;
	pref_geo: string[];
	pref_secteur: string[];
	personne_morale: boolean;
	maximizing_year?: number;
	reco_filter: boolean;
	retool_reco_filter: boolean;
	annual_net_revenue: number;
	has_filled_tax_form?: boolean;
	has_filled_tax_shares_form?: boolean;
};

export type ScpiRecommendationData = {
	parts_plein: number;
	parts_nue: number;
	montant_plein: number;
	montant_nu: number;
	id_airtable: string;
	decote_period: number;
};

export type LabSCPIRecommendationResponse = {
	allocation: {
		[scpiName: string]: ScpiRecommendationData;
	};
	remise: number | null;
	cost: number | null;
};

//SCPI Direct Simulation

type PortfolioAllocation = {
	id: string;
	number_of_shares: number;
	montant_investi: number;
	decote_period: number;
};

export type LabSCPIDirectSimulationBody = {
	investment_horizon: number;
	annual_net_revenue: number;
	number_children: number;
	family_situation: FamilySituation;
	personne_morale: boolean;
	retool_reco_filter: boolean;
	has_filled_tax_form: boolean;
	has_filled_tax_shares_form: boolean;
	portfolio_allocation: PortfolioAllocation[];
};

export type LabSCPIDirectSimulationResultStats = {
	montant_versé: number;
	capital_total_estimé: number;
	évolution_totale_capital: number;
	Gain: number;
	repartition_gain: {
		Dividends: number;
		Décote: number;
		Revalorisation: number;
	};
	taux_distribution_moyen_estimé: number;
	taux_revalorisation_annuel: number;
	'Frais d’entrées': number;
	'Décote moyenne': number;
	dividendes_annuelles_moyennes: number;
	gross_répartition_sectorielle: {
		secteurSanté: number;
		secteurAutre: number;
		secteurHôtels: number;
		secteurLocaux_activité: number;
		secteurLogistique: number;
		secteurBureaux: number;
		secteurCommerces: number;
		secteurRésidentiel: number;
	};
	gross_répartition_géographique: {
		geoEtranger: number;
		geoRégions: number;
	};
	tri: number;
	TD_moyen: number;
	Reval_moyen: number;
	Frais_entré_global: number;
	Frais_entré_global_avec_remise: number;
};

export type LabSCPIDirectSimulationResponse = {
	valo: {
		'valo net': Record<string, number>;
	};
	div: {
		'div net': Record<number, number>;
	};
	repartitons: {
		[key: string]: number;
	};
	stats: LabSCPIDirectSimulationResultStats;
};

export type LabSCPICreditSimulationResponse = {
	valo: {
		'valo net': Record<number, number>;
	};
	table: {
		valo_capital_net: Record<number, number>;
		dividendes_brutes: Record<number, number>;
		interet_annuel_paye: Record<number, number>;
		net_cashflow: Record<number, number>;
		principal_annuel_paye: Record<number, number>;
		tax_annuel_paye: Record<number, number>;
		tax_evite: Record<number, number>;
	};
	repartitons: {
		secteurSanté: number;
		secteurAutre: number;
		"secteurLocaux d'activité": number;
		geoEtranger: number;
		geoIdf: number;
		geoRégions: number;
		secteurBureaux: number;
		secteurCommerces: number;
		secteurLogistique: number;
		secteurRésidentiel: number;
	};
	stats: {
		'Montant investi': number;
		'Gain de capital estimé': number;
		'Évolution de la valeur des parts en %': number;
		'Frais d’entrées': number;
		'IRR avec remise et cout': number;
		'IRR hors remise et cout': number;
		'Taux de revalorisation annuel': number;
		'cout du crédit': number;
		'epargne effort max': number;
		'epargne effort moyen': number;
		mensualité: number;
		remise: number;
		'taux de distribution moyen estimé': number;
		'économie de taxe': number;
		TRI: number | null;
	};
};

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		createScpiDirectRecommendation: builder.mutation<LabSCPIRecommendationResponse, ScpiDirectRecommendationBody>({
			query: (data) => ({
				url: '/bo/cpm/propale-scpi-direct/recommendation',
				method: 'POST',
				body: data,
			}),
		}),
		createScpiDirectSimulation: builder.mutation<LabSCPIDirectSimulationResponse, LabSCPIDirectSimulationBody>({
			query: (data) => ({
				url: '/bo/cpm/propale-scpi-direct/simulation',
				method: 'POST',
				body: data,
			}),
		}),
		createScpiDirectTemplate: builder.mutation<{ link: string }, NotionTemplateBody>({
			query: (data) => ({
				url: '/bo/cpm/propale-scpi-direct/notion-link',
				method: 'POST',
				body: data,
			}),
		}),
	}),
});

export const {
	useCreateScpiDirectRecommendationMutation,
	useCreateScpiDirectSimulationMutation,
	useCreateScpiDirectTemplateMutation,
} = endpoints;
