import {
	AirtableAttachment,
	ImmoPeFundOrigin,
	ImmoPeObjectives,
	OpsProcess,
	TerminationReason,
} from 'types/airtable/global.airtable.type';
import { LegalEntity, SubscriptionStatus } from 'types/global.type';

export enum ScpiProjectType {
	PLEINE_PROPRIETE = 'PLEINE_PROPRIETE',
	NUE_PROPRIETE = 'NUE_PROPRIETE',
}

export enum ScpiDividentsDistributionFrequency {
	MONTHLY = 'MONTHLY',
	QUARTERLY = 'QUARTERLY',
}

type ScpiSubscriptionStatus =
	| SubscriptionStatus.REQUESTED
	| SubscriptionStatus.PENDING
	| SubscriptionStatus.KYC_VALIDATION
	| SubscriptionStatus.CONTRACT_SENT_TO_CLIENT
	| SubscriptionStatus.SIGNED
	| SubscriptionStatus.PARTNER_VALIDATION
	| SubscriptionStatus.PAYMENT
	| SubscriptionStatus.REGISTERED
	| SubscriptionStatus.COMPLETED
	| SubscriptionStatus.TERMINATED;

export type ScpiSubscription = {
	id: string;
	createdAt: string;
	updatedAt: string;

	userId: string;
	initialDepositAmount: string;
	nbShare: number;
	demembrementDuration?: number;
	amountSubscribed: string;
	dividendsDistributionFrequency: ScpiDividentsDistributionFrequency;
	fundsOrigin?: ImmoPeFundOrigin;
	objectives: ImmoPeObjectives[];
	projectType: ScpiProjectType;

	lastShareValuation: string;
	lastValuationDate: string;

	effectiveDate?: string;

	effectiveDateQISComputed?: string;
	performanceIsComputed?: boolean;
	dashboardIsComputed?: boolean;

	isWithLoan: boolean;

	legalEntity: LegalEntity;
	moralPersonId?: string;
	bankInformationId?: string;

	provider: string;
	airtableFundId: string;
	fundName: string;
	legacyAirtableId?: string;
	process: OpsProcess;

	status: ScpiSubscriptionStatus;
	requestedAt?: string;
	pendingAt?: string;
	kycValidationAt?: string;
	contractSentToClientAt?: string;
	signedAt?: string;
	partnerValidationAt?: string;
	paymentAt?: string;
	registeredAt?: string;
	completedAt?: string;
	terminatedAt?: string;

	terminationReason?: TerminationReason;

	opsPropertiesId?: string;
	hubspotId?: string;
};

export type ScpiFund = {
	id: string;
	name: string;
	description: string;
	fundOpen: 'Oui' | 'Non';
	partner: string;
	sectors: string[];
	regions: string[];
	fundCover: AirtableAttachment[];
	fundDICI?: AirtableAttachment[];
	statuts?: AirtableAttachment[];
	fundFlyer?: AirtableAttachment[];
	process: OpsProcess;
	typeformUrl: string;
	ticket: number;
	show: boolean;
	templateBulletin: AirtableAttachment[];
	templateSurvey: AirtableAttachment[];
	templateDemembrement: AirtableAttachment[];
	dividendsDistributionFrequency: ScpiDividentsDistributionFrequency;
	'Email - MO/BO'?: string;
	startDate: string;
	tags: string[];
	isCapitalVariable: string;
	tenurePeriod: string;
	distributionRate: number;
	expectedDistributionRate: number;
	label: string;
	lastShareValuation: number;
	minPPShares: number;
	minNPShares: number;
	remisePercentage: number;
	meanRevalorisation: number;
	q2Reporting2024?: string;
	q3Reporting2024?: string;
	q4Reporting?: string;
	ramifyOpinion?: string;
	recommendedInvestmentHorizon?: number;
	reconstitutionPricePerShare?: number;

	includeInRecommandation: boolean;
	includeInRetoolRecommandation: boolean;

	remRecurring?: number;
	remRecurringYearsNumber?: number;
	remUpFront?: number;
	entranceFees?: number;

	reportTrimestrielSCPI?: string[];
	saveOnWebflow?: boolean;
	tableauDeDemembrement?: string[];
	universCredit?: string[];
};

export type ScpiFundPatrimoine = {
	id: string;
	fundId: string;

	secteurSanté: number;
	secteurRésidentiel: number;
	secteurCommerces: number;
	secteurBureaux: number;
	secteurLogistique: number;
	"secteurLocaux d'activité": number;
	secteurHôtels: number;
	secteurAutre: number;

	geoIdf: number;
	geoEtranger: number;
	geoRégions: number;
};

export type ScpiFundDemembrement = {
	id: number;
	fundId: string;

	[duration: number]: number;
};

export type ScpiFundHistory = {
	id: string;
	fundId: string;

	date: string;
	prix: number;
	dividende?: number;
	reconstitutionPrice?: number;
	aum?: number;
	numberOfAssets?: number;
	financialOccupancyRate?: number;
};
