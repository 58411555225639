import { FiltersContractsRebalancing, LabHoldings, LabTurnover } from 'services/rebalancing-contrat';
import { dashboardApi } from 'store/api';
import { ContractVpRebalancing } from 'types/invest-deal.type';

type ContractIdDto = {
	contractId: string;
};

export interface LabVpArbitrageResponse {
	turnover: number;
	turnover_euro: number;
	last_refresh_date: string;
	turnover_basic_view_dict: LabTurnover;
	turnover_basic_view_euro: number;
	current_allocation_euro: LabTurnover;
	current_allocation_weights: LabHoldings;
	recommended_allocation_euro: LabTurnover;
	recommended_allocation_weights: LabHoldings;
	turnover_asset_class_view_dict: LabTurnover;
	turnover_asset_class_view_euro: number;
	turnover_asset_category_view_dict: LabTurnover;
	turnover_asset_category_view_euro: number;
}

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getVPsToRebalance: builder.query<
			ContractVpRebalancing[],
			FiltersContractsRebalancing & { modifiedOnly?: boolean; lastUpdated?: number }
		>({
			query: (data) => ({
				url: '/bo/qis/vps-to-rebalance',
				params: data,
				method: 'GET',
			}),
			providesTags: ['VP_REBALANCE'],
		}),
		recomputeVpRebalancing: builder.mutation<LabVpArbitrageResponse, ContractIdDto & { saveRecompute: boolean }>({
			query: (data) => ({
				url: '/bo/qis/compute-vp-rebalancing',
				params: data,
				method: 'PUT',
			}),
			invalidatesTags: ['CONTRACT_REBALANCE'],
		}),
		applyVpRebalancing: builder.mutation<unknown, ContractIdDto>({
			query: (data) => ({
				url: '/bo/qis/apply-vp-rebalancing',
				body: data,
				method: 'POST',
			}),
			invalidatesTags: ['VP_REBALANCE'],
		}),
		rebalanceVpBatch: builder.mutation<void, string[]>({
			query: (data) => ({
				url: '/bo/qis/apply-batch-vp-rebalancing',
				body: data,
				method: 'POST',
			}),
			invalidatesTags: ['VP_REBALANCE'],
		}),
	}),
});

export const {
	useApplyVpRebalancingMutation,
	useGetVPsToRebalanceQuery,
	useRecomputeVpRebalancingMutation,
	useRebalanceVpBatchMutation,
} = endpoints;
