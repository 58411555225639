import { useMemo } from 'react';

import { AllDeal } from 'services/deal';
import { useGetStatusOptionsQuery } from 'services/ops/status';
import { SubscriptionStatus } from 'types/global.type';
import { getProductTypeKey, isNone } from 'utils/functions';

const useDealStatus = (deal: AllDeal) => {
	const { data: statusOptions } = useGetStatusOptionsQuery();

	const dealStatusOptions = useMemo<SubscriptionStatus[]>(() => {
		if (isNone(statusOptions)) return [];

		const productTypeKey = getProductTypeKey(deal, Object.keys(statusOptions));
		const options = statusOptions[productTypeKey];

		// if deal has a status and it's not in the options, add it to the list
		if (deal.status && !options.includes(deal.status)) return [deal.status, ...options];
		return options;
	}, [deal, statusOptions]);

	const nextStatus = useMemo<SubscriptionStatus | null>(() => {
		const index = dealStatusOptions.indexOf(deal.status);
		if (index === dealStatusOptions.length - 1) return null;
		return dealStatusOptions.at(dealStatusOptions.indexOf(deal.status) + 1) ?? null;
	}, [deal, dealStatusOptions]);

	return { dealStatusOptions, nextStatus };
};

export default useDealStatus;
