import { FC, useMemo, useState } from 'react';
import { Badge, Button, HStack, Text, VStack } from '@chakra-ui/react';

import CustomModal from 'components/Modal';
import { AllDeal, useUpdateDealMutation } from 'services/deal';
import { ProductType } from 'types/global.type';

import BankAccountList from '.';

type LinkBankAccountModalProps = {
	deal: AllDeal;
	isOpen: boolean;
	onClose: () => void;
};

const LinkBankAccountModal: FC<LinkBankAccountModalProps> = ({ deal, isOpen, onClose }) => {
	const [select, setSelect] = useState<string | null>(
		'bankInformationId' in deal ? (deal.bankInformationId as string) : null,
	);

	const [updateDeal] = useUpdateDealMutation();

	const isBankAccountIdDirty = useMemo(() => {
		if (!select) return false;
		return 'bankInformationId' in deal ? deal.bankInformationId !== select : true;
	}, [deal, select]);

	return (
		<CustomModal
			size="6xl"
			headersProps={{ children: <Text fontSize="18px">Associer un compte bancaire</Text> }}
			isOpen={isOpen}
			onClose={onClose}
		>
			<VStack align="start" spacing="12px">
				<Text color="gray" fontSize="12px">
					Cet outil sert à modifier l'affichage du compte bancaire dans l'app. Il ne modifie aucunement le compte à
					prélever pour les paiements le cas échéant.
				</Text>
				{deal.productType === ProductType.INVEST && (
					<HStack>
						<Badge colorScheme="red">Attention</Badge>
						<Text color="red.800" fontSize="12px">
							Pour les deals INVEST, il est nécessaire d'également effectuer le changement auprès du partenaire !
						</Text>
					</HStack>
				)}
				<BankAccountList selectedBankId={select} onSelect={(v: string) => setSelect(v)} email={deal.user.email} />
				<Button
					colorScheme="blue"
					isDisabled={!isBankAccountIdDirty || !select}
					onClick={() => {
						if (isBankAccountIdDirty && select)
							updateDeal({
								id: deal.id,
								productType: deal.productType,
								bankAccountId: select,
								properties: {
									id: deal.opsPropertiesId,
								},
							});
						onClose();
					}}
				>
					Modifier
				</Button>
			</VStack>
		</CustomModal>
	);
};

export default LinkBankAccountModal;
