import { PortfolioType } from 'types/investmentPreferences.type';

export enum GreenType {
	GREEN = 'GREEN',
	NOTGREEN = 'NOTGREEN',
	BOTH = 'BOTH',
}

export const defaultPortfolioTypeValues: Record<PortfolioType, boolean> = {
	ESSENTIAL: true,
	FLAGSHIP: true,
	CUSTOM: true,
	ELITE: true,
	TITANIUM: true,
};
