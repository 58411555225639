import { useMemo, useState } from 'react';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	HStack,
	Input,
	Menu,
	MenuButton,
	MenuItemOption,
	MenuList,
	MenuOptionGroup,
	Table,
	TableContainer,
	Tbody,
	Text,
	Thead,
	Tr,
} from '@chakra-ui/react';

import { DefaultTd } from 'components/tables/columns/Td';
import { DefaultTh } from 'components/tables/columns/Th';
import { ContractVpRebalancing } from 'types/invest-deal.type';
import { displayMoney, isNotNone } from 'utils/functions';

type ContractsTableProps = {
	contracts: ContractVpRebalancing[];
	pageSize: number;
	selectedContract?: ContractVpRebalancing;
	onClick: (c: ContractVpRebalancing) => void;
};

const sortFields = (a: ContractVpRebalancing, b: ContractVpRebalancing, orderBy: string, order: 'asc' | 'desc') => {
	if (orderBy === 'rebalancedAt') {
		if (order === 'asc')
			return (
				new Date(a.programmedVersement.rebalancedAt ?? 0).getTime() -
				new Date(b.programmedVersement.rebalancedAt ?? 0).getTime()
			);
		else
			return (
				new Date(b.programmedVersement.rebalancedAt ?? 0).getTime() -
				new Date(a.programmedVersement.rebalancedAt ?? 0).getTime()
			);
	}
	if (orderBy === 'turnoverRecoCurrent') {
		if (order === 'asc') return (a.turnoverRecoCurrent ?? 0) - (b.turnoverRecoCurrent ?? 0);
		else return (b.turnoverRecoCurrent ?? 0) - (a.turnoverRecoCurrent ?? 0);
	}
	if (orderBy === 'turnoverSentCurrent') {
		if (order === 'asc') return (a.turnoverSentCurrent ?? 0) - (b.turnoverSentCurrent ?? 0);
		else return (b.turnoverSentCurrent ?? 0) - (a.turnoverSentCurrent ?? 0);
	}
	if (orderBy === 'email') {
		const emailA = a.user?.email ?? '';
		const emailB = b.user?.email ?? '';
		return order === 'asc' ? emailA.localeCompare(emailB) : emailB.localeCompare(emailA);
	}
	if (orderBy === 'name') {
		const lastNameA = a.user?.kyc?.lastName ?? '';
		const lastNameB = b.user?.kyc?.lastName ?? '';
		return order === 'asc' ? lastNameA.localeCompare(lastNameB) : lastNameB.localeCompare(lastNameA);
	}
	return 0;
};

const RebalancingVPsTable = ({ contracts, pageSize, selectedContract, onClick }: ContractsTableProps) => {
	const [pageIndex, setPageIndex] = useState<number>(0);
	const [searchBy, setSearchBy] = useState<string>('name');
	const [input, setInput] = useState<string>('');
	const [sortBy, setSortBy] = useState<string | null>(null);
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

	const handleSort = (column: string) => {
		if (sortBy === column) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortBy(column);
			setSortDirection('asc');
		}
	};

	const filteredContracts = useMemo(
		() =>
			contracts.filter((c) =>
				(searchBy === 'email' ? c.user?.email ?? '' : c.user?.kyc?.lastName ?? '')
					.toLocaleLowerCase()
					.includes(input.toLowerCase()),
			),
		[contracts, input, searchBy],
	);

	const getSortIcon = (column: string) => {
		if (sortBy === column) {
			return sortDirection === 'asc' ? (
				<ChevronUpIcon boxSize="24px" bg="gray.100" cursor="pointer" />
			) : (
				<ChevronDownIcon boxSize="24px" bg="gray.100" cursor="pointer" />
			);
		}
		return null;
	};

	return (
		<Box>
			<HStack>
				<HStack spacing={4} mb={4}>
					<Input
						w="500px"
						placeholder={searchBy === 'name' ? 'Nom' : 'Email'}
						value={input}
						onChange={(v) => setInput(v.target.value)}
					/>
					<Menu closeOnSelect={false}>
						<MenuButton as={Button}>Chercher par</MenuButton>
						<MenuList minWidth="240px">
							<MenuOptionGroup value={searchBy} onChange={(v) => setSearchBy(v as string)} type="radio">
								<MenuItemOption value="name">Nom</MenuItemOption>
								<MenuItemOption value="email">Email</MenuItemOption>
							</MenuOptionGroup>
						</MenuList>
					</Menu>
				</HStack>

				<Box w="100%" textAlign="right" mb="16px" fontWeight="semibold" userSelect="none">
					<Text as="span" color="gray.600">
						Show {pageIndex + 1} to {pageIndex + pageSize} of {filteredContracts.length} entries{' '}
					</Text>
					<ChevronLeftIcon
						mx="16px"
						boxSize="24px"
						bg="gray.100"
						cursor="pointer"
						onClick={() =>
							setPageIndex(
								pageIndex === 0 ? Math.floor(filteredContracts.length / pageSize) * 10 : pageIndex - pageSize,
							)
						}
					/>
					<ChevronRightIcon
						boxSize="24px"
						bg="gray.100"
						cursor="pointer"
						onClick={() =>
							setPageIndex(pageIndex + 1 * pageSize >= filteredContracts.length ? 0 : pageIndex + pageSize)
						}
					/>
				</Box>
			</HStack>

			<TableContainer>
				<Table variant="simple" size="sm">
					<Thead>
						<Tr>
							<DefaultTh onClick={() => handleSort('email')}>Email {getSortIcon('email')}</DefaultTh>
							<DefaultTh onClick={() => handleSort('name')}>Nom {getSortIcon('name')}</DefaultTh>
							<DefaultTh>Prénom</DefaultTh>
							<DefaultTh>Contract Amount</DefaultTh>
							<DefaultTh>VP Amount</DefaultTh>
							<DefaultTh onClick={() => handleSort('rebalancedAt')}>
								Last Rebalance {getSortIcon('rebalancedAt')}
							</DefaultTh>
							<DefaultTh onClick={() => handleSort('turnoverRecoCurrent')}>
								Turnover (reco | current) {getSortIcon('turnoverRecoCurrent')}
							</DefaultTh>
							<DefaultTh onClick={() => handleSort('turnoverSentCurrent')}>
								Turnover (sent | current) {getSortIcon('turnoverSentCurrent')}
							</DefaultTh>
							<DefaultTh>Apicil ID</DefaultTh>
						</Tr>
					</Thead>
					<Tbody>
						{filteredContracts
							.sort((a, b) => sortFields(a, b, sortBy ?? '', sortDirection))
							.slice(pageIndex, pageIndex + pageSize)
							.map((contract) => {
								const vp = contract.programmedVersement;

								return (
									<Tr
										key={contract.id}
										onClick={() => onClick(contract)}
										cursor="pointer"
										bg={selectedContract?.id === contract.id ? 'blue.50' : 'white'}
									>
										<DefaultTd>{contract.user?.email}</DefaultTd>
										<DefaultTd>{contract.user?.kyc?.lastName}</DefaultTd>
										<DefaultTd>{contract.user?.kyc?.firstName}</DefaultTd>
										<DefaultTd>{contract.amount ? displayMoney(contract.amount) : null}</DefaultTd>
										<DefaultTd>{vp.amount ? displayMoney(vp.amount) : null}</DefaultTd>
										<DefaultTd>{formatDate(vp.rebalancedAt)}</DefaultTd>
										<DefaultTd>
											{isNotNone(contract.turnoverRecoCurrent)
												? `${(contract.turnoverRecoCurrent * 100).toFixed(2)}%`
												: null}
										</DefaultTd>
										<DefaultTd>
											{isNotNone(contract.turnoverSentCurrent)
												? `${(contract.turnoverSentCurrent * 100).toFixed(2)}%`
												: null}
										</DefaultTd>
										<DefaultTd>{contract.externalProviderId}</DefaultTd>
									</Tr>
								);
							})}
					</Tbody>
				</Table>
			</TableContainer>
		</Box>
	);
};

const formatDate = (date: string | null) => {
	if (!date) return '';
	return new Date(date).toLocaleDateString();
};

export default RebalancingVPsTable;
