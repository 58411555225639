import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, chakra, FormControl, FormLabel, Input, Text, Textarea, VStack } from '@chakra-ui/react';

import CustomModal from 'components/Modal';
import useThemedToast from 'hooks/useThemedToast';
import { useResetPartnerAdequationMutation } from 'services/ops/crypto';

const defaultContent = `Bonjour,

Nous vous revenons ce jour au sujet de votre souscription à l'offre Crypto Ramify : lors de la vérification d'identité, vous avez fourni une pièce d'identité n'ayant pas été acceptée par notre partenaire.

Afin de valider votre identité, il sera malheureusement nécessaire d'effectuer de nouveau cette étape. Assurez vous bien que : 
- La pièce d'identité est bien en cours de validité 
- La capture réalisée est bien nette

Vous pouvez vérifier votre identité depuis <a href='https://app.ramify.fr/crypto/onboarding/verification/agreement'>cet onglet</a> de votre espace investisseur.

Nous restons à votre disposition pour toute information complémentaire,
							
L'équipe Ramify`;

type ResetFlowForm = {
	email: string;
	content: string;
};

type CryptoPartnerFlowResetModalProps = {
	userEmail: string;
	isOpen: boolean;
	onClose: () => void;
};

const CryptoPartnerFlowResetModal: FC<CryptoPartnerFlowResetModalProps> = ({ userEmail, isOpen, onClose }) => {
	const toast = useThemedToast();

	const {
		control,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm<ResetFlowForm>({
		defaultValues: {
			email: userEmail,
			content: defaultContent,
		},
	});

	const [triggerResetAdequation, { isLoading: isLoadingResetAdequation }] = useResetPartnerAdequationMutation();

	const onSubmit = (data: ResetFlowForm) => {
		triggerResetAdequation({
			email: userEmail,
			content: data.content,
		})
			.unwrap()
			.then(() => {
				toast({
					title: 'Succès',
					description: 'La réinitialisation a bien été prise en compte',
					status: 'success',
				});
				onClose();
				reset();
			})
			.catch(() => {
				toast({
					title: 'Erreur',
					description: 'Une erreur est survenue lors de la réinitialisation',
					status: 'error',
				});
			});
	};

	return (
		<CustomModal
			size="3xl"
			isOpen={isOpen}
			onClose={onClose}
			headersProps={{
				children: (
					<Text fontSize="24px" fontWeight="bold">
						Réinitialiser le flow Onfido
					</Text>
				),
			}}
			footerProps={{
				children: (
					<chakra.form onSubmit={handleSubmit(onSubmit)}>
						<Button colorScheme="blue" type="submit" isLoading={isLoadingResetAdequation}>
							Envoyer et réinitialiser
						</Button>
					</chakra.form>
				),
			}}
		>
			<VStack w="100%">
				<FormControl>
					<FormLabel>Email</FormLabel>
					<Input isDisabled type="email" value={userEmail} isReadOnly />
				</FormControl>
				<FormControl isInvalid={!!errors.content}>
					<FormLabel>Contenu du mail</FormLabel>
					<Controller
						rules={{ required: 'Ce champ est requis' }}
						name="content"
						control={control}
						render={({ field }) => (
							<Textarea
								{...field}
								borderBottomRadius="0px"
								borderTopRadius="0px"
								minH="420px"
								placeholder="Raison de la réinitialisation"
							/>
						)}
					/>
					{errors.content && <Text color="red.500">{errors.content.message}</Text>}
				</FormControl>
			</VStack>
		</CustomModal>
	);
};

export default CryptoPartnerFlowResetModal;
