import { RamifyDoc } from 'services/document';
import { EditableOpsPropertiesFields } from 'services/ops/properties';
import { OpsAttachment } from 'services/subscription';
import { dashboardApi } from 'store/api';
import {
	BlockingInstance,
	BlockingInstanceDocument,
	BlockingInstanceLifecycle,
	BlockingInstanceStatus,
} from 'types/blocking-instance.type';
import { ProductType } from 'types/global.type';
import { Kyc } from 'types/kyc.type';
import { User } from 'types/user.type';
import { DocumentName } from 'utils/documentNamingMap';

export type BlockingInstanceWithDoc = BlockingInstance & { missingDocuments: BlockingInstanceDocument[] };
export type BlockingInstanceWithKyc = BlockingInstanceWithDoc & { user: User & { kyc: Kyc } };

export type InstanceDocumentData = { documentName: DocumentName; comment: string };

type CreateBlockingInstance = {
	email?: string;
	product: ProductType;
	lifecycle: BlockingInstanceLifecycle;
	status?: BlockingInstanceStatus;
	partner: string;
	message?: string;
	mailId?: string;
	mailSubject?: string;
	mailBody?: string;
	mailCC?: string[];
	missingDocuments?: InstanceDocumentData[];
	noMissingDocuments: boolean;
	linkedEntityId?: string;
	properties: EditableOpsPropertiesFields;
};

type UpdateBlockingInstance = {
	id: string;
	status?: string;
	message?: string;
	missingDocuments?: UpdateMissingDocument[];
	properties: EditableOpsPropertiesFields;
};

type MatchBlockingInstance = {
	id: string;
	email: string;
	message?: string;
	missingDocuments?: InstanceDocumentData[];
	linkedEntityId: string;
	productType: ProductType;
	noMissingDocuments: boolean;
	properties?: EditableOpsPropertiesFields;
};

type ReviewBlockingInstance = {
	id: string;
	message: string;
	isValid: boolean;
	invalidDocumentsId?: string[];
	attachments?: OpsAttachment[];
	partnerEmail?: string;
	noMissingDocuments: boolean;
};

type ContextNoteReply = {
	id: string;
	message: string;
};

export type MissingDocumentToReview = RamifyDoc & {
	id: string;
	submitted: boolean;
};

export type UpdateMissingDocument = {
	id?: string;
	documentName: DocumentName;
	submitted: boolean;
};

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getBlockingInstances: builder.query<BlockingInstanceWithKyc[], { searchBy: string; input: string | undefined }>({
			query: (queryParams) => ({
				url: '/bo/ops/blocking-instances',
				params: queryParams,
				method: 'GET',
			}),
			providesTags: ['OPS_BLOCKING_INSTANCE'],
		}),
		getBlockingInstanceById: builder.query<BlockingInstanceWithKyc, { id: string }>({
			query: ({ id }) => ({
				url: `/bo/ops/blocking-instances/${id}`,
				method: 'GET',
			}),
			providesTags: (res) => [
				{ type: 'OPS_BLOCKING_INSTANCE', id: res?.id },
				{ type: 'OPS_PROPERTIES', id: res?.opsPropertiesId },
			],
		}),
		createBlockingInstances: builder.mutation<BlockingInstanceWithKyc, CreateBlockingInstance>({
			query: (body) => ({
				url: `/bo/ops/blocking-instances`,
				body,
				method: 'POST',
			}),
			invalidatesTags: ['OPS_BLOCKING_INSTANCE'],
		}),
		removeBlockingInstanceRelances: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `/bo/ops/blocking-instances/${id}/remove-relances`,
				method: 'PUT',
			}),
		}),
		matchBlockingInstances: builder.mutation<BlockingInstanceWithKyc, MatchBlockingInstance>({
			query: ({ id, ...body }) => ({
				url: `/bo/ops/blocking-instances/match/${id}`,
				body,
				method: 'PUT',
			}),
			invalidatesTags: (res) => [
				'OPS_BLOCKING_INSTANCE_DOCUMENT',
				{ type: 'OPS_BLOCKING_INSTANCE', id: res?.id },
				{ type: 'OPS_PROPERTIES', id: res?.opsPropertiesId },
			],
		}),
		updateBlockingInstances: builder.mutation<BlockingInstanceWithKyc, UpdateBlockingInstance>({
			query: ({ id, ...body }) => ({
				url: `/bo/ops/blocking-instances/update/${id}`,
				body,
				method: 'PUT',
			}),
			invalidatesTags: (res) => [
				'OPS_BLOCKING_INSTANCE_DOCUMENT',
				{ type: 'OPS_BLOCKING_INSTANCE', id: res?.id },
				{ type: 'OPS_PROPERTIES', id: res?.opsPropertiesId },
			],
		}),
		reviewBlockingInstances: builder.mutation<void, ReviewBlockingInstance>({
			query: ({ id, ...body }) => ({
				url: `/bo/ops/blocking-instances/review/${id}`,
				body,
				method: 'PUT',
			}),
			invalidatesTags: ['OPS_BLOCKING_INSTANCE'],
		}),
		contextNoteReply: builder.mutation<void, ContextNoteReply>({
			query: ({ id, ...body }) => ({
				url: `/bo/ops/blocking-instances/context-note/${id}`,
				body,
				method: 'PUT',
			}),
			invalidatesTags: ['OPS_BLOCKING_INSTANCE'],
		}),
		getBlockingInstanceDocuments: builder.query<MissingDocumentToReview[], { id: string }>({
			query: ({ id }) => ({
				url: `/bo/ops/blocking-instances/documents/${id}`,
				method: 'GET',
			}),
			providesTags: ['OPS_BLOCKING_INSTANCE_DOCUMENT'],
		}),
		deleteBlockingInstance: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `/bo/ops/blocking-instances/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['OPS_BLOCKING_INSTANCE'],
		}),
	}),
});

export const {
	useGetBlockingInstancesQuery,
	useLazyGetBlockingInstancesQuery,
	useGetBlockingInstanceByIdQuery,
	useLazyGetBlockingInstanceByIdQuery,
	useCreateBlockingInstancesMutation,
	useMatchBlockingInstancesMutation,
	useUpdateBlockingInstancesMutation,
	useReviewBlockingInstancesMutation,
	useContextNoteReplyMutation,
	useGetBlockingInstanceDocumentsQuery,
	useDeleteBlockingInstanceMutation,
	useRemoveBlockingInstanceRelancesMutation,
} = endpoints;
