import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Box,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Button,
	Center,
	Heading,
	HStack,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Skeleton,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import Cardlayout from 'components/CardLayout';
import { CommentsSection, useOpsComments } from 'components/Comment';
import HistorySection from 'components/History';
import { ChangeDealOverdueDate } from 'features/ChangeOverdueDate';
import DocumentsList from 'features/DocumentsList';
import DealBoInfo from 'features/NextStatusModal/DealBoInfo';
import UserDataTable from 'features/UserDataTable';
import useFund from 'hooks/useFund';
import { useNextStatusModal } from 'hooks/useNextStatusModal';
import useThemedToast from 'hooks/useThemedToast';
import CryptoInspector from 'pages/ops/super/crypto/CryptoInspector';
import { useGetClientKYCQuery } from 'services/client';
import {
	AllDeal,
	OpsInvestDeal,
	useDeleteDealMutation,
	useGetDealByIdQuery,
	useUpdateDealMutation,
} from 'services/deal';
import { RamifyDoc } from 'services/document';
import { useGetStatusesQuery } from 'services/ops/status';
import { useDeleteTransferMutation, useGetTransferByIdQuery } from 'services/ops/transfer';
import { LegalEntity, ProductType, SubscriptionStatus } from 'types/global.type';
import { SavingsAccountProvider } from 'types/savingsAccount.type';
import { getDealAmount } from 'utils/dealInfo';
import { displayMoney, isNone, isNotNone } from 'utils/functions';
import { PermissionDisplayGuard } from 'utils/guards';
import { BoPermission } from 'utils/permissions';

import SubscriptionSendApi, { ApiPartner } from './sender/SubscriptionSendApi';
import SubscriptionSendMail from './sender/SubscriptionSendMail';

const transferMailBody = `Bonjour,

Vous trouverez le bulletin de transfert du client cité en objet en pièce jointe.
Merci de me confirmer la bonne réception et traitement du document.

Bien à vous`;

const shouldDisplayNextButton = (subscription: AllDeal | undefined) => {
	if (isNone(subscription)) return false;

	if (subscription.status === SubscriptionStatus.COMPLETED) return false;

	if (subscription.productType === ProductType.GIRARDIN) return true;
	if (subscription.productType === ProductType.AV_LUX) return true;
	if (subscription.productType === ProductType.CRYPTO) return false;

	if (
		subscription.productType === ProductType.PE ||
		subscription.productType === ProductType.SCPI ||
		subscription.productType === ProductType.CROWDFUNDING ||
		subscription.productType === ProductType.ART
	) {
		// only show for PM SCPI if not Remake or Novaxia
		if (subscription.productType === ProductType.SCPI && subscription.legalEntity === LegalEntity.MORAL) {
			if (subscription.partner === 'Remake' || subscription.partner === 'Novaxia') return false;
			return true;
		}

		// only show for PM PE if not Peqan
		if (subscription.productType === ProductType.PE && subscription.legalEntity === LegalEntity.MORAL) {
			if (subscription.partner === 'Peqan') return false;
			return true;
		}

		// only show for crowdfundings if not Anaxago
		if (subscription.productType === ProductType.CROWDFUNDING && subscription.partner === 'Anaxago') return false;

		// otherwise, show based on process
		if (subscription.process === 'extranet' || subscription.process === 'paper') return true;
	}

	return false;
};

const ProcessModalButton = ({ deal }: { deal: AllDeal | undefined }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	if (!deal) return null;

	return (
		<>
			<Button colorScheme="blue" onClick={onOpen}>
				Process
			</Button>

			{isOpen && (
				<AlertDialog
					size="3xl"
					isOpen={isOpen}
					onClose={onClose}
					header="Process "
					body={<DealBoInfo deal={deal} />}
					footer={null}
				/>
			)}
		</>
	);
};

const useFindDealOrTransfer = (productType: ProductType | 'TRANSFER') => {
	const { id } = useParams<{ id: string }>();

	const {
		data: possibleSubscription,
		isFetching: isSubscriptionFetching,
		isLoading: isSubscriptionLoading,
	} = useGetDealByIdQuery(
		{ id: id ?? '', productType: productType as ProductType },
		{ skip: !isNotNone(id) || !isNotNone(productType) || productType === 'TRANSFER' },
	);
	const {
		data: possibleTransfer,
		isFetching: isTransferFetching,
		isLoading: isTransferLoading,
	} = useGetTransferByIdQuery(
		{ id: id ?? '' },
		{ skip: !isNotNone(id) || !isNotNone(productType) || productType !== 'TRANSFER' },
	);

	return useMemo(
		() => ({
			data: (possibleSubscription ?? possibleTransfer ?? undefined) as AllDeal | undefined,
			isFetching: isSubscriptionFetching || isTransferFetching,
			isLoading: isSubscriptionLoading || isTransferLoading,
		}),
		[isSubscriptionFetching, isSubscriptionLoading, isTransferFetching, isTransferLoading, possibleSubscription, possibleTransfer], // prettier-ignore
	);
};

export const SubscriptionDetails = () => {
	const navigate = useNavigate();
	const toast = useThemedToast();
	const location = useLocation();
	const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
	const productType = useMemo(() => queryParams.get('productType') as ProductType | 'TRANSFER', [queryParams]);

	const { data: subscription, isLoading: isSubscriptionLoading } = useFindDealOrTransfer(productType);
	const { data: kyc } = useGetClientKYCQuery({ email: subscription?.user.email ?? '' }, { skip: isNone(subscription) });
	const { data: statuses } = useGetStatusesQuery();
	const [deleteDeal, { isLoading: isDeleteDealLoading }] = useDeleteDealMutation();
	const [deleteTransfer, { isLoading: isDeleteTransferLoading }] = useDeleteTransferMutation();
	const [updateDeal, { isLoading: isUpdateLoading }] = useUpdateDealMutation();

	const fund = useFund(subscription, subscription?.status !== SubscriptionStatus.SIGNED);
	const comments = useOpsComments();
	const { NextStatusModal, handleOpenNextStatusModal } = useNextStatusModal();
	const { isOpen: isDeleteDialogOpen, onOpen: openDeleteDialog, onClose: closeDeleteDialog } = useDisclosure();
	const opsProperties = useMemo(() => subscription?.opsProperties, [subscription?.opsProperties]);

	const [selectedDocuments, setSelectedDocuments] = useState<RamifyDoc[]>([]);
	const [validationInput, setValidationInput] = useState<string>('');
	const [partnerEmail, setParterEmail] = useState<string>('');

	const showNextStatusbutton = useMemo(() => shouldDisplayNextButton(subscription), [subscription]);

	useEffect(() => {
		if (!subscription || !fund) return;
		setParterEmail(fund['Email - MO/BO'] ?? '');
	}, [subscription, fund]);

	const handleSendComment = useCallback(() => {
		// If the transfer has no opsPropertiesId, we need to generate it before sending the comment
		if (!subscription?.opsPropertiesId) {
			updateDeal({
				id: subscription!.id,
				productType: subscription!.productType,
				properties: { id: subscription!.opsPropertiesId ?? undefined, comment: comments.comment },
			});
			comments.onChangeComment('');
		} else comments.onCreateComment(subscription?.opsPropertiesId);
	}, [comments, subscription, updateDeal]);

	const handleDeleteSubscription = async () => {
		(productType === 'TRANSFER'
			? deleteTransfer({ id: subscription!.id })
			: deleteDeal({
					id: subscription!.id,
					productType: subscription!.productType,
					partnerEmail: partnerEmail.length > 0 ? partnerEmail : undefined,
			  })
		)
			.unwrap()
			.then(() => {
				setValidationInput('');
				setParterEmail('');
				closeDeleteDialog();
				navigate('..');
				toast({ status: 'success', title: 'Souscription supprimée avec succès' });
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	if (isSubscriptionLoading) return <Skeleton h="100%" w="100%" />;
	if (isNone(subscription))
		return (
			<Center>
				<Heading size="md">Souscription non trouvée</Heading>
			</Center>
		);

	return (
		<VStack w="100%" spacing="12px" align="start" pb="32px">
			<NextStatusModal />

			<Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
				<BreadcrumbItem>
					<BreadcrumbLink onClick={() => navigate('..')}>Souscriptions</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink>{subscription.user.email}</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<HStack w="100%" align="start" justify="space-between">
				<Heading size="lg">
					{kyc?.kyc?.firstName} {kyc?.kyc?.lastName} - {subscription.productType} -{' '}
					{displayMoney(getDealAmount(subscription))}
				</Heading>

				<HStack align="start">
					{showNextStatusbutton ? (
						<Button colorScheme="blue" onClick={() => handleOpenNextStatusModal(subscription)}>
							Passer au status suivant
						</Button>
					) : (
						<ProcessModalButton deal={subscription} />
					)}

					<Button
						colorScheme="blue"
						onClick={() => navigate('/ops/super/blocking-instance', { state: { deal: subscription } })}
					>
						Créer une instance bloquante
					</Button>

					<Menu closeOnSelect={false}>
						<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
							Actions
						</MenuButton>
						<MenuList>
							<MenuItem onClick={() => navigate(`/ops/super/client/${subscription.user.id}`)}>Page client</MenuItem>
							<PermissionDisplayGuard permission={BoPermission.DEAL_DELETE}>
								<MenuItem color="red.600" onClick={openDeleteDialog}>
									Supprimer la souscription
								</MenuItem>
							</PermissionDisplayGuard>
						</MenuList>
					</Menu>
				</HStack>
			</HStack>

			<VStack w="100%" spacing="12px" align="start">
				<UserDataTable email={subscription.user.email} deal={subscription} />

				<DocumentsList
					context="subscription"
					email={subscription.user.email}
					subscription={subscription}
					selectedDocuments={selectedDocuments}
					setSelectedDocuments={setSelectedDocuments}
				/>

				{!showNextStatusbutton && subscription.productType !== ProductType.CRYPTO && (
					<Cardlayout title="Transmettre le dossier">
						{/* PE AUTO - API */}
						{subscription.productType === ProductType.PE && subscription.process === 'api' && (
							<SubscriptionSendApi
								selectedDocuments={selectedDocuments}
								setSelectedDocuments={setSelectedDocuments}
								subscription={subscription}
								partner={subscription.partner.toLowerCase() as ApiPartner}
							/>
						)}

						{/* PE AUTO - IN APP SIGNED */}
						{subscription.productType === ProductType.PE && subscription.process === 'mail' && (
							<SubscriptionSendMail
								selectedDocuments={selectedDocuments}
								setSelectedDocuments={setSelectedDocuments}
								subscription={subscription}
							/>
						)}

						{/* SCPI AUTO - IN APP SIGNED*/}
						{subscription.productType === ProductType.SCPI && subscription.process === 'mail' && (
							<SubscriptionSendMail
								selectedDocuments={selectedDocuments}
								setSelectedDocuments={setSelectedDocuments}
								subscription={subscription}
							/>
						)}

						{/* ART - IN APP SIGNED*/}
						{subscription.productType === ProductType.ART && (
							<SubscriptionSendMail
								defaultReceiver="andrea@matis.club"
								selectedDocuments={selectedDocuments}
								setSelectedDocuments={setSelectedDocuments}
								subscription={subscription}
							/>
						)}

						{/* CROWDFUNDING */}
						{/* anaxago subs are a special case: they are sent via email, but tagged as extranet on airtable because they are not in app signed */}
						{subscription.productType === ProductType.CROWDFUNDING &&
							(subscription.process === 'mail' || subscription.provider === 'Anaxago') && (
								<SubscriptionSendMail
									defaultReceiver={subscription.provider === 'Anaxago' ? 'alexandre.trigo@anaxago.com' : undefined}
									selectedDocuments={selectedDocuments}
									setSelectedDocuments={setSelectedDocuments}
									subscription={subscription}
								/>
							)}

						{/* CASH */}
						{subscription.productType === ProductType.CASH &&
							subscription.provider === SavingsAccountProvider.CFCAL && (
								<SubscriptionSendApi
									selectedDocuments={selectedDocuments}
									setSelectedDocuments={setSelectedDocuments}
									subscription={subscription}
									partner={ApiPartner.CFCAL}
								/>
							)}

						{/* INVEST */}
						{subscription.productType === ProductType.INVEST && subscription.dealType !== 'TRANSFER' && (
							<SubscriptionSendApi
								selectedDocuments={selectedDocuments}
								setSelectedDocuments={setSelectedDocuments}
								subscription={subscription}
								partner={(subscription as OpsInvestDeal).envelope?.provider?.toLowerCase() as ApiPartner}
							/>
						)}

						{/* TRANSFER */}
						{subscription.productType === ProductType.INVEST && subscription.dealType === 'TRANSFER' && (
							<SubscriptionSendMail
								isTransfert
								selectedDocuments={selectedDocuments}
								setSelectedDocuments={setSelectedDocuments}
								subscription={subscription}
								defaultText={transferMailBody}
								defaultReceiver="operations.epargne@intencial.fr"
								defaultSubject={`Transfert PER | ${subscription.user.kyc.firstName} ${
									subscription.user.kyc.lastName
								} | ${
									!subscription.externalProviderId
										? '[EN COURS DE SOUSCRIPTION]'
										: `Numéro de contrat ${subscription.externalProviderId}`
								}`}
							/>
						)}
					</Cardlayout>
				)}

				{subscription.productType === ProductType.CRYPTO && <CryptoInspector userEmail={subscription.user.email} />}

				<CommentsSection
					comment={comments.comment}
					commentList={opsProperties?.comments}
					onCreateComment={handleSendComment}
					onChangeComment={comments.onChangeComment}
					onDeleteComment={comments.onDeleteComment}
					isLoading={isUpdateLoading}
				/>
				<HStack align="start" w="100%">
					<HistorySection opsProperties={opsProperties} />
					<Box>
						{isNotNone(statuses) && (
							<Box>
								<Cardlayout title="Deadline">
									<ChangeDealOverdueDate overdueItem={subscription} statuses={statuses} />
								</Cardlayout>
							</Box>
						)}
					</Box>
				</HStack>
			</VStack>

			<AlertDialog
				isOpen={isDeleteDialogOpen}
				onClose={closeDeleteDialog}
				header="Supprimer la souscription"
				body={
					<VStack spacing="16px" align="start">
						<Text>Vous êtes sur le point de supprimer cette souscription.</Text>
						{subscription.status === SubscriptionStatus.SIGNED && (
							<>
								<Text>Email du partenaire:</Text>
								<Input
									value={partnerEmail}
									onChange={(e) => setParterEmail(e.target.value)}
									placeholder="Email du partenaire"
								/>
							</>
						)}
						<Text>
							Veuillez confirmer cette opération en écrivant <i>valider la suppression</i>
						</Text>
						<Input
							value={validationInput}
							onChange={(e) => setValidationInput(e.target.value)}
							placeholder="valider la suppression"
						/>
					</VStack>
				}
				footer={
					<>
						<Button onClick={closeDeleteDialog}>Annuler</Button>
						<Button
							colorScheme="red"
							ml={3}
							isLoading={isDeleteDealLoading || isDeleteTransferLoading}
							isDisabled={validationInput !== 'valider la suppression'}
							onClick={handleDeleteSubscription}
						>
							Valider
						</Button>
					</>
				}
			/>
		</VStack>
	);
};
